import { InfoBlk, NoDataFound, SelectInput, Spinner, Toaster } from "@sk/uis";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import produce from "immer";
import { CustomerService, NavService } from "@sk/services";

const CustTemplatesList = ({ templates, customerId, templateTypes, type }) => {
  const { register } = useForm();
  const [filteredTemplates, setFilteredTemplates] = useState(templates);

  useEffect(() => {
    setFilteredTemplates(templates);
  }, [templates]);

  const templateTypeCb = (v) => {
    if (!v || v === "all") {
      setFilteredTemplates(templates);
    } else {
      const filtered = templates.filter(
        (template) => template.promotionConfigType === v
      );
      setFilteredTemplates(filtered);
    }
  };

  const sendMsgCb = async (index) => {
    setFilteredTemplates(
      produce((draft) => {
        const d = draft[index];
        d.sending = true;
      })
    );

    const r = await CustomerService.sendMessageToCustomer(
      filteredTemplates[index].templateId,
      customerId
    );

    setFilteredTemplates(
      produce((draft) => {
        const d = draft[index];
        d.sending = false;
      })
    );

    if (r.statusCode == 200) {
      Toaster.success("Message sent successfully");
    } else {
      Toaster.error("Failed to send message");
    }
  };

  const getSendButtonContent = (sending) => {
    if (sending) {
      return <Spinner />;
    }

    switch (type) {
      case "WhatsApp":
        return (
          <>
            Send WhatsApp
            <i className="bi bi-whatsapp ms-1"></i>
          </>
        );
      case "SMS":
        return (
          <>
            Send SMS
            <i className="bi bi-chat-dots ms-1"></i>
          </>
        );
      default:
        return (
          <>
            Send
            <i className="bi bi-send ms-1"></i>
          </>
        );
    }
  };

  const getButtonClass = () => {
    switch (type) {
      case "WhatsApp":
        return "btn-success"; // Green for WhatsApp
      case "SMS":
        return "btn-info"; // Blue for SMS
      default:
        return "btn-primary"; // Default color
    }
  };

  const viewTemplateCb = (template) => {
    NavService.openInNewTab("/utility/whatsapp-template/view", {
      id: template.templateId,
    });
  };

  const viewConfigCb = (template) => {
    NavService.openInNewTab("/configs/marketing/promotion", {
      search: template._id,
    });
  };

  return (
    <>
      <InfoBlk size="sm" variant="info">
        <i className="bi bi-info-circle me-1"></i>
        <span className="fs-val-sm">
          Select a template type to view available templates. Click on Send
          button to send a message to the selected customer.
        </span>
      </InfoBlk>

      <SelectInput
        callback={templateTypeCb}
        label={"Choose Type"}
        name={"templateType"}
        register={register}
        options={templateTypes}
      />

      {filteredTemplates.length === 0 ? (
        <div className="text-center mt-3">
          <NoDataFound>
            <span>No templates found for the selected type.</span>
          </NoDataFound>
        </div>
      ) : (
        (filteredTemplates || []).map((m, index) => (
          <div key={index} className="border rounded-3 p-3 mb-3 bg-light">
            <div className="fs-val-xs text-muted">
              <span className="align-middle">ID: </span>
              <button
                className="btn btn-sm btn-link text-muted fs-val-xs p-0"
                onClick={() => viewConfigCb(m)}
              >
                {m._id}
              </button>
            </div>
            <div
              className="fs-val-md"
              dangerouslySetInnerHTML={{ __html: m.message }}
            ></div>
            <div className="text-end mt-2">
              <button
                className="btn btn-sm btn-outline-secondary fs-val-sm me-2"
                onClick={() => viewTemplateCb(m)}
              >
                <i className="bi bi-eye-fill me-2"></i>
                Preview
              </button>
              <button
                className={`btn btn-sm fs-val-sm ${getButtonClass()}`}
                onClick={() => sendMsgCb(index)}
                disabled={m.sending}
              >
                {getSendButtonContent(m.sending)}
              </button>
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default CustTemplatesList;
