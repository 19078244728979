import { StoresModal } from "@sk/features"; // Import the modal component
import { FranchiseService, NavService } from "@sk/services"; // Import FranchiseService
import { AppCard, Rbac } from "@sk/uis"; // Ensure you import necessary components
import { useEffect, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";

const StoreCard = ({
  fid,
  navigate,
  callback,
  showViewAnalytics = true,
  showViewInventory,
  rbacKey,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [franchiseDetails, setFranchiseDetails] = useState(null);
  const [loading, setLoading] = useState(false); // State for loading

  const rbac = {
    salesAnalytics: ["DarkStoreSalesDashboard"],
    inventory: ["DarkStoreInventoryDashboard"],
  };

  useEffect(() => {
    const fetchFranchiseDetails = async () => {
      if (fid) {
        setLoading(true); // Set loading to true when fetching starts
        const response = await FranchiseService.getFranchise(fid, {
          select:
            "name, _id, town, district, state, pincode, sk_franchise_details",
        });
        setLoading(false); // Set loading to false when fetching ends
        if (response.statusCode === 200) {
          // Set the franchise details and the chips array
          setFranchiseDetails({
            ...response.resp,
          });
          callback?.({ franchiseDetails: response.resp });
        } else {
          callback?.();
        }
      }
    };

    fetchFranchiseDetails();
  }, [fid]); // Fetch details when fid changes

  const handleRedirect = (redirect) => {
    NavService.to(navigate, redirect.path, {
      fid: fid,
      fName: franchiseDetails?.name,
      ...redirect.params,
    });
  };

  const storeModalCb = (e) => {
    setShowModal(false);

    if (e?.action === "selectedStore") {
      const store = e?.store?.[0] || {};
      if (store?._id) {
        NavService.to(navigate, location.hash.replace("#", ""), {
          fid: store._id,
          fName: store.name,
        });
      }
    }
  };

  return (
    <>
      <AppCard noPad={true}>
        <div className="row px-3 pt-3 pb-3">
          <div className="col">
            {loading ? ( // Show spinner while loading
              <Spinner animation="border" />
            ) : (
              <>
                <div className="fs-val-sm text-muted">Store Details</div>
                <div className="fs-val-normal fw-semibold">
                  {franchiseDetails?.name} - {franchiseDetails?._id}
                </div>
                <div className="fs-val-sm text-muted">
                  {franchiseDetails?.town}, {franchiseDetails?.district},
                  {franchiseDetails?.state} - {franchiseDetails?.pincode}
                </div>
              </>
            )}
          </div>
          {showViewAnalytics && (
            <Rbac roles={rbac.salesAnalytics}>
              <div className="col-auto align-self-end">
                <button
                  className="btn btn-outline-primary btn-sm fs-val-sm"
                  onClick={() =>
                    handleRedirect({ path: "/pos/sales-analytics" })
                  }
                >
                  View Sales Analytics
                </button>
              </div>
            </Rbac>
          )}
          {showViewInventory && (
            <Rbac roles={rbac.inventory}>
              <div className="col-auto align-self-end">
                <button
                  className="btn btn-outline-primary btn-sm fs-val-sm"
                  onClick={() => handleRedirect({ path: "/pos/inventory" })}
                >
                  View Inventory
                </button>
              </div>
            </Rbac>
          )}
          <div className="col-auto align-self-end">
            <button
              className="btn btn-secondary btn-sm fs-val-sm"
              onClick={() => setShowModal(true)}
            >
              Change Store
            </button>
          </div>
        </div>

        <div className="p-2 py-1 bg-light row g-1">
          {chips.map((chip) => (
            <div className="col-auto" key={chip.key}>
              <Dropdown size="sm">
                <Dropdown.Toggle variant="light" size="sm">
                  {chip.name}{" "}
                  <i className="bi bi-caret-down-fill fs-val-xs text-black-50"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {chip.subMenu.map((subMenu) => (
                    <Dropdown.Item
                      key={subMenu.key}
                      className="fs-val-md"
                      onClick={() => handleRedirect(subMenu?.redirect)}
                    >
                      <i className={`${subMenu.icon} me-2`}></i>
                      {subMenu.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ))}

          {/* <Tabs
            data={chips}
            activeTab={activeTab}
            template={4}
            callback={onTabChange}
            noBg={true}
          /> */}
        </div>
      </AppCard>

      <StoresModal
        show={showModal}
        callback={storeModalCb}
        onlyAssignedStore={true}
        rbackKey={rbacKey}
      />
    </>
  );
};

const chips = [
  {
    key: "purchase",
    name: "Purchase",
    subMenu: [
      {
        key: "purchase",
        name: "Store Purchase",
        icon: "bi bi-cart",
        redirect: {
          path: "/oms/orders",
        },
      },
      {
        key: "inwards",
        name: "Store Shipments",
        icon: "bi bi-truck",
        redirect: {
          path: "/logistics/store-shipments",
        },
      },
      {
        key: "storeInventory",
        name: "Store Inventory",
        icon: "bi bi-box2",
        redirect: {
          path: "/pos/inventory",
          params: {
            view: "old",
          },
        },
      },
      {
        key: "inventoryAnalysis",
        name: "Inventory Analysis",
        icon: "bi bi-graph-up",
        redirect: {
          path: "/dashboard/inventory-ageing",
          params: {
            view: "store",
          },
        },
      },

      {
        key: "purchaseBasket",
        name: "Purchase Basket",
        icon: "bi bi-basket",
        redirect: {
          path: "/pos/purchase-basket",
        },
      },

      {
        key: "rackBin",
        name: "Rack Bin",
        icon: "bi bi-grid-3x3",
        redirect: {
          path: "/pos/rack-bin",
        },
      },

      {
        key: "newlyLaunched",
        name: "Newly Launched",
        icon: "bi bi-stars",
        redirect: {
          path: "/pos/newly-launched",
        },
      },
      // {
      //   key: "inventoryAnalysis",
      //   name: "Inventory Analysis",
      //   icon: "bi bi-graph-up",
      //   redirect: {
      //     path: "/dashboard/inventory-ageing",
      //   },
      // },
    ],
  },
  // {
  //   key: "configs",
  //   name: "Configs",
  //   subMenu: [
  //     {
  //       key: "kingOffer",
  //       name: "King Offer",
  //       icon: "bi bi-gift",
  //     },
  //     {
  //       key: "kingSlab",
  //       name: "King Slab",
  //       icon: "bi bi-box",
  //     },
  //     {
  //       key: "discount",
  //       name: "Discount",
  //       icon: "bi bi-tag",
  //     },
  //   ],
  // },
  {
    key: "sales",
    name: "Sales",
    subMenu: [
      {
        key: "salesAnalytics",
        name: "Sales Analytics",
        icon: "bi bi-bar-chart",
        redirect: {
          path: "/pos/sales-analytics",
        },
      },
      {
        key: "ccc",
        name: "CCC Dashboard",
        icon: "bi bi-speedometer",
        redirect: {
          path: "/dashboard/coins-v2",
        },
      },
    ],
  },
  {
    key: "settings",
    name: "Settings",
    subMenu: [
      {
        key: "reports",
        name: "Reports",
        icon: "bi bi-file-text",
        redirect: {
          path: "/pos/sales-analytics",
          params: {
            tab: "reports",
          },
        },
      },
    ],
  },
];

export default StoreCard;
