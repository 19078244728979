import { PoService, VendorService } from "@sk/services";
import {
  Amount,
  AppCard,
  DateFormatter,
  HighlightText,
  KeyVal,
  NoDataFound,
  PageLoader,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { set } from "date-fns";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import VendorOpenPoFilter from "./components/VendorOpenPoFilter";

// Canvas Style
const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "75%",
  },
};

const defaultPageOpt = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const getData = async (params) => {
  const r = await PoService.getList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const getCount = async (params) => {
  try {
    const r = await PoService.getCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const header = [
  { label: "Sl.no", isCentered: true },
  { label: "PO Id", isCentered: true },
  { label: "Created on", isCentered: true },
  { label: "Expected on", isCentered: true },
  { label: "Status on", isCentered: true },
  { label: "Amount", isCentered: true },
];

const defaultFromData = {
  createdAt: [],
  expectedAt: [],
  status: "",
  poId: "",
};

const prepareFilterParams = (
  filter = {},
  pagination = {},
  additionalData = {}
) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      whId: additionalData.whId,
      "contact.vendorId": additionalData.vendorId,
      status: { $in: ["Open", "Partially Inwarded"] },
    },
  };

  if (filter.status) {
    p.filter.status = { $in: [filter.status] };
  }
  const poId = filter?.poId?.trim();

  if (poId) {
    p.filter._id = { $regex: poId, $options: "gi" };
  }
  if (filter.createdAt?.length) {
    p.filter.createdAt = {
      $gte: set(filter?.createdAt[0], { hours: 0, minutes: 0, seconds: 0 }),
      $lte: set(filter?.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }
  if (filter.expectedAt?.length) {
    p.filter.expectedAt = {
      $gte: set(filter?.expectedAt[0], { hours: 0, minutes: 0, seconds: 0 }),
      $lte: set(filter?.expectedAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }
  return p;
};

const VendorOpenPoModal = ({ show, closeModal, vendorId, whId }) => {
  const [loading, setLoading] = useState(true);

  const [pageLoading, setPageLoading] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const paginationRef = useRef({ ...defaultPageOpt });

  const filterDataRef = useRef({ ...defaultFromData });

  const [data, setData] = useState([]);

  const [vendorData, setVendorData] = useState({});

  useEffect(() => {
    if (show && vendorId && whId) {
      init();
    }
  }, [init, show, vendorId, whId]);

  const loadVendorDetails = useCallback(async () => {
    setPageLoading(true);
    const r = await VendorService.getVendor(vendorId, {
      select: "name,gst_no,communication",
    });
    if (r.statusCode !== 200) {
      setVendorData({});
    } else {
      setVendorData(r.resp);
    }
    setPageLoading(false);
  }, [vendorId]);

  const loadList = useCallback(async () => {
    setLoading(true);
    const params = getFilterParams(whId, vendorId);
    const r = await getData(params);
    setData(r);
    setLoading(false);
  }, [getFilterParams, whId, vendorId]);

  const loadCount = useCallback(async () => {
    setLoadingTotalRecords(true);
    const params = { ...getFilterParams(whId, vendorId) };
    delete params.page;
    delete params.count;

    const c = await getCount(params);

    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [getFilterParams, whId, vendorId]);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const applyFilter = useCallback(() => {
    paginationRef.current = { ...defaultPageOpt };
    loadList();
    loadCount();
  }, [loadList, loadCount]);

  const init = useCallback(() => {
    loadVendorDetails();
    applyFilter();
  }, [applyFilter, loadVendorDetails]);

  const filterCb = useCallback(
    (data) => {
      if (data.action == "applied") {
        filterDataRef.current = { ...data.data };
      } else if (data.action == "reset") {
        filterDataRef.current = { ...defaultFromData };
      }
      applyFilter();
    },
    [applyFilter]
  );

  const getFilterParams = useCallback((whId, vendorId) => {
    return prepareFilterParams(filterDataRef.current, paginationRef.current, {
      whId,
      vendorId,
    });
  }, []);

  return (
    <Offcanvas
      show={show}
      onHide={closeModal}
      placement="end"
      style={style.offCanvasStyle}
    >
      <Offcanvas.Header
        closeButton
        closeVariant="white"
        style={style.offCanvasHeaderStyle}
      >
        <Offcanvas.Title>Vendor Open Po List : #{vendorId}</Offcanvas.Title>
      </Offcanvas.Header>
      {pageLoading ? (
        <PageLoader />
      ) : (
        <Offcanvas.Body>
          {show && (
            <AppCard title="Vendor Details" noShadow={true}>
              <div className="row">
                <div className="col-12">
                  <div className="fw-semibold fs-val-lg text-primary mb-3">
                    {vendorData?.name || "N/A"} - {vendorData?._id || "N/A"}
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-6">
                  <KeyVal label="Address" template="col">
                    {vendorData?.communication?.line1
                      ? vendorData?.communication?.line1 + ","
                      : ""}
                    {vendorData?.communication?.line2
                      ? vendorData?.communication?.line2 + ","
                      : ""}
                    {vendorData?.communication?.landmark
                      ? vendorData?.communication?.landmark + ","
                      : ""}
                    {vendorData?.communication?.district
                      ? vendorData?.communication?.district + ","
                      : ""}
                    {vendorData?.communication?.city
                      ? vendorData?.communication?.city + ","
                      : ""}
                    {vendorData?.communication?.state
                      ? vendorData?.communication?.state + ","
                      : ""}
                    {vendorData?.communication?.pincode
                      ? vendorData?.communication?.pincode
                      : ""}
                  </KeyVal>
                </div>

                <div className="col-6">
                  <KeyVal label="GST No." template="col">
                    {vendorData?.gst_no || "N/A"}
                  </KeyVal>
                </div>
              </div>
            </AppCard>
          )}

          <VendorOpenPoFilter callback={filterCb} />

          <PaginationSummary
            paginationConfig={paginationRef.current}
            loadingTotalRecords={loadingTotalRecords}
          />
          <table className=" table table-bordered">
            <TableHeader data={header} />
            <tbody>
              {/* When It is Loading */}
              {loading && (
                <TableSkeletonLoader
                  rows={paginationRef.current.rowsPerPage}
                  cols={header.length}
                />
              )}

              {/* When There is No Data */}
              {!loading && data.length == 0 && (
                <tr className="fs-val-md">
                  <td colSpan={header.length}>
                    <NoDataFound> No PO Data Found </NoDataFound>
                  </td>
                </tr>
              )}

              {!loading &&
                data.length > 0 &&
                data.map((x, i) => (
                  <tr key={x._id}>
                    <td className="text-center">
                      {paginationRef.current.startSlNo + i}
                    </td>
                    <td className="text-center"> {x._id} </td>
                    <td className="text-center">
                      <DateFormatter date={x.createdAt} />
                    </td>
                    <td className="text-center">
                      <DateFormatter date={x.expectedAt} />
                    </td>
                    <td className="text-center">
                      <HighlightText status={x.status} />
                    </td>

                    <td className="text-center">
                      <Amount value={x.poValue} decimalPlace={2} />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <PaginationBlock
            paginationConfig={paginationRef.current}
            paginationCb={paginationCb}
            loadingTotalRecords={loadingTotalRecords}
          />
        </Offcanvas.Body>
      )}
    </Offcanvas>
  );
};

export default memo(VendorOpenPoModal);
