import { CommonService, PoService } from "@sk/services";
import format from "date-fns/format";
function formatDate(d) {
  let date = new Date(d);
  return format(date, "dd-MM-yyyy");
}

const doPrint = (d) => {
  const data = { ...d };
  data._totalPOQty = 0;

  data._totalPoValue = CommonService.rupeeFormat(data.poValue, 2);
  (data._formattedProducts || []).forEach((x) => {
    data._totalPOQty += x.resp.quantity.requested ?? 0;
  });

  let tbodyData = "";

  // Table Data
  data._formattedProducts.forEach((x) => {
    tbodyData += ` <tr>
              <td class="text-center">${x.hsnNumber ?? ""}</td>
              <td class="text-center">
                ${x.name} - ${x._id}
              </td>
              <td class="text-center">
                <div>30 Days : ${x.resp.salesIn30 ?? ""}</div>
                <div>60 Days : ${x.resp.salesIn60 ?? ""}</div>
              </td>
              <td class="text-center">
                <div>Avail : ${x.resp.availableQty ?? ""}</div>
              </td>
              <td class="text-center">
                <div>Mrp : <span class="rupee-symbol" >&#8377;</span> ${CommonService.rupeeFormat(
                  x.mrp,
                  2
                )}</div>
                <div>Unit :<span class="rupee-symbol" >&#8377;</span>  ${CommonService.rupeeFormat(
                  x.resp.unitPrice,
                  2
                )}</div>
              </td>
              <td class="text-center">
                <div>Base : ${x.resp?.margins?.bMargin ?? ""}%</div>
                <div>Scheme : ${x.resp?.margins?.sMargin ?? ""}%</div>
                <div><span class="fw-600"> Total </span> : ${PoService.calculateFinalMargin(
                  {
                    dealerPrice: x.dealerPrice,
                    dealerMarginPrice: x.dealerMarginPrice,
                    purchasePrice: x.purchasePrice,
                    mrp: x.mrp,
                  }
                )} %</div>
              </td>
              <td class="text-center">${x.resp.quantity.requested}</td>

              <td class="p-2">
                <div>Tax : ${x.tax ?? 0}%</div>
                <div>Before :<span class="rupee-symbol" >&#8377;</span>
                ${CommonService.rupeeFormat(x.totalValue, 2)}
                </div>
                <div>After:<span class="rupee-symbol" >&#8377;</span> ${CommonService.rupeeFormat(
                  x.totalValue,
                  2
                )}  </div>
              </td>
              <td class="text-center"><span class="rupee-symbol" >&#8377;</span>                 ${CommonService.rupeeFormat(
                x.totalValue,
                2
              )} </td>
            </tr>`;
  });

  let template = `<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width" />
    <link
      href="https://fonts.googleapis.com/css?family=Poppins:400,500,600,700"
      rel="stylesheet"
    />
    <style>
      * {
        font-family: "poppins";
      }
      .container {
        padding: 3rem;
      }
      .p-2 {
        padding: 0.5rem;
      }

      .my-2 {
        margin: 0.25rem 0;
      }
      .my-4 {
        margin: 0.5rem 0;
      }
      .row {
        display: flex;
      }
      .col-4 {
        width: 33.33%;
      }
      .col-8 {
        width: 75%;
      }
      .col-2 {
        width: 12.5%;
      }
      .col-3 {
        width: 25%;
      }
      .col-7 {
        width: 60%;
      }
      .col-6 {
        width: 50%;
      }
      .col-12 {
        width: 100%;
      }

      .fw-700 {
        font-weight: 700;
      }
      .fw-600 {
        font-weight: 600;
      }

      .border {
        border: 0.1rem solid rgba(128, 128, 128, 0.702);
      }

      .border-end {
        border-right: 0.1rem solid rgba(128, 128, 128, 0.702);
      }

      .border-top-0 {
        border-top: 0;
      }

      .table {
        width: 100%;
        border-collapse: collapse;
      }

      table,
      th,
      td {
        border: 0.1rem solid rgba(128, 128, 128, 0.702); /* This property is added to create a border */
      }
      .th-product-width {
        width: 25%;
      }
      .th-hsn-width {
        width: 10%;
      }
      .th-total-width {
        width: 12%;
      }
      .title-background-color {
        background-color: #e9e7cd;
      }
      .title-background-color {
        background-color: #e9e7cd;
      }
      .table-header-background-color {
        background-color: #bdb766;
      }

      .text-center {
        text-align: center;
      }

      .details-block {
        padding: 1rem;
        padding-bottom: 0;
      }
      .rupee-symbol{
        font-family: 'sans-serif';
      }
      .text-end{
        text-align: end;
      }

      @media print {
        * {
          font-size: 10px;
        }
      }
    </style>
  </head>

  <body>
    <div class="container">
      <div class="border title-background-color">
        <h2 class="fw-7pp text-center">Purchase Order - Accounts Copy</h2>
      </div>

      <!-- Basic Detials -->
      <div class="row border border-top-0">
        <!-- Buyer Details -->
        <div class="col-6 border-end">
          <div class="details-block">
            <div class="fw-600">Billing Name :</div>
            <div>${data.delivery?.companyName ?? ""}</div>
          </div>
          <div class="details-block">
            <div class="fw-600">Billing Address :</div>
            <div class="col-8">
              ${data.delivery?.address?.door_no ?? ""},${
    data?.delivery?.address?.street ?? ""
  },
              ${data.delivery.district ?? ""},${data.delivery.state ?? ""} - ${
    data.delivery.pincode ?? ""
  }
            </div>
            <div class="my-2">
              <span class="fw-600"> GST No. : </span>
              <span>${data.delivery.gstNo ?? ""}</span>
            </div>
          </div>
        </div>

        <!-- Vendor Details -->
        <div class="col-6">
          <div class="details-block">
            <div class="fw-600">Vendor Name :</div>
            <div>${data.contact.vendorName ?? ""}</div>
          </div>
          <div class="details-block">
            <div class="fw-600">Vendor Address :</div>
            <div class="col-8">
              ${data.contact.address ?? ""}
            </div>
            <div class="my-2">
              <div class="row">
                <div class="col-6">
                  <span class="fw-600"> GST No. : </span>
                  <span>${data.contact?.vendorGST ?? ""}</span>
                </div>
                <div class="col-6">
                  <span class="fw-600"> Mobile No : </span>
                  <span>${data.contact?.mobile ?? ""}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Additional Details -->
      <div class="row border border-top-0">
        <div class="col-4 border-end container">
          PO Number : <span class="fw-600"> ${data._id ?? ""}</span>
        </div>
        <div class="col-4 border-end container">
          PO Date : <span class="fw-600"> ${formatDate(data?.createdAt)} </span>
        </div>
        <div class="col-4 container">
          Expected Date : <span class="fw-600"> ${formatDate(
            data?.expectedAt
          )} </span>
        </div>
      </div>

      <!-- Table and Summary -->
      <div class="my-4">
        <table class="table">
          <thead>
            <tr class="table-header-background-color">
              <th class="fw-600 th-hsn-width">Hsn Number</th>
              <th class="fw-600 th-product-width">Product</th>
              <th class="fw-600">Sold Qty</th>
              <th class="fw-600">Qty</th>
              <th class="fw-600">Mrp / Price</th>
              <th class="fw-600">Margin</th>
              <th class="fw-600">PO Qty</th>
              <th class="fw-600">Tax</th>
              <th class="fw-600 th-total-width">Total</th>
            </tr>
          </thead>
          <tbody>
           ${tbodyData}
          </tbody>
        </table>
        <!-- PO Summary -->
        <div class="row border border-top-0">
          <div class="col-4 p-2">
            <h4 class="fw-600">Total Products :  ${data?.products?.length} </h4>
          </div>
          <div class="col-4 p-2">
            <h4 class="fw-600">Total PO Qty : ${data?._totalPOQty}</h4>
          </div>
          <div class="col-4 p-2 ">
            <h4 class="fw-600 text-end">Total PO Value : <span class="rupee-symbol" >&#8377;</span> ${
              data._totalPoValue
            }</h4>
          </div>
        </div>

        <!-- PO Summary Details -->
        <div class="row border border-top-0">
          <div class="col-6 p-2">
            <div>PO Remarks :</div>
            <div class="fw-600">${data.remarks ?? ""}</div>
          </div>

          <div class="col-6 p-2">
            <div>PO Created By :</div>
            <div class="fw-600">Mahesha</div>
          </div>
        </div>

        <!-- Note  -->
        <div class="row border border-top-0 p-2">
          <div class="fw-600">
            *Please Note: Tax already included in price.*
          </div>
        </div>
      </div>
    </div>

  </body>
</html>
`;
  const w = window.open("", "Purchase Order - Accounts Copy");
  w.document.title = "Purchase Order - Accounts Copy";
  w.document.write(template);

  const timer = setTimeout(() => {
    w.print();
    clearTimeout(timer);
    w.window.close();
  }, 2000);
};

export default { doPrint };
