import { set } from "lodash";
import React, { useState, useEffect } from "react";

const CountDownTimer = ({ targetDate, title = "Expires within", cssClass }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hrs: Math.floor(difference / (1000 * 60 * 60)),
        min: Math.floor((difference / 1000 / 60) % 60),
        sec: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        hrs: 0,
        min: 0,
        sec: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  return (
    <>
      {timeLeft.hrs > 0 || timeLeft.min > 0 || timeLeft.sec > 0 ? (
        <span className={cssClass}>
          {title} {timeLeft.hrs} : {timeLeft.min.toString().padStart(2, "0")} :{" "}
          {timeLeft.sec.toString().padStart(2, "0")}
        </span>
      ) : (
        <span>Time's up!</span>
      )}
    </>
  );
};

export default CountDownTimer;
