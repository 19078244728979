import { CustomerService } from "@sk/services";
import {
  AppCard,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useAttachAdditionalData } from "@sk/hooks";

const tableColumns = [
  { label: "#", key: "slNo", enableSort: false, width: "5%" },
  { label: "Suspended", key: "status", enableSort: true, width: "20%" },
  { label: "Logged On", key: "createdAt", enableSort: true, width: "25%" },
  { label: "Remarks", key: "remarks", width: "30%" },
  { label: "Updated By", key: "updatedBy", width: "20%" },
];

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 15,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 15,
};

const additionalDataConfig = [
  {
    key: "suspendedBy",
    api: "employee",
    loadingKey: "updatedByLoading",
    dataKey: "_updatedBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "firstName,lastName",
    }),
  },
];

const getData = async (params) => {
  const r = await CustomerService.getSuspendedLogs(params);
  const d = Array.isArray(r.resp) ? r.resp : [];

  return { data: d };
};

const getCount = async (params) => {
  let p = { ...params, outputType: "count" };
  delete p.page;
  delete p.count;

  const r = await CustomerService.getSuspendedLogs(p);
  return { count: r.resp?.[0]?.total || 0 };
};

const prepareFilterParams = (filter, pagination) => {
  let p = {
    page: pagination?.activePage,
    count: pagination?.rowsPerPage,
    filter: {
      customerId: filter.cid,
    },
    sort: {
      createdAt: -1,
    },
  };
  return p;
};

const ClubSuspendLogsModal = ({ customerId, onClose, show }) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState({
    loading: false,
    value: 0,
  });

  const filterRef = useRef({
    cid: customerId,
  });
  const paginationRef = useRef({ ...defaultPaginationConfig });

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const loadList = useCallback(async () => {
    setLoading(true);
    setLogs([]);

    const p = await prepareFilterParams(
      filterRef.current,
      paginationRef.current
    );
    const r = await getData(p);
    const d = r.data;

    let tmp = [];
    setAdditionalData(d, additionalDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length === additionalDataConfig.length) {
        setLogs([...attachAllData(d, tmp)]);
      }
    });

    setLogs(d);
    setLoading(false);
  }, [attachAllData, setAdditionalData]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };
    loadList();

    setTotalCount({ loading: true, value: 0 });
    const p = await getCount(
      prepareFilterParams(filterRef.current, paginationRef.current)
    );
    setTotalCount({ loading: false, value: p.count });
  }, [loadList]);

  useEffect(() => {
    if (show) {
      filterRef.current = { cid: customerId };
      applyFilter();
    }
  }, [show, applyFilter]);

  const paginationCb = (paginationConfig) => {
    paginationRef.current = {
      ...paginationRef.current,
      ...paginationConfig,
    };
    loadList();
  };

  return (
    <Modal show={show} onHide={onClose} size="lg">
      <Modal.Header closeButton className="bg-light">
        <Modal.Title className="text-dark mb-0">
          Block Club Purchase Logs
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-bg">
        <AppCard>
          <table className="table table-sm">
            <TableHeader data={tableColumns} noBg />
            <tbody className="fs-val-sm">
              {loading ? (
                <TableSkeletonLoader cols={tableColumns.length} rows={5} />
              ) : null}

              {!loading && logs.length === 0 ? (
                <tr>
                  <td colSpan={tableColumns.length} className="text-center">
                    <NoDataFound>No logs found</NoDataFound>
                  </td>
                </tr>
              ) : null}

              {logs.map((log, index) => (
                <tr key={index}>
                  <td>{paginationRef.current.startSlNo + index}</td>
                  <td>
                    <HighlightText
                      type={log.status == "Blocked" ? "success" : "danger"}
                      template={2}
                      size="sm"
                    >
                      {log.status == "Blocked" ? "Yes" : "No"}
                    </HighlightText>
                  </td>
                  <td>
                    <DateFormatter
                      date={log.createdAt}
                      format="dd MMM yyyy hh:mm a"
                    />
                  </td>
                  <td>{log.remarks || "--"}</td>
                  <td>{log._updatedBy?.firstName || "--"}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="text-end">
            <PaginationBlock
              paginationConfig={paginationRef.current}
              loadingTotalRecords={totalCount.loading}
              paginationCb={paginationCb}
              size="sm"
            />
          </div>
        </AppCard>
      </Modal.Body>
    </Modal>
  );
};

export default ClubSuspendLogsModal;
