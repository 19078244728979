import { SelectInput, TextInput } from "@sk/uis";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { debounce } from "lodash";
import { AuthService } from "@sk/services";

const VendorMarginModalFilter = ({ callback }) => {
  const { register, getValues } = useForm();

  const debounceCallback = useCallback(
    debounce((data) => {
      callback({ formData: data });
    }, 1000),
    [callback]
  );

  const onSearch = () => {
    debounceCallback(getValues());
  };

  const onStatusChange = () => {
    callback({ formData: getValues() });
  };

  const onWareshouseChange = () => {
    callback({ formData: getValues() });
  };

  return (
    <div className="row">
      <div className="col-4">
        <TextInput
          name="search"
          register={register}
          callback={onSearch}
          label="Search"
          placeholder="Search by Brand/Category/Product"
          gap={0}
        />
      </div>
      <div className="col-3">
        <SelectInput
          name="status"
          register={register}
          label="Status"
          options={statusOptions}
          callback={onStatusChange}
          gap={0}
        />
      </div>
      <div className="col-3">
        <SelectInput
          name="wareshouse"
          register={register}
          label="Wareshouse"
          options={wareshouseOptions}
          callback={onWareshouseChange}
          gap={0}
        />
      </div>
    </div>
  );
};

const statusOptions = [
  { label: "All", value: "" },
  { label: "Active", value: "Active" },
  { label: "Expired", value: "Expired" },
];

const wareshouseOptions = AuthService.getLoggedInEmpWh().map((wh) => ({
  label: wh.name,
  value: wh._id,
}));
wareshouseOptions.unshift({ label: "All", value: "" });

export default VendorMarginModalFilter;
