import { DateInput, SelectInput, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";

const typeOptions = [
  { label: "All IN/OUT Entries", value: "all" },
  { label: "Only IN Entries", value: "credit" },
  { label: "Only OUT Entries", value: "debit" },
];

const transactionTypeOptions = [
  {
    label: "All",
    value: "all",
    desc: "",
  },
  {
    label: "Purchase from Vendor",
    value: "PURCHASE - Third Party",
    desc: "Products which are purchased from other thirdparty vendors",
  },
  {
    label: "Purchase from SK",
    value: "PURCHASE - SK",
    desc: "Products which are purchased from StoreKing",
  },
  {
    label: "Sales from POS (B2C Sales)",
    value: "SALES - POS",
    desc: "Products which are purchased by customer",
  },
  {
    label: "Network Sales (from Retailer)",
    value: "SALES - Network",
    desc: "Products which are purchased by your Network Retailer",
  },
  {
    label: "Stock Adjustment",
    value: "Adjustment",
    desc: "Stock which are added or reduced",
  },
  {
    label: "Subscription",
    value: "Subscription",
    desc: "StoreKing subscribed product stock",
  },
  {
    label: "POS Returns",
    value: "Sales Return",
    desc: "List of accepted returns from the customer",
  },
  {
    label: "Purchase Returns",
    value: "Purchase Return",
    desc: "List of accepted returns from the StoreKing",
  },
];

const defaultFormData = {
  search: "",
  dateRange: [],
  type: "all",
  transactionType: "all",
};

const dtConfig = {
  mode: "range",
  maxDate: new Date(),
};

const LedgerListFilter = ({ callback }) => {
  const { control, register, getValues, setValue } = useForm({
    defaultValues: {
      ...defaultFormData,
    },
  });

  const onInpChange = (chngFn) => (val) => {
    chngFn(val);
    triggerCb();
  };

  const onTypeChange = () => {
    const values = getValues();

    const selectedOption = transactionTypeOptions.find(
      (option) => option.value === values.transactionType
    );
    setValue("tranTypeDesc", selectedOption ? selectedOption.desc : "");

    triggerCb();
  };

  const triggerCb = () => {
    let f = getValues();
    callback({ formData: { ...f } });
  };

  const onSearchChange = useCallback(
    debounce(() => {
      triggerCb();
    }, 500),
    [triggerCb]
  );

  return (
    <div className="row mb-3">
      <div className="col">
        <TextInput
          name="search"
          placeholder="Search by ID or Type"
          register={register}
          gap={0}
          callback={onSearchChange}
        />
      </div>
      <div className="col">
        <SelectInput
          name="type"
          options={typeOptions}
          callback={onTypeChange}
          gap={0}
          register={register}
        />
      </div>
      <div className="col">
        <SelectInput
          name="transactionType"
          options={transactionTypeOptions}
          callback={onTypeChange}
          gap={0}
          register={register}
          placeholder="Select Transaction Type"
        />
      </div>
      <div className="col">
        <Controller
          name="dateRange"
          control={control}
          render={({ field: { onChange, value } }) => (
            <DateInput
              placeholder="Select date range"
              value={value}
              callback={onInpChange(onChange)}
              config={dtConfig}
              gap={0}
              template={2}
            />
          )}
        />
      </div>
      <div className="col-auto">
        <button
          className="btn btn-outline-primary btn-sm fs-val-md mb-0"
          onClick={() => callback({ action: "download" })}
        >
          <i className="bi bi-download me-2"></i>
          Download
        </button>
      </div>
    </div>
  );
};

export default LedgerListFilter;
