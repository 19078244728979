import { VendorService } from "@sk/services";
import { AppCard } from "@sk/uis";
import { useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import VendorMarginTable from "../../components/vendor-margin/VendorMarginTable";
import VendorMarginModalFilter from "./components/VendorMarginModalFilter";

const canvasStyle = {
  width: "80%",
};

const VendorMarginModal = ({ show, callback, vendorId }) => {
  const [margins, setMargins] = useState([]);
  const [loading, setLoading] = useState(true);

  const origDataRef = useRef([]);

  const filterRef = useRef({});

  useEffect(() => {
    if (show) {
      const fetchMargin = async () => {
        try {
          setLoading(true);
          const r = await VendorService.getVendor(vendorId, {
            select: "margins",
          });

          const margins = r.resp.margins || [];

          const updatedMargins = await VendorService.getVendorMarginList(
            margins
          );

          setMargins(updatedMargins);
          origDataRef.current = [...updatedMargins];
          setLoading(false);
        } catch (error) {
          console.error("Error fetching vendor margin:", error);
          setLoading(false);
        }
      };

      fetchMargin();
    }
  }, [show, vendorId, callback]);

  const filterCb = ({ formData }) => {
    filterRef.current = formData;
    applyFilter();
  };

  const applyFilter = () => {
    setMargins(
      VendorService.vendorMarginFilterHandler(
        filterRef.current,
        origDataRef.current
      )
    );
  };

  return (
    <Offcanvas
      show={show}
      onHide={callback}
      placement="end"
      style={canvasStyle}
    >
      <Offcanvas.Header closeButton className="bg-white">
        <Offcanvas.Title>Vendor Margin</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modal-bg">
        <AppCard>
          <VendorMarginModalFilter callback={filterCb} />
        </AppCard>

        <AppCard>
          <VendorMarginTable data={margins} loading={loading} />
        </AppCard>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default VendorMarginModal;
