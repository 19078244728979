import {
  DateFormatter,
  DisplayFranchiseName,
  HighlightText,
  ImgPreviewModal,
  ImgRender,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  Rbac,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import classNames from "classnames";
import { memo, useState } from "react";
import CountDownTimer from "./CountDownTimer";

// const headers = [
//   { label: "Sl No", width: "auto", isCentered: true },
//   { label: "Page", isCentered: false },
//   { label: "Page Location", isCentered: true },
//   { label: "Banner Name", isCentered: true },
//   // { label: "Sub Banner Order", isCentered: true },
//   // { label: "Banner For", isCentered: true },
//   { label: "Start Date", isCentered: true },
//   { label: "End Date", isCentered: true },
//   // { label: "Display", isCentered: true },
//   // { label: "Resolution (W*H)", isCentered: true },
//   { label: "Preview", isCentered: true },
//   // { label: "Slider Time(s)", isCentered: true },
//   // { label: "Apply Banner On", isCentered: true },
//   //   { label: "Status", isCentered: true },
//   // { label: "Input Criteria", isCentered: true },
//   // { label: "Status", isCentered: true },
//   { label: "Conversions", isCentered: true },
//   { label: "Status", isCentered: true },
//   { label: "Actions", isCentered: true },
// ];

const headers = [
  { label: "Sl No", width: "auto", isCentered: true },
  { label: "Page Location", width: "15%", isCentered: false },
  { label: "Slider Details", isCentered: true },
  { label: "Actions", isCentered: true },
];

const subTableHeaders = [
  { label: "Sl No", width: "auto", isCentered: true },
  { label: "Slider Name", isCentered: true },
  { label: "Franchise Type", isCentered: true, width: "20%" },
  // { label: "Start Date", isCentered: true },
  { label: "Validity", isCentered: true },
  { label: "Preview", width: "25%", isCentered: true },
  { label: "Conversions", isCentered: true },
  { label: "Status", isCentered: true },
];

const rbac = {
  view: ["ViewBanner"],
  edit: ["EditBanner"],
};

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  editSliderCb,
  viewCb,
  editCb,
  auditCb,
}) => {
  const [imgPreviewData, setImgPreviewData] = useState({
    show: false,
    data: [],
  });

  const viewImages = (x) => {
    if (x?._banners[0]?.image) {
      let d = [{ id: x?._banners[0]?.image }];
      setImgPreviewData({ show: true, data: d });
    }
  };

  const closeImgPreviewModal = () => {
    setImgPreviewData({ show: false, data: [] });
  };

  return (
    <>
      <div className="mb-3 ps-2">
        <PaginationSummary
          paginationConfig={paginationConfig}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>

      <table className="table table-bordered bg-white">
        <TableHeader data={headers} />
        <tbody className="fs-val-md">
          {/* Table Loader */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {/* No Data */}
          {!loading && !data?.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Data Found</NoDataFound>
              </td>
            </tr>
          ) : null}

          {/* List  */}
          {!loading &&
            data?.map((x, index) => (
              <tr key={x._id + index}>
                {/* SL No */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>

                {/*Banner  Name/Id */}
                <td>
                  <div
                    className="mb-2 text-primary cursor-pointer"
                    onClick={() => {
                      editCb(x?._id);
                    }}
                  >
                    {x?._id?.page || "N/A"} - {x?._id?.location || "N/A"}
                  </div>

                  {/* <div className="mb-1 fs-val-sm ">
                    Resolution : {x?.resolution?.width}x{x?.resolution?.height}{" "}
                    (WxH)
                  </div> */}
                </td>

                <td>
                  <table className="table table-bordered bg-white">
                    <TableHeader data={subTableHeaders} />
                    <tbody className="fs-val-md">
                      {(x?.list || []).map((e, i) => (
                        <tr key={e._id + i}>
                          <td className="text-center">{i + 1}</td>

                          {/* Sub-Banner */}
                          <td className="text-center">
                            <div className="mb-2 fw-semibold">
                              {e?._banners[0]?.title || "N/A"}
                            </div>
                            <div className="fs-val-sm text-muted mb-3">
                              Sub Banner Order :{" "}
                              {e?._banners[0]?.ordering || "N/A"}{" "}
                            </div>
                            <div className="mb-2 fw-semibold fs-val-sm">
                              ID : {e?._id || "N/A"}
                            </div>
                          </td>
                          <td className="text-center">
                            <div className="mb-2">
                              {e?._banners[0]?.filterByFranchiseType ==
                              "ByGroup"
                                ? "By Group"
                                : "By Franchise"}
                            </div>

                            {e?._banners[0]?.filterByFranchiseType ==
                            "ByGroup" ? (
                              <div className="mb-2">
                                Group Type :{" "}
                                {e?._banners[0]?.filterByFranchiseGroupType}
                              </div>
                            ) : (
                              <div>
                                <DisplayFranchiseName
                                  fId={
                                    e?._banners[0]?.filterByFranchiseId || ""
                                  }
                                />
                              </div>
                            )}
                          </td>

                          {/*Start Validity */}

                          <td className="text-center">
                            {e?._banners[0]?.period?.date?.startDate ? (
                              <>
                                <div className="mb-2">
                                  <DateFormatter
                                    date={
                                      e?._banners[0]?.period?.date?.startDate
                                    }
                                    format="dd MMM yyyy hh:mm a"
                                  />
                                </div>

                                {/* <div className="mb-2">
                                  <DateFormatter
                                    date={
                                      e?._banners[0]?.period?.date?.startDate
                                    }
                                    format="hh:mm a"
                                  />
                                </div> */}
                              </>
                            ) : null}

                            {e?._banners[0]?.period?.date?.endDate ? (
                              <>
                                <div className="mb-2">
                                  <DateFormatter
                                    date={e?._banners[0]?.period?.date?.endDate}
                                    format="dd MMM yyyy hh:mm a"
                                  />
                                </div>
                                {/* <div className="mb-2">
                                  <DateFormatter
                                    date={e?._banners[0]?.period?.date?.endDate}
                                    format="hh:mm a"
                                  />
                                </div> */}
                              </>
                            ) : null}

                            <div className="mb-2 mt-3">
                              <HighlightText
                                status={e?._banners[0]?._activeStatus?.label}
                                isBadge={true}
                              />
                            </div>

                            {e?._banners[0]?._isExpiringSoon ? (
                              <CountDownTimer
                                targetDate={
                                  e?._banners[0]?.period?.date?.endDate
                                }
                                cssClass={"text-warning fs-val-sm"}
                              />
                            ) : null}
                          </td>

                          {/* Image */}
                          <td className="text-center">
                            {e.displayType != "text" ? (
                              <div
                                className="p-2 py-3 cursor-pointer"
                                onClick={() => {
                                  viewImages(e);
                                }}
                              >
                                <ImgRender
                                  assetId={e?._banners[0]?.image || ""}
                                  height="180"
                                />
                              </div>
                            ) : (
                              "N/A"
                            )}
                          </td>

                          {/* Conversion */}
                          <td className="text-center">
                            <div className="text-primary mb-2 fs-val-lg">
                              {e?._banners[0]?.bannerClicks || 0}
                            </div>
                            <div className="text-primary">Clicks</div>
                          </td>

                          {/* status */}
                          <td className="text-center">
                            {/* <div
                              className={classNames(
                                e?._activeStatus?.color,
                                "text-semibold mt-2 mb-2",
                                {
                                  "fw-bold": e?._activeStatus?.label == "Live",
                                  "fw-semibold":
                                    e?._activeStatus?.label != "Live",
                                }
                              )}
                            >
                              {e?._banners[0]?._activeStatus?.label}
                              
                            </div> */}

                            <div className="mb-2">
                              <HighlightText
                                status={e?._banners[0]?.status || "N/A"}
                                isBadge={true}
                              />
                            </div>

                            <div className="mt-3">
                              <Rbac roles={rbac.view}>
                                <button
                                  className="btn btn-sm btn-outline-primary me-1 fs-val-xs mb-3 mx-1"
                                  onClick={() => {
                                    viewCb(e?._id);
                                  }}
                                >
                                  View
                                </button>
                              </Rbac>
                              <Rbac roles={rbac.edit}>
                                <button
                                  className="btn btn-sm btn-outline-warning me-1 fs-val-xs mb-3 mx-1"
                                  onClick={() => {
                                    editSliderCb(e);
                                  }}
                                >
                                  Edit
                                </button>
                              </Rbac>
                            </div>
                            <div>
                              <button
                                className="btn btn-sm btn-outline-info me-1 fs-val-xs mb-3 mx-1"
                                onClick={() => {
                                  auditCb(e);
                                }}
                              >
                                Audit Logs
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>

                {/* Sub-Banner */}
                {/* <td className="text-center">
                  <div className="mb-2">{x?.banners?.title || "N/A"}</div>
                  <div className="fs-val-sm text-muted">
                    Sub Banner Order : {x?.banners?.ordering || "N/A"}{" "}
                  </div>
                </td> */}

                {/*Start Validity */}
                {/* <td className="text-center">
                  {x.banners?.period?.date?.startDate ? (
                    <div className="mb-2">
                      <DateFormatter
                        date={x.banners?.period?.date?.startDate}
                        format="dd MMM yyyy"
                      />
                    </div>
                  ) : null}
                </td> */}

                {/* End Date */}
                {/* <td className="text-center">
                  {x.banners?.period?.date?.endDate ? (
                    <div className="mb-2">
                      <DateFormatter
                        date={x.banners?.period?.date?.endDate}
                        format="dd MMM yyyy"
                      />
                    </div>
                  ) : null}
                </td> */}

                {/* Image */}
                {/* <td className="text-center">
                  {x?.banners.displayType != "text" ? (
                    <div
                      className="p-2 py-3 cursor-pointer"
                      onClick={() => {
                        viewImages(x);
                      }}
                    >
                      <ImgRender
                        assetId={x?.banners?.image || ""}
                        width="100"
                        height="100"
                      />
                    </div>
                  ) : (
                    "N/A"
                  )}
                </td> */}

                {/* Conversion */}
                {/* <td className="text-center">
                  <div className="text-primary">200 Clicks</div>
                </td> */}

                {/* status */}
                {/* <td className="text-center">
                  <div
                    className={classNames(
                      x?._activeStatus?.color,
                      "text-semibold mt-2 mb-2"
                    )}
                  >
                    {x?._activeStatus?.label}
                  </div>
                </td> */}

                {/* Actions */}
                <td className="text-center">
                  {/* <div
                    className={classNames(
                      x?._activeStatus?.color,
                      "text-semibold mb-2"
                    )}
                  >
                    {x?._activeStatus?.label}
                  </div> */}
                  <div className="mb-3 text-success fw-bold">
                    Live :<span className="text-success ps-1">{x?._live}</span>
                  </div>

                  <div className="mb-3 text-warning">
                    Upcoming :
                    <span className="text-warning ps-1">{x?._upcoming}</span>
                    {/* {x?._total} */}
                  </div>

                  <div className="mb-5 text-danger">
                    Expired :
                    <span className="text-danger ps-1">{x?._expired}</span>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        showSummary={false}
        className="pe-2"
      />

      <ImgPreviewModal
        show={imgPreviewData.show}
        handleClose={closeImgPreviewModal}
        assets={imgPreviewData.data}
      />
    </>
  );
};

export default memo(Table);
