import { AppTitle, Tabs } from "@sk/uis";
import { set as dtSet } from "date-fns";
import { useEffect, useState } from "react";
import FilterForm from "./components/FilterForm";
import MarketDahboardChart from "./components/MarketDahboardChart";
import Table from "./components/Table";

const notificationTypes = [
  { label: "SMS Notification", key: "Sms" },
  { label: "Whatsapp Notification", key: "Whatsapp" },
  { label: "Email Notification", key: "Email" },
];

const tabs = [
  { tabName: "Event", key: "event" },
  { tabName: "Reminder", key: "reminder" },
  { tabName: "Campaign", key: "capmaign" },
];

const defaultFilterParams = {
  typeOfCandidate: "",
  mobileNo: "",
  createdAt: [
    dtSet(new Date(2024, 0, 1), { hours: 0, minutes: 0 }),
    dtSet(new Date(), { hours: 23, minutes: 59 }),
  ],
};

const MarketingDashboard = ({ filterData }) => {
  const [filterParams, setFilterParams] = useState({ ...defaultFilterParams });

  const [activeTab, setActiveTab] = useState({
    key: tabs[0].key,
  });

  useEffect(() => {
    setFilterParams({ ...filterData });
  }, [filterData]);

  // Tab Callback
  const tabCb = (data) => {
    setActiveTab({ ...data.value });
  };

  const filterCb = (d) => {
    setFilterParams({ ...d.formData, ...filterData });
  };

  return (
    <>
      <div className="bg-white p-4">
        <div>
          <FilterForm callback={filterCb} />
        </div>

        <div className="row">
          {notificationTypes.map((e, i) => (
            <div className="col-4 p-3" key={i}>
              <div className="row border  rounded-2 p-3 bg-white">
                <div className="col-12">
                  <AppTitle title={e.label} />
                </div>
                <div className="col-12">
                  <MarketDahboardChart
                    type={e.key}
                    filterParams={filterParams}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-3">
          <div className="card">
            <div className="card-body p-0">
              <Tabs data={tabs} callback={tabCb} activeTab={activeTab} />
              <div className="p-4">
                <Table />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketingDashboard;
