import {
  DateFormatter,
  HighlightText,
  KeyVal,
  NoDataFound,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";

const tableContainerStyle = {
  maxHeight: "calc(100vh - 300px)",
  overflowY: "auto",
};

const VendorMarginTable = ({ data, loading, callback }) => {
  return (
    <div style={tableContainerStyle}>
      <table className="table table-sm bg-white">
        <TableHeader data={tableHeaderData} noBg={true} isSticky={true} />
        <tbody className="fs-val-md">
          {loading ? (
            <TableSkeletonLoader cols={tableHeaderData.length} />
          ) : null}

          {!loading && data.length === 0 ? (
            <tr>
              <td colSpan={tableHeaderData.length} className="text-center">
                <NoDataFound>No data found</NoDataFound>
              </td>
            </tr>
          ) : null}

          {data.map((margin, index) => (
            <tr
              key={index}
              className={margin.status === "Expired" ? "danger-row" : ""}
            >
              <td>{index + 1}</td>
              {/* <td>
                <HighlightText type="secondary" template={2}>
                  {margin.marginType}
                </HighlightText>
              </td> */}
              <td>
                {margin._brand?.name && (
                  <KeyVal label="Brand" labelCol="col-2" contentCol="col-10">
                    : {margin._brand?.name}{" "}
                    <span className="fs-val-sm text-muted">
                      ({margin.brand})
                    </span>
                  </KeyVal>
                )}
                {margin._category?.name && (
                  <KeyVal
                    label="Category"
                    labelCol="col-2"
                    contentCol="col-10"
                    noBottomSpace={true}
                  >
                    : {margin._category?.name}{" "}
                    <span className="fs-val-sm text-muted">
                      ({margin.category})
                    </span>
                  </KeyVal>
                )}

                {margin._product?.name ? (
                  <KeyVal
                    label="Product"
                    labelCol="col-2"
                    contentCol="col-10"
                    noBottomSpace={true}
                  >
                    <div className="d-flex">
                      <span>:</span>
                      <span className="ms-1">
                        {margin._product?.name}{" "}
                        <span className="fs-val-sm text-muted">
                          ({margin.product})
                        </span>
                      </span>
                    </div>
                  </KeyVal>
                ) : null}
              </td>
              <td>{margin.whId}</td>
              <td style={tdStyle.margin} className="text-center">
                {margin.margin}%
              </td>
              <td>
                <DateFormatter date={margin.start_date} format="dd MMM yyyy" />

                <span> - </span>

                <DateFormatter date={margin.end_date} format="dd MMM yyyy" />
              </td>
              <td>
                <HighlightText
                  type={margin.status === "Active" ? "success" : "danger"}
                  template={2}
                >
                  {margin.status}
                </HighlightText>
              </td>
              {/* <td>
                <button
                  className="btn btn-sm btn-outline-primary fs-val-sm"
                  onClick={() => callback({ action: "edit", data: margin })}
                >
                  Edit
                </button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const tableHeaderData = [
  { label: "Sl No", width: "3%" },
  // { label: "Type", width: "10%" },
  { label: "Details", width: "45%" },
  { label: "Warehouse", width: "10%" },
  { label: "Margin", width: "8%", isCentered: true },
  { label: "Validity", width: "15%" },
  { label: "Status", width: "10%" },
  // { label: "Action", width: "10%" },
];

const tdStyle = {
  margin: {
    backgroundColor: "#f8f9fa",
  },
};

export default VendorMarginTable;
