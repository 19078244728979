import { appConfigs, BannerService, UtilityService } from "@sk/services";
import { EntitySearchInput, SelectInput } from "@sk/uis";
import { sortBy } from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
import { filterView } from "../../constantService";

const canvasStyle = { width: "30%" };

const defaultFormData = filterView.filterFormData;

const createdOnDateConfig = { mode: "range" };

const applicableForOptions = filterView.applicableForOptions;

const accessLevelOptions = filterView.accessLevelOptions;

const rmfSearchFilter = {
  filter: { "sk_franchise_details.franchise_type": "RMF" },
};

const rfSearchFilter = {
  filter: { "sk_franchise_details.franchise_type": "RF" },
};

const sfSearchFilter = {
  filter: { "sk_franchise_details.franchise_type": "SF" },
};

const franchiseSearchFilter = {
  filter: {
    "sk_franchise_details.franchise_sub_type": {
      $in: appConfigs.DARKSTORE_SUB_TYPES,
    },
  },
};

let l = [...BannerService.getBannerLocationCode()];
l.unshift({ label: "All", value: "" });
const bannerCodeOptions = [...l];

const BannerAdvanceFilterModal = ({
  type,
  show,
  callback,
  stateListOptions,
  bannerDesignList,
}) => {
  const { register, handleSubmit, reset, control, setValue, getValues } =
    useForm({
      defaultValues: defaultFormData,
    });

  const [districtOptions, setDistrictOptions] = useState([]);

  const [townOptions, setTownOptions] = useState([]);

  const [locationOptions, setLocationOptions] = useState([]);

  const [pageList, setPageList] = useState({ options: [], rawData: [] });

  const [accessLevel, platformType, filterByFranchiseType, page, location] =
    useWatch({
      control,
      name: [
        "accessLevel",
        "platformType",
        "filterByFranchiseType",
        "page",
        "location",
      ],
    });

  useEffect(() => {
    if (show && bannerDesignList) {
      setPageList({
        options: [...bannerDesignList.options],
        rawData: [...bannerDesignList.data],
      });
    }
  }, [show, setPageList]);

  const onSubmit = (data) => {
    callback({ formData: data, status: "applied" });
  };

  // To Reset Filter Form Data
  const resetFormData = () => {
    reset({ ...defaultFormData });
    callback({ formData: defaultFormData, status: "reset" });
  };

  // To Close Filter Modal
  const closeModal = () => {
    callback({ status: "closed" });
  };

  const onStateChange = useCallback(
    async (val, type = "") => {
      if (type != "init") {
        setValue("district", "");
        setValue("town", "");
      }
      let r = await UtilityService.getDistrictList(val);
      let opt = (r?.resp || []).map((e) => {
        return { label: e.name, value: e.name };
      });
      opt.unshift({ label: "Select District", value: "" });
      setDistrictOptions(opt);
      type != "init" ? setTownOptions([]) : null;
    },
    [setValue]
  );

  const onDistrictChange = useCallback(
    async (val) => {
      setValue("town", "");
      let r = await UtilityService.getTownList(getValues("state"), val);
      let opt = (r.resp || []).map((e) => {
        return { label: e.name, value: e.name };
      });
      opt.unshift({ label: "Select Town", value: "" });
      setTownOptions(opt);
    },
    [getValues, setValue]
  );

  const onPlatformTypeChange = () => {
    setValue("filterByFranchiseType", "");
    setValue("filterByFranchiseGroupType", "");
    setValue("fran", "");
  };

  const onPageInputChange = useCallback(
    async (val) => {
      let p = [];
      p = [...pageList.rawData].filter((e) => {
        return e.page == val;
      });

      let loc = [{ label: "All Location", value: "" }];
      let list = sortBy(p, "location");
      list.forEach((e) => {
        loc.push({ label: e.location, value: e.location });
      });

      setLocationOptions([...loc]);
    },
    [setLocationOptions, pageList]
  );

  const onFilterByTypeChange = () => {
    setValue("filterByFranchiseGroupType", "");
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-light">
          {/* Modal Title */}
          <Offcanvas.Title>
            <div className="fs-val-lg"> Advance Filter </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-3  border-top">
          <>
            <div className="row">
              {/* Start Time */}
              {/* <div className="col-6">
                <Controller
                  control={control}
                  name="startTime"
                  render={({ field: { onChange, value } }) => (
                    <DateInput
                      label="Active Start Time"
                      callback={onChange}
                      value={value}
                      config={{
                        noCalendar: true,
                        enableTime: true,
                        dateFormat: "h:i K",
                      }}
                    />
                  )}
                />
              </div> */}

              {/* End Time */}
              {/* <div className="col-6">
                <Controller
                  control={control}
                  name="endTime"
                  render={({ field: { onChange, value } }) => (
                    <DateInput
                      label="Active End Time"
                      callback={onChange}
                      value={value}
                      config={{
                        noCalendar: true,
                        enableTime: true,
                        dateFormat: "h:i K",
                      }}
                    />
                  )}
                />
              </div> */}

              <div className="col-4 mb-2">
                <SelectInput
                  type="text"
                  label="Banner Page"
                  register={register}
                  name="page"
                  options={pageList.options}
                  callback={onPageInputChange}
                />
              </div>
              <div className="col-8 mb-2">
                <SelectInput
                  type="text"
                  label="Banner Location"
                  register={register}
                  name="location"
                  options={locationOptions}
                  disabled={!page?.length}
                />
              </div>

              {/* Status  options */}
              <div className="col-6 mb-3">
                <SelectInput
                  label="Banner Status"
                  register={register}
                  name="status"
                  isMandatory={false}
                  options={filterView.bannerwiseStatusOptions}
                />
              </div>

              {/* Filter by Franchise Type */}
              {platformType == "ClubApp" || type == "clubApp" ? (
                <div className="col-6 mb-3">
                  <SelectInput
                    label="Filter By Type"
                    register={register}
                    name="filterByFranchiseType"
                    isMandatory={false}
                    options={filterView.filterByFranchiseTypeOptions}
                    callback={onFilterByTypeChange}
                  />
                </div>
              ) : null}

              {filterByFranchiseType == "ByFranchise" ? (
                <div className="col-12 mb-3">
                  <label className="fw-semibold mb-1 fs-val-md">
                    Franchise
                  </label>
                  <Controller
                    control={control}
                    name="fran"
                    render={({ field: { onChange, value } }) => (
                      <EntitySearchInput
                        type="franchise"
                        placeholder="Search by Franchise Name"
                        value={value}
                        callback={onChange}
                        uid="fran"
                        isMultiple={false}
                        isMandatory={true}
                        emptyLabel="Franchise Not found"
                        filterParams={franchiseSearchFilter}
                      />
                    )}
                  />
                </div>
              ) : null}

              {filterByFranchiseType == "ByGroup" ? (
                <div className="col-6 mb-3">
                  <SelectInput
                    label="Group Type"
                    register={register}
                    name="filterByFranchiseGroupType"
                    isMandatory={false}
                    options={filterView.filterByFranchiseGroupTypeOptions}
                  />
                </div>
              ) : null}

              {/* Banner Location Code  */}
              {/* <div className="col-6 mb-3">
                <SelectInput
                  label="Banner Location Code"
                  register={register}
                  name="bannerLocationCode"
                  isMandatory={false}
                  options={[...bannerCodeOptions]}
                />
              </div> */}

              {/* Platform Type   options */}
              {/* {type != "clubApp" ? (
                <div className="col-6 mb-3">
                  <SelectInput
                    label="Platform Type "
                    register={register}
                    name="platformType"
                    isMandatory={false}
                    options={filterView.platformTypeOptions}
                    callback={onPlatformTypeChange}
                  />
                </div>
              ) : null} */}

              {/* Banner For */}
              {/* {platformType == "RetailerApp" ? (
                <div className="col-6 mb-3">
                  <SelectInput
                    label="Banner For"
                    register={register}
                    name="applicableFor"
                    isMandatory={false}
                    options={applicableForOptions}
                  />
                </div>
              ) : null} */}

              {/* Banner Location Code  */}
              {/* <div className="col-6 mb-3">
                <SelectInput
                  label="Input Criteria"
                  register={register}
                  name="filterByConditionType"
                  isMandatory={false}
                  options={filterView.filterByConditionTypeOptions}
                />
              </div> */}

              {/* Date Range */}
              {/* <div className="col-12 mb-4">
                <label className="mb-1 fs-val-md">
                  Banner Active Period Date Range
                </label>
                <Controller
                  name="dateRange"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <DatePickerInput
                      placeholder="Choose"
                      value={value}
                      inpChange={onChange}
                      config={createdOnDateConfig}
                    />
                  )}
                />
              </div> */}

              {/* Access Level */}
              {/* {type != "clubApp" ? (
                <div className="col-6 mb-3">
                  <SelectInput
                    label="Access Level"
                    register={register}
                    name="accessLevel"
                    options={accessLevelOptions}
                  />
                </div>
              ) : null} */}

              {/* State / District / Town */}
              {accessLevel == "state" && (
                <>
                  {/* State */}
                  <div className="col-12 mb-3">
                    <SelectInput
                      type="text"
                      label="State"
                      register={register}
                      name="state"
                      options={stateListOptions}
                      callback={onStateChange}
                    />
                  </div>

                  {/* District */}
                  <div className="col-6 mb-3">
                    <SelectInput
                      type="text"
                      label="District"
                      register={register}
                      name="district"
                      options={districtOptions}
                      callback={onDistrictChange}
                      disabled={!getValues("state")?.length}
                    />
                  </div>

                  {/* Town */}
                  <div className="col-6 mb-3">
                    <SelectInput
                      type="text"
                      label="Choose Town"
                      register={register}
                      name="town"
                      options={townOptions}
                      disabled={!getValues("district")?.length}
                    />
                  </div>
                </>
              )}

              {/* RMF  */}
              {["rmf"].indexOf(accessLevel) != -1 && (
                <>
                  <div className="col-12">
                    <label className="fw-semibold mb-1 fs-val-md">RMF</label>
                    <Controller
                      control={control}
                      name="rmf"
                      render={({ field: { onChange, value } }) => (
                        <EntitySearchInput
                          type="franchise"
                          placeholder="Search by RMF Name"
                          value={value}
                          callback={onChange}
                          uid="rmf"
                          isMultiple={false}
                          isMandatory={true}
                          filterParams={rmfSearchFilter}
                          emptyLabel="RMF Not found"
                        />
                      )}
                    />
                  </div>
                </>
              )}

              {/* RF Franchise  */}
              {["rf"].indexOf(accessLevel) != -1 && (
                <>
                  <div className="col-12">
                    <label className="fw-semibold mb-1 fs-val-md">RF</label>
                    <Controller
                      control={control}
                      name="rf"
                      render={({ field: { onChange, value } }) => (
                        <EntitySearchInput
                          type="franchise"
                          placeholder="Search by RF Name"
                          value={value}
                          callback={onChange}
                          uid="rf"
                          isMultiple={false}
                          isMandatory={true}
                          filterParams={rfSearchFilter}
                          emptyLabel="RF Not found"
                        />
                      )}
                    />
                  </div>
                </>
              )}

              {/* SF Franchise */}
              {["sf"].indexOf(accessLevel) != -1 && (
                <>
                  <div className="col-12">
                    <label className="fw-semibold mb-1 fs-val-md">SF</label>
                    <Controller
                      control={control}
                      name="sf"
                      render={({ field: { onChange, value } }) => (
                        <EntitySearchInput
                          type="franchise"
                          placeholder="Search by SF Name"
                          value={value}
                          callback={onChange}
                          uid="sf"
                          isMultiple={false}
                          isMandatory={true}
                          filterParams={sfSearchFilter}
                          emptyLabel="SF Not found"
                        />
                      )}
                    />
                  </div>
                </>
              )}
            </div>

            {/*  Action Button */}
            <div className="text-end w-100 mt-4">
              <button
                className="btn me-1 btn-warning btn-sm"
                type="button"
                onClick={resetFormData}
              >
                Reset
              </button>
              <button
                className="btn btn-primary btn-sm"
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                Apply
              </button>
            </div>
          </>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(BannerAdvanceFilterModal);
