import { useAttachAdditionalData } from "@sk/hooks";
import {
  BannerService,
  CommonService,
  NavService,
  UtilityService,
} from "@sk/services";
import {
  AppCard,
  AppliedFilterLabel,
  DatePickerInput,
  Rbac,
  Spinner,
  Tabs,
  TextInput,
} from "@sk/uis";
import { add, differenceInDays, set } from "date-fns";
import { debounce, sortBy, uniqBy } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { filterView, listView } from "../constantService";
import BannerSummaryCard from "./components/BannerSummaryCard";
import Table from "./components/Table";
import AuditLogModal from "./modals/AuditLogModal";
import BannerAdvanceFilterModal from "./modals/BannerAdvanceFilterModal";
import ManageBannerSliderModal from "./modals/ManageBannerSliderModal";

const defaultSort = { key: "createdAt", value: "desc" };

let d = [...BannerService.getBannerLocationCode()];
let l = d?.length ? d : [];
l.unshift({ label: "Select", value: "" });
const bannerCodeOptions = [...l];

const rbac = {
  addButton: ["AddBanner"],
};

const createdOnDateConfig = { mode: "range" };

const tabs = [
  // { key: "All", tabName: "All" },
  { key: "Present", tabName: "Present" },
  { key: "Future", tabName: "Future" },
  { key: "Past", tabName: "Past" },
];

const getData = async (params) => {
  const r = await BannerService.getBannerList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const getCount = async (params) => {
  delete params.count;
  delete params.page;
  delete params.outputType;
  params.outputType = "listCount";
  params.groupBy = "totalBanners";

  try {
    const r = await BannerService.getBannerList(params);
    let t = Array.isArray(r.resp) ? r.resp[0] || 0 : { total: 0 };
    return { count: r.statusCode == 200 ? t?.totalBanners || 0 : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

function BannerListComponent({ type = "" }) {
  const { register, getValues, control, setValue } = useForm({
    defaultValues: filterView.filterFormData,
  });

  const [data, setData] = useState([]);

  const [activeTab, setActiveTab] = useState({
    key: tabs[0].key,
  });

  // For  List Data Loader
  const [loadingData, setLoadingData] = useState(true);

  // For Pagination Count Loader
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [summaryCard, setSummaryCard] = useState([]);

  const [showAdvFilterModal, setShowAdvFilterModal] = useState(false);

  const [showManageSliderModal, setShowManageSliderModal] = useState({
    show: false,
    bannerId: "",
    subBannerId: "",
  });

  const [showTab, setShowTab] = useState(true);

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [filterLabels, setFilterLabels] = useState([]);

  const [bannerDesignList, setBannerDesignList] = useState({
    loading: false,
    data: [],
    options: [],
  });

  const [auditLogsModal, setAuditLogsModal] = useState({
    show: false,
    data: {},
  });

  const filterDataRef = useRef({ periodType: tabs[0].key });

  const sortRef = useRef(defaultSort);
  // state list  data
  const stateListRef = useRef([]);

  const paginationRef = useRef({ ...listView.pagination });

  useEffect(() => {
    init();
  }, [init]);

  const init = useCallback(() => {
    loadSummary();
    applyFilter();
    loadStateList();
    loadBannerDesignList();
  }, [applyFilter, loadBannerDesignList, loadSummary]);

  const loadBannerDesignList = useCallback(async () => {
    setBannerDesignList({ loading: true, data: [] });
    const r = await BannerService.getBannerLocationMasterList({
      page: 1,
      count: 200,
    });
    if (Array.isArray(r.resp)) {
      let op = [{ label: "All", value: "" }];
      let list = sortBy(r.resp, "page");
      uniqBy(list, "page").forEach((e) => {
        op.push({ label: e.page, value: e.page });
      });
      setBannerDesignList({
        loading: false,
        data: r.resp,
        options: op,
      });
    } else {
      setBannerDesignList({
        loading: false,
        data: [],
        options: [],
      });
    }
  }, [setBannerDesignList]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...listView.pagination };

    loadList();

    prepareFilterLabels();

    // for total Pagination records
    setLoadingTotalRecords(true);
    const p = getFilterParams();
    const c = await getCount(p);

    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [loadList]);

  // List
  const loadList = useCallback(async () => {
    setLoadingData(true);
    const p = getFilterParams();
    const d = await getData(p);
    // To Attach Additional Data
    d.forEach((z) => {
      z.list.forEach((x) => {
        let k = bannerCodeOptions.filter((k) => {
          return k.value == x.bannerLocationCode || "";
        });

        x._bannerLocation = k.length ? k[0]?.location || "" : "";
        x._bannerPage = k.length ? k[0]?.page || "" : "";

        z._total = 0;
        z._upcoming = 0;
        z._live = 0;
        z._expired = 0;
        x._banners = [];
        x.bannersList = [x.banners];
        (x.bannersList || []).forEach((e) => {
          e._activeStatus = BannerService.getBannerStatusByDate(
            e?.period?.date?.startDate,
            e?.period?.date?.endDate
          );
          if (
            differenceInDays(new Date(e?.period?.date?.endDate), new Date()) <=
              1 &&
            e._activeStatus?.key != "expired" &&
            e._activeStatus?.key == "active"
          ) {
            e._isExpiringSoon = true;
          } else {
            e._isExpiringSoon = false;
          }

          if (e._activeStatus?.key == "upcoming") {
            z._upcoming++;
          }
          if (e._activeStatus?.key == "active") {
            z._live++;
          }
          if (e._activeStatus?.key == "expired") {
            z._expired++;
          }

          z._total++;

          if (
            // filterDataRef.current.periodType == "All" ||
            1 ||
            e._activeStatus?.apiKey == filterDataRef.current.periodType
          ) {
            x._banners.push(e);
          }
        });
      });
    });

    // setAdditionalData(d.list, listView.additionalTableDataConfig, (x) => {
    //   tmp.push(x);
    //   if (tmp.length == listView.additionalTableDataConfig.length) {
    //     setData([...attachAllData(d.list, tmp)]);
    //   }
    // });
    setData(d);
    setLoadingData(false);
  }, [attachAllData, setAdditionalData]);

  // Summary
  const loadSummary = useCallback(async () => {
    let k = [...listView.summaryData].map((x) => {
      let p = getFilterParams();
      // if (p.filter) {
      //   delete p.filter?.isActive;
      // }
      delete p.filter;
      p.filter = {
        platformType: "ClubApp",
      };
      return {
        ...x,
        filterParams: { ...p.filter, ...x.filter },
      };
    });
    setSummaryCard(k);
  }, []);

  //Fetching  State List for Filter Form
  const loadStateList = useCallback(async () => {
    let r = await UtilityService.getStateList();
    let list = (r.resp || []).map((e) => {
      return { label: e.name, value: e.name };
    });
    list.unshift({ label: "All", value: "" });
    stateListRef.current = list;
  }, []);

  // Get Filter Params
  const getFilterParams = () => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  };

  // For Applying Filter Params
  const prepareFilterLabels = useCallback(() => {
    const v = { ...(filterDataRef.current || {}) };
    delete v.name;
    delete v.periodType;

    if (v.customerstatus) {
      let s = filterView.criteriaCustomerStatusOptions.find(
        (k) => v.customerstatus === k.value
      );
      v.customerstatus = s?.label || "";
    }

    // Applicable For
    if (v.applicableFor) {
      let s = filterView.applicableForOptions.find(
        (k) => v.applicableFor === k.value
      );
      v.applicableFor = s?.label || "";
    }

    //  Access Level
    if (v.accessLevel) {
      let s = filterView.accessLevelOptions.find(
        (k) => v.accessLevel === k.value
      );
      v.accessLevel = s?.label || "";
    }

    if (type == "clubApp") {
      delete v.platformType;
    }

    //  Platform Type
    if (v.platformType) {
      let s = filterView.platformTypeOptions.find(
        (k) => v.platformType === k.value
      );
      v.platformType = s?.label || "";
    }

    //  Banner Location Code
    if (v.bannerLocationCode) {
      let s = bannerCodeOptions.find((k) => v.bannerLocationCode === k.value);
      v.bannerLocationCode = s?.label || "";
    }

    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    setFilterLabels(l);
  }, []);

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const prepareFilterParams = (pagination = {}, filter = {}) => {
    let p = {
      page: pagination.activePage,
      count: pagination.rowsPerPage,
      filter: {},
      groupBy: "clubBanner",
      outputType: "List",
      sort: { createdAt: -1 },
    };

    let d = filter || {};
    let searchKey = d.name?.length
      ? { $regex: d.name.trim(), $options: "i" }
      : "";

    if (searchKey) {
      p.filter = {
        $and: [
          {
            $or: [
              { "banners.title": searchKey },
              { title: searchKey },
              { _id: searchKey },
            ],
          },
        ],
      };
    }

    if (d?.status?.length) {
      // p.subBannerStatus = d.status;
      p.filter["banners.status"] = d.status;
    }

    if (d?.page?.length) {
      p.filter.page = d.page;
    }

    if (d?.location?.length) {
      p.filter.location = d.location;
    }

    if (d?.filterByConditionType) {
      p.filter["banners.filterByConditionType"] = d?.filterByConditionType;
    }

    if (d?.applicableFor) {
      p.filter.applicableFor = d.applicableFor;
    }

    // Time
    if (d?.startTime?.length > 0) {
      p.filter["banners.period.timeRanges.startTime"] = { $gte: d?.startTime };
    }
    if (d?.endTime?.length > 0) {
      p.filter["banners.period.timeRanges.endTime"] = { $lte: d?.endTime };
    }

    // Access Level
    if (d?.accessLevel) {
      p.filter["banners.location.accessLevel"] = d.accessLevel;
    }

    // Banner Location Code
    if (d?.bannerLocationCode) {
      p.filter["bannerLocationCode"] = d.bannerLocationCode;
    }

    // Plat form Type
    // if (d?.platformType) {
    //   p.filter["platformType"] = d.platformType;
    // }

    // if (type == "clubApp") {
    p.filter["platformType"] = "ClubApp";
    // }

    // Filter By Franchise  Type
    if (d?.filterByFranchiseType) {
      p.filter["banners.filterByFranchiseType"] = d.filterByFranchiseType;
    }

    // Filter By Franchise Group Type
    if (d?.filterByFranchiseGroupType) {
      p.filter["banners.filterByFranchiseGroupType"] =
        d.filterByFranchiseGroupType;
    }

    // State
    if (d?.accessLevel == "state") {
      p.filter["banners.location.state"] = d.state;
      p.filter["banners.location.district"] = d.district;
      p.filter["banners.location.town"] = d.town;
    }

    if (d?.fran?.length) {
      // p.filter["franchiseId"] = d.fran[0]?.value?._id || "";
      p.filter["banners.filterByFranchiseId"] = d.fran[0]?.value?._id || "";
    }

    // RF
    if (d?.rf?.length) {
      p.filter["banners.location.rf"] = d.rf[0]?.value?._id || "";
    }

    // RMF
    if (d?.rmf?.length) {
      p.filter["banners.location.rmf"] = d.rmf[0]?.value?._id || "";
    }

    // SF
    if (d?.sf?.length) {
      p.filter["banners.location.sf"] = d.sf[0]?.value?._id || "";
    }

    if (d?.dateRange?.length) {
      p.filter["banners.period.date.startDate"] = {
        $gte: set(d.dateRange[0], {
          hours: 0,
          minutes: 0,
          seconds: 0,
        }),
      };
      p.filter["banners.period.date.endDate"] = {
        $lte: set(d.dateRange[1], {
          hours: 23,
          minutes: 59,
          seconds: 59,
        }),
      };
    }

    if (activeTab.key.length && activeTab.key != "All") {
      p.periodType = activeTab.key;
    }

    if (filter.periodType == "Present" && !d?.dateRange?.length) {
      p.subBannerStatus = "LIVE";
      // p.groupBy = "totalBanners";
      // let sd = set(new Date(), {
      //   hours: 0,
      //   minutes: 0,
      //   seconds: 0,
      // });
      // let ed = set(new Date(), {
      //   hours: 23,
      //   minutes: 59,
      //   seconds: 59,
      // });
      p.filter["banners.period.date.startDate"] = { $lte: new Date() };
      p.filter["banners.period.date.endDate"] = { $gte: new Date() };
      // p.filter["$or"] = [
      //   {
      //     "banners.period.date.startDate": { $lte: sd },
      //     "banners.period.date.endDate": { $gte: ed },
      //   },
      //   {
      //     "banners.period.date.startDate": { $gte: sd },
      //     "banners.period.date.endDate": { $lte: ed },
      //   },
      // ];
    }

    let today = new Date();
    let yesterday = new Date(today).setDate(today.getDate() - 1);

    if (filter.periodType == "Past" && !d?.dateRange?.length) {
      p.subBannerStatus = "EXPIRED";
      p.filter["banners.period.date.endDate"] = {
        $lte: set(new Date(yesterday), {
          hours: 23,
          minutes: 59,
          seconds: 59,
        }),
      };
    }

    if (filter.periodType == "Future" && !d?.dateRange?.length) {
      p.subBannerStatus = "UPCOMING";
      p.filter["banners.period.date.startDate"] = {
        $gt: add(new Date(), {
          minutes: 1,
        }),
      };
    }

    return p;
  };

  // For Searching via Brand
  const onSearch = useCallback(
    debounce(() => {
      filterDataRef.current = {
        ...filterDataRef.current,
        name: getValues("name"),
      };
      applyFilter();
    }, 700),
    [applyFilter, getValues]
  );

  // To Handle Advance Filter
  const advFilterCb = useCallback((data) => {
    if (["applied", "reset"].indexOf(data.status) != -1) {
      filterDataRef.current = { ...filterDataRef.current, ...data.formData };
      paginationRef.current = { ...listView.pagination };
      applyFilter();
    }
    setShowAdvFilterModal(false);
  }, []);

  const editCb = useCallback((d) => {
    NavService.openInNewTab("/configs/banner/manage", {
      loc: d.location,
      page: d.page,
    });
  }, []);

  const viewCb = useCallback((id) => {
    let p = {
      id: id,
    };
    if (type.length) {
      p.type = type;
    }
    NavService.openInNewTab("/configs/banner/view", p);
  }, []);

  const editSliderCb = useCallback((d) => {
    setShowManageSliderModal({
      show: true,
      bannerId: d?._id,
      subBannerId: d?._banners[0]?._id || {},
    });
  }, []);

  const addBanner = () => {
    let p = {};
    if (type?.length) {
      p.type = type;
    }
    NavService.openInNewTab("/configs/banner/manage", p);
  };

  const auditCb = (k) => {
    let d = k?.banners || {};
    setAuditLogsModal({
      show: true,
      data: d?.auditLog?.length ? [...d?.auditLog] : [],
    });
  };

  const auditLogsModalCb = () => {
    setAuditLogsModal({ show: false, data: {} });
  };

  // For Opening Advance Filter Modal
  const openAdvFilterModal = useCallback(() => setShowAdvFilterModal(true), []);

  const manageSliderModalCb = (d) => {
    setShowManageSliderModal({ show: false, bannerId: "", subBannerId: "" });
    if (d?.status == "success") {
      init();
    }
  };

  // Tab callback
  const tabCb = useCallback(
    (d) => {
      setValue("dateRange", []);
      setActiveTab({ ...d.value });
      filterDataRef.current = {
        ...filterDataRef.current,
        dateRange: [],
        periodType: d?.value?.key,
      };
      applyFilter();
    },
    [setActiveTab, applyFilter, setValue]
  );

  const onDateRangeChange = useCallback(
    (chngFn) => async (val) => {
      chngFn(val);
      filterDataRef.current = {
        ...filterDataRef.current,
        dateRange: val,
      };

      // filterDataRef.current = { ...filterDataRef.current, ...getValues() };
      applyFilter();
      setShowTab(val.length > 1 ? false : true);
    },
    [applyFilter, getValues, setShowTab]
  );

  return (
    <div>
      {/* SUMMARY OVERVIEW */}
      <div className="row mb-2">
        {summaryCard.map((card, i) => (
          <div className="col-3" key={i}>
            <BannerSummaryCard
              label={card.label}
              color={card?.color}
              filterParams={card.filterParams}
              img={card.img}
              status={card.status}
            />
          </div>
        ))}
      </div>
      {/* Filter  Block  */}
      <AppCard>
        <div className="row align-items-center">
          <div className="col-6">
            <div className="row">
              <div className="col-6 align-self-center">
                {/* <label className="mb-1 fs-val-md">Search Banner</label> */}
                <TextInput
                  name="name"
                  type="text"
                  register={register}
                  callback={onSearch}
                  placeholder="Search By Banner Name/ ID"
                  gap={0}
                />
              </div>
              <div className="col-4">
                {/* <label className="mb-1 fs-val-md">
                Banner Active Period Date Range
              </label> */}
                <Controller
                  name="dateRange"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <DatePickerInput
                      placeholder="Banner Validity Date Range"
                      value={value}
                      inpChange={onDateRangeChange(onChange)}
                      config={createdOnDateConfig}
                      gap={0}
                    />
                  )}
                />
              </div>

              <div className="col-auto">
                {bannerDesignList.loading ? (
                  <Spinner />
                ) : (
                  <button
                    className="btn app-filter-btn"
                    type="button"
                    onClick={openAdvFilterModal}
                  >
                    <i className="bi bi-funnel"></i> FILTER
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="col-auto ms-auto align-self-center">
            <Rbac roles={rbac.addButton}>
              <button
                className="btn btn-primary btn-sm fs-val-md me-1"
                onClick={addBanner}
              >
                Add Banner
              </button>
            </Rbac>
          </div>
        </div>
      </AppCard>

      {/* Filter selected Label */}
      <div className="my-3">
        {filterLabels.length > 0 ? (
          <AppliedFilterLabel labels={filterLabels} />
        ) : null}
      </div>

      <AppCard noPad={true}>
        {showTab ? (
          <div className="mb-3">
            <Tabs data={tabs} callback={tabCb} activeTab={activeTab} />
          </div>
        ) : null}
        <Table
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
          editSliderCb={editSliderCb}
          editCb={editCb}
          viewCb={viewCb}
          auditCb={auditCb}
        />
      </AppCard>
      {/* Filter Modal*/}
      <BannerAdvanceFilterModal
        type={type}
        callback={advFilterCb}
        show={showAdvFilterModal}
        stateListOptions={stateListRef.current}
        bannerDesignList={bannerDesignList}
      />
      <ManageBannerSliderModal
        show={showManageSliderModal.show}
        bannerId={showManageSliderModal.bannerId}
        subBannerId={showManageSliderModal.subBannerId}
        callback={manageSliderModalCb}
      />
      <AuditLogModal
        show={auditLogsModal.show}
        auditLog={auditLogsModal.data}
        callback={auditLogsModalCb}
      />
    </div>
  );
}

export default BannerListComponent;
