import { ImgRender } from "@sk/uis";

const style = {
  height: "520px",
  width: "360px",
  overflowX: "hidden",
  backgroundColor: "#e5ddd5",
};

const WhatsAppTemplatePreview = ({
  headerText,
  body,
  footer,
  actions,
  headerImage,
}) => {
  return (
    <div
      className="border-3 border-dark rounded-3 border p-4 custom-scrollbar"
      style={style}
    >
      <div className="bg-white rounded">
        <div className="p-2">
          {headerImage ? (
            <ImgRender assetId={headerImage} isAsset={true} />
          ) : (
            <div
              className="fs-val-sm fw-semibold"
              dangerouslySetInnerHTML={{
                __html: headerText?.replace(/\n/g, "<br/>"),
              }}
            ></div>
          )}
          <div
            className="fs-val-sm"
            dangerouslySetInnerHTML={{ __html: body?.replace(/\n/g, "<br/>") }}
          ></div>
          <div
            className="fs-val-xs text-muted"
            dangerouslySetInnerHTML={{
              __html: footer?.replace(/\n/g, "<br/>"),
            }}
          ></div>
        </div>
        <div>
          {actions?.map((action, index) => (
            <div key={index} className="text-center border-bottom border-1">
              <button className="btn btn-link fs-val-md text-decoration-none">
                {action.type === "coupon_code" && (
                  <>
                    <i className="bi bi-files me-2"></i>
                    <span>Copy Offer Code</span>
                  </>
                )}
                {action.type === "website_url" && (
                  <>
                    <i className="bi bi-link me-2"></i>
                    <span>{action.buttonText}</span>
                  </>
                )}
                {action.type === "quick_replies" && (
                  <>
                    <i className="bi bi-chat-left-text me-2"></i>
                    <span>{action.buttonText}</span>
                  </>
                )}
                {action.type === "phone_number" && (
                  <>
                    <i className="bi bi-telephone me-2"></i>
                    <span>{action.buttonText}</span>
                  </>
                )}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhatsAppTemplatePreview;
