import { NotificationService } from "@sk/services";
import { AppCard, DateFormatter, ImgRender, PageLoader, Tabs } from "@sk/uis";
import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import CustMsgLogsTable from "./components/CustMsgLogsTable";
import CustTemplatesList from "./components/CustTemplatesList";
import { orderBy } from "lodash";

const tabsData = [
  { tabName: "Templates", key: "templates" },
  { tabName: "Logs", key: "logs" },
];

const canvasStyle = {
  width: "50%",
};

const CustomerMsgTemplateModal = ({ show, callback, customer, type }) => {
  const [activeTab, setActiveTab] = useState(tabsData[0]);
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [templateTypes, setTemplateTypes] = useState([]);

  useEffect(() => {
    if (show) {
      const fetchDetails = async () => {
        setLoading(true);
        setTemplates([]);
        // const r = await CustomerService.getDetail(customer?.id, {
        //   select: "promotionalRuleIds",
        // });

        const segmentOptions = NotificationService.getSegmentOptions();

        const promotions = await NotificationService.getPromotionTemplateList({
          page: 1,
          count: segmentOptions.length,
          filter: {
            promotionConfigType: {
              $in: segmentOptions.map((e) => e.value),
            },
          },
        });

        const promotionIds = promotions.resp?.map((e) => e._id);

        const rules =
          await NotificationService.getCustomerPromotionalRuleIdsDetails(
            promotionIds,
            {
              mode: type,
            }
          );

        const rulesData = Array.isArray(rules.resp) ? rules.resp : [];

        const uniqueTypes = [
          { label: "All", value: "all" },
          ...[
            ...new Set(rulesData.map((rule) => rule.promotionConfigType)),
          ].map((type) => ({
            label:
              segmentOptions.find((opt) => opt.value === type)?.label || type,
            value: type,
          })),
        ];

        setTemplateTypes(orderBy(uniqueTypes, ["label"], ["asc"]));
        setTemplates(rulesData);
        setLoading(false);
      };

      fetchDetails();
      setActiveTab(tabsData[0]);
    }
  }, [show, type]);

  const onClose = () => {
    callback && callback({ action: "close" });
  };

  const tabCb = ({ value }) => {
    setActiveTab(value);
  };

  const getHeaderTitle = () => {
    switch (type) {
      case "WhatsApp":
        return "Choose WhatsApp Template";
      case "SMS":
        return "Choose SMS Template";
      default:
        return "Choose Message Template";
    }
  };

  const getLogo = () => {
    switch (type) {
      case "WhatsApp":
        return <i className="bi bi-whatsapp"></i>;
      case "SMS":
        return <i className="bi bi-chat-dots"></i>;
      default:
        return null;
    }
  };

  const getHeaderClass = () => {
    switch (type) {
      case "WhatsApp":
        return "bg-success";
      case "SMS":
        return "bg-info";
      default:
        return "bg-light";
    }
  };

  const getTitleClass = () => {
    switch (type) {
      case "WhatsApp":
        return "text-white";
      case "SMS":
        return "text-white";
      default:
        return "text-dark";
    }
  };

  return (
    <Offcanvas
      show={show}
      onHide={onClose}
      size="lg"
      scrollable={true}
      style={canvasStyle}
      placement="end"
    >
      <Offcanvas.Header closeButton className={getHeaderClass()}>
        <Offcanvas.Title className={getTitleClass()}>
          {getLogo()} {getHeaderTitle()}
        </Offcanvas.Title>
      </Offcanvas.Header>

      <Offcanvas.Body className="modal-bg">
        {loading ? (
          <PageLoader />
        ) : (
          <>
            <AppCard title="Customer Details">
              <div className="d-flex align-items-center gap-4">
                <div
                  className="d-flex align-items-center justify-content-center rounded-circle bg-light overflow-hidden"
                  style={{ width: "60px", height: "60px" }}
                >
                  {customer?.image ? (
                    <ImgRender assetId={customer.image} className="h-100" />
                  ) : (
                    <i className="bi bi-person fs-4"></i>
                  )}
                </div>
                <div className="customer-info flex-grow-1">
                  <div className="mb-1 fs-val-normal fw-bold">
                    {customer?.name}
                  </div>
                  <div className="d-flex gap-3 text-secondary fs-val-sm">
                    <div>
                      <span>ID: {customer?.id}</span>
                    </div>
                    <div>
                      <i className="bi bi-calendar3 me-1"></i>
                      <span>
                        Registered:{" "}
                        <DateFormatter
                          date={customer?.createdAt}
                          format="dd MMM yyyy"
                        />
                      </span>
                    </div>
                    <div>
                      <i className="bi bi-telephone-fill me-1"></i>
                      <span>Mobile: {customer?.mobile || "N/A"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </AppCard>

            <AppCard noPad={true}>
              <Tabs data={tabsData} activeTab={activeTab} callback={tabCb} />

              {activeTab.key == "templates" ? (
                <div className="p-4">
                  <CustTemplatesList
                    templates={templates}
                    templateTypes={templateTypes}
                    customerId={customer?.id}
                    type={type}
                  />
                </div>
              ) : (
                <div className="p-4">
                  <CustMsgLogsTable customerId={customer?.id} type={type} />
                </div>
              )}
            </AppCard>
          </>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default CustomerMsgTemplateModal;
