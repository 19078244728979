import { AuthService, NavService, appConfigs } from "@sk/services";
import { Tabs } from "@sk/uis";
import { useEffect, useState } from "react";
import BannerList from "../banner/list/BannerListComponent";

let tabs = [
  ...appConfigs.MARKETDASHABOARD.tabs.filter((e) => {
    return AuthService.isValidUserRole(e.rbac);
  }),
];
let marketingSubTabs = [...appConfigs.MARKETDASHABOARD.marketingSubTabs];

let smartCoinSubTabs = [...appConfigs.MARKETDASHABOARD.smartCoinSubTabs];

let bogoSubTabs = [...appConfigs.MARKETDASHABOARD.bogoSubTabs];

const MarketingDashboardNavComponent = ({
  activeTab,
  router,
  filterData,
  subTypeKey = "",
}) => {
  const [activeTabKey, setActiveTabKey] = useState("");

  const [subType, setSubType] = useState(subTypeKey || "dashboard");

  useEffect(() => {
    if (activeTab || subTypeKey) {
      setActiveTabKey(activeTab);

      if (subTypeKey.length) {
        setSubType(subTypeKey);
      } else {
        let k = tabs.find((e) => {
          return e.key == activeTab;
        });
        setSubType(k?.key || "");
      }
    }
  }, [activeTab, subTypeKey]);

  const tabCb = (d) => {
    NavService.to(router, d.value.navTo, {
      tab: d.value.key,
      subType: d.value.subType,
    });
  };

  const subTabCb = (d) => {
    NavService.to(router, d.value.navTo, {
      tab: d.value.tab,
      subType: d.value.key,
    });
  };

  return (
    <div>
      <Tabs
        template={4}
        data={tabs}
        callback={tabCb}
        activeTab={{ key: activeTabKey }}
      />
      {activeTabKey == "marketing" ? (
        <div>
          {subType == "dashboard" ? (
            <div className="py-3 bg-white">
              <Tabs
                data={marketingSubTabs}
                callback={subTabCb}
                activeTab={{ key: "dashboard" }}
                template="2"
              />

              {/* <MarketingDashboard filterData={filterData} /> */}
            </div>
          ) : null}
        </div>
      ) : null}

      {activeTabKey == "banner" ? (
        <div className="mt-3">
          {subType == "dashboard" ? (
            <>
              {/* <Tabs
                data={bannerSubTabs}
                callback={subTabCb}
                activeTab={{ key: subType }}
                template="2"
              /> */}
              <BannerList type="clubApp" />
            </>
          ) : null}
        </div>
      ) : null}

      {activeTabKey == "smartcoin" ? (
        <div className="py-3 bg-white">
          <Tabs
            data={smartCoinSubTabs}
            callback={subTabCb}
            activeTab={{ key: subType }}
            template="2"
          />
        </div>
      ) : null}

      {/* {subType == "dashboard" ? (
            <>
              <div className="bg-white">
                <SmartCoinDashboardComponent />
              </div>
              <div className="bg-white">
                <OrderSummaryDashboardComponent />
              </div>
              <div className="bg-white">
                <LevelsDashboardComponent />
              </div>
            </>
          ) : null}  */}

      {/* {activeTabKey == "widget" ? (
        <div className="py-3 bg-white">
          <Tabs
            data={widgetSubTabs}
            callback={subTabCb}
            activeTab={{ key: subType }}
            template="2"
          />
        </div>
      ) : null} */}

      {activeTabKey == "bogo" ? (
        <div className="my-3">
          <Tabs
            data={bogoSubTabs}
            callback={subTabCb}
            activeTab={{ key: subType }}
            template="3"
          />
        </div>
      ) : null}

      {/* {activeTabKey == "rsp" ? (
        <div className="py-3 bg-white">
          <Tabs
            data={rspSubTabs}
            callback={subTabCb}
            activeTab={{ key: subType }}
            template="2"
          />
        </div>
      ) : null} */}

      {/* {activeTabKey == "purchaseLimit" ? (
        <div className="py-3 bg-white">
          <Tabs
            data={purchaseLimitSubTabs}
            callback={subTabCb}
            activeTab={{ key: subType }}
            template="2"
          />
        </div>
      ) : null} */}
    </div>
  );
};

export default MarketingDashboardNavComponent;
