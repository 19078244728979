import { yupResolver } from "@hookform/resolvers/yup";
import { AuthService, CommonService, VendorService } from "@sk/services"; // Import VendorService
import {
  AppCard,
  DateInput,
  EntitySearchInput,
  PageLoader,
  SelectInput,
  TextInput,
} from "@sk/uis";
import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";

const VendorMarginManageModal = ({ show, callback, vendorId, formData }) => {
  const [vendorDetails, setVendorDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const marginsRef = useRef([]);

  useEffect(() => {
    const fetchVendorDetails = async () => {
      if (show && vendorId) {
        setLoading(true);
        try {
          const response = await VendorService.getVendor(vendorId, {
            select: "name,address,margins,state,district,city,pincode",
          });
          setVendorDetails(response.resp || {});
          marginsRef.current = response.resp.margins || [];
        } catch (error) {
          console.error("Failed to fetch vendor details", error);
          setVendorDetails({});
        } finally {
          setLoading(false);
        }
      }
    };

    fetchVendorDetails();
  }, [show, vendorId]);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      type: "product",
      product: [],
      brand: [],
      category: [],
      margin: "",
      weightage: "",
      startDate: null,
      endDate: null,
      warehouse: "",
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (show && formData) {
      const formattedData = {
        ...formData,
        product: formData.product
          ? [
              {
                label: formData._product.name,
                value: { _id: formData.product },
              },
            ]
          : [],
        brand: formData.brand
          ? [{ label: formData._brand.name, value: { _id: formData.brand } }]
          : [],
        category: formData.category
          ? [
              {
                label: formData._category.name,
                value: { _id: formData.category },
              },
            ]
          : [],
        startDate: formData.start_date ? [formData.start_date] : null,
        endDate: formData.end_date ? [formData.end_date] : null,
      };
      Object.keys(formattedData).forEach((key) => {
        setValue(key, formattedData[key]);
      });
    }
  }, [show, formData, setValue]);

  const [endDateConfig, setEndDateConfig] = useState({
    minDate: new Date(),
  });

  const [type, startDate] = useWatch({
    control,
    name: ["type", "startDate"],
  });

  const onClose = () => {
    callback({
      status: "close",
    });
  };

  const onSubmit = (data) => {
    let t = {
      marginType: data.type,
      margin: data.margin,
      weightage: data.weightage,
      startDate: data.startDate[0],
      endDate: data.endDate[0],
      warehouse: data.warehouse,
    };

    if (type === "product") {
      t.product = data.product[0].value._id;
    } else if (type === "brand_category") {
      t.brand = data.brand[0].value._id;
      t.category = data.category[0].value._id;
    }

    const finalMargins = [...marginsRef.current, t];

    console.log(data);
    console.log(finalMargins);
    // handle form submission
  };

  const handleMarginChange = (e) => {
    let value = e.target.value;

    if (value) {
      value = CommonService.roundedByDecimalPlace(value, 2);
    }

    if (value > 100) {
      value = 100;
    }

    setValue("margin", value);
  };

  const onStartDateChange = (chngFn) => (val) => {
    chngFn(val);
    setEndDateConfig({
      ...endDateConfig,
      minDate: val.length > 0 ? val[0] : new Date(),
    });

    const endDate = getValues("endDate");

    if (val.length > 0 && endDate?.length && val[0] > endDate[0]) {
      setValue("endDate", val);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      size="lg"
      enforceFocus={false}
      autoFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">Manage Vendor Margin</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-bg">
        {loading ? (
          <PageLoader />
        ) : (
          <>
            {vendorDetails?._id && (
              <AppCard title="Vendor Details">
                <div className="mb-1">
                  <span className="fw-bold me-1">{vendorDetails.name} </span>
                  <span className="fs-val-sm text-muted px-1 border rounded">
                    {vendorDetails._id}
                  </span>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <div>
                      <div className="fs-val-md">
                        {vendorDetails?.address?.line1}{" "}
                        {vendorDetails?.address?.line2}
                      </div>
                      <div className="fs-val-md text-muted">
                        {vendorDetails?.state},{" "}
                        {vendorDetails?.district || "--"}, {vendorDetails?.city}{" "}
                        - {vendorDetails?.pincode}
                      </div>
                    </div>
                  </div>
                </div>
              </AppCard>
            )}
            <AppCard>
              <div className="fs-val-sm mb-1">
                Update margin details for this vendor
              </div>
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className="row">
                  <div className="col-6">
                    <SelectInput
                      name="type"
                      register={register}
                      options={typeOptions}
                      isMandatory
                      label="Margin Type"
                      error={errors.type?.message}
                    />
                  </div>
                  <div className="col-6">
                    <SelectInput
                      name="warehouse"
                      register={register}
                      options={warehouseOptions}
                      isMandatory
                      label="Warehouse"
                      error={errors.warehouse?.message}
                    />
                  </div>
                </div>

                {type === "product" && (
                  <div className="row">
                    <div className="col-12">
                      <Controller
                        name="product"
                        control={control}
                        render={({ field }) => (
                          <EntitySearchInput
                            label="Product"
                            placeholder="Search by Product"
                            type="product"
                            filterParams={productFilterParams}
                            callback={field.onChange}
                            value={field.value}
                            isMandatory
                            error={errors.product?.message}
                            uid="vendor_margin_product"
                          />
                        )}
                      />
                    </div>
                  </div>
                )}

                {type === "brand_category" && (
                  <div className="row">
                    <div className="col-6">
                      <Controller
                        name="brand"
                        control={control}
                        render={({ field }) => (
                          <EntitySearchInput
                            label="Brand"
                            placeholder="Search by Brand"
                            type="brand"
                            filterParams={brandFilterParams}
                            callback={field.onChange}
                            value={field.value}
                            isMandatory
                            error={errors.brand?.message}
                            uid="vendor_margin_brand"
                          />
                        )}
                      />
                    </div>
                    <div className="col-6">
                      <Controller
                        name="category"
                        control={control}
                        render={({ field }) => (
                          <EntitySearchInput
                            label="Category"
                            placeholder="Search by Category"
                            type="category"
                            filterParams={categoryFilterParams}
                            callback={field.onChange}
                            value={field.value}
                            isMandatory
                            error={errors.category?.message}
                            uid="vendor_margin_category"
                          />
                        )}
                      />
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-6">
                    <TextInput
                      name="margin"
                      label="Margin"
                      placeholder="Enter Margin"
                      register={register}
                      type="number"
                      isMandatory
                      error={errors.margin?.message}
                      max={100}
                      callback={handleMarginChange}
                    />
                  </div>
                  <div className="col-6">
                    <TextInput
                      name="weightage"
                      label="Weightage"
                      placeholder="Enter Weightage"
                      register={register}
                      type="number"
                      isMandatory
                      error={errors.weightage?.message}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <Controller
                      name="startDate"
                      control={control}
                      render={({ field }) => (
                        <DateInput
                          label="Start Date"
                          placeholder="Select Start Date"
                          callback={onStartDateChange(field.onChange)}
                          value={field.value}
                          isMandatory
                          error={errors.startDate?.message}
                          config={startDateConfig}
                        />
                      )}
                    />
                  </div>
                  <div className="col-6">
                    <Controller
                      name="endDate"
                      control={control}
                      render={({ field }) => (
                        <DateInput
                          label="End Date"
                          placeholder="Select End Date"
                          callback={field.onChange}
                          value={field.value}
                          isMandatory
                          error={errors.endDate?.message}
                          config={endDateConfig}
                          disabled={!startDate?.length}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="mt-3 text-end">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </AppCard>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

const schema = yup.object().shape({
  type: yup.string().required("Type is required"),
  product: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.object().shape({
          _id: yup.string().required(),
        }),
      })
    )
    .when("type", {
      is: "product",
      then: (schema) => schema.min(1, "Product is required"),
    }),
  brand: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.object().shape({
          _id: yup.string().required(),
        }),
      })
    )
    .when("type", {
      is: "brand_category",
      then: (schema) => schema.min(1, "Brand is required"),
    }),
  category: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.object().shape({
          _id: yup.string().required(),
        }),
      })
    )
    .when("type", {
      is: "brand_category",
      then: (schema) => schema.min(1, "Category is required"),
    }),
  margin: yup
    .number()
    .typeError("Margin must be a number")
    .required("Margin is required")
    .min(1, "Margin must be greater than 0")
    .max(100, "Margin must be less than or equal to 100"),
  weightage: yup
    .number()
    .typeError("Weightage must be a number")
    .required("Weightage is required"),
  startDate: yup
    .date()
    .typeError("Start Date must be a valid date")
    .required("Start Date is required"),
  endDate: yup
    .date()
    .typeError("End Date must be a valid date")
    .required("End Date is required"),
  warehouse: yup.string().required("Warehouse is required"), // Add warehouse validation
});

const typeOptions = [
  { label: "Product", value: "product" },
  { label: "Brand/Category", value: "brand_category" },
];

const productFilterParams = {
  filter: { status: "Active" },
};

const brandFilterParams = {
  filter: { status: "Active" },
};

const categoryFilterParams = {
  filter: { status: "Active" },
};

const startDateConfig = {
  minDate: new Date(),
};

const warehouseOptions = AuthService.getLoggedInEmpWh().map((x) => ({
  label: x.name,
  value: x._id,
}));
warehouseOptions.unshift({
  label: "Choose",
  value: "",
});

export default VendorMarginManageModal;
