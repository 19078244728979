import { useState } from "react";
import { Modal } from "react-bootstrap";

const RemarksModal = ({
  show,
  callback,
  maxLength = 500,
  placeholder = "Enter your remarks here...",
  title = "Enter Remarks",
}) => {
  const [remarks, setRemarks] = useState("");

  const handleRemarksChange = (event) => {
    setRemarks(event.target.value);
  };

  const handleClose = () => {
    callback({ action: "close" });
  };

  const handleSave = () => {
    callback({ action: "submit", remarks });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton className="bg-light">
        <Modal.Title className="text-dark mb-0">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea
          className="form-control"
          name="remarks"
          placeholder={placeholder}
          onChange={handleRemarksChange}
          rows={5}
          maxLength={maxLength}
        />
        <div className="fs-val-sm text-muted">
          {remarks.length} / {maxLength}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={handleSave}>
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemarksModal;
