import { CustomerService, CommonService } from "@sk/services";
import {
  AppCard,
  DateFormatter,
  HighlightText,
  ImgRender,
  NoDataFound,
  PaginationBlock,
  TableHeader,
  TableSkeletonLoader,
  Toaster,
  InfoPopover,
} from "@sk/uis";
import { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useAttachAdditionalData } from "@sk/hooks";

const tableColumns = [
  { label: "S.No", key: "slNo", enableSort: false },
  { label: "Date & Time", key: "createdAt", enableSort: true },
  { label: "Duration", key: "duration", enableSort: true },
  { label: "Status", key: "status", enableSort: true },
  { label: "Called By", key: "createdBy" },
  { label: "Recording", key: "audioUrl" },
];

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 15,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 15,
};

const additionalDataConfig = [
  {
    key: "createdBy",
    api: "user",
    loadingKey: "createdByLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const getData = async (params) => {
  const r = await CustomerService.getCallLogs(params);
  const d = Array.isArray(r.resp) ? r.resp : [];
  return { data: d };
};

const getCount = async (params) => {
  let p = { ...params };
  delete p.page;
  delete p.count;

  const r = await CustomerService.getCallLogsCount(p);
  return { count: r.count };
};

const prepareFilterParams = (filter, pagination) => {
  let p = {
    page: pagination?.activePage,
    count: pagination?.rowsPerPage,
    filter: {
      "customerInfo.id": filter.cid,
      type: "VoiceCall",
    },
    sort: "-createdAt",
  };
  return p;
};

const CustomerCallLogs = ({ show, callback, customer }) => {
  const [callLogs, setCallLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dailing, setDailing] = useState(false);
  const [playingAudioId, setPlayingAudioId] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(
    CommonService.isOzonetelLoggedIn()
  );
  const audioRefs = useRef({});

  const [totalCount, setTotalCount] = useState({
    loading: false,
    value: 0,
  });

  const paginationRef = useRef({ ...defaultPaginationConfig });
  const filterRef = useRef({
    cid: customer?.id,
  });
  const sortRef = useRef({
    key: "createdAt",
    value: "desc",
  });

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };

    loadList();

    setTotalCount({ loading: true, value: 0 });
    const p = await getCount(
      prepareFilterParams(filterRef.current, paginationRef.current)
    );
    setTotalCount({ loading: false, value: p.count });
    paginationRef.current.totalRecords = p.count;
  }, []);

  const loadList = useCallback(async () => {
    setLoading(true);
    setCallLogs([]);

    const p = prepareFilterParams(filterRef.current, paginationRef.current);
    const r = await getData(p);
    const d = r.data;

    let tmp = [];
    setAdditionalData(d, additionalDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == additionalDataConfig.length) {
        setCallLogs([...attachAllData(d, tmp)]);
      }
    });

    setCallLogs(d);
    setLoading(false);
  }, [attachAllData, setAdditionalData]);

  useEffect(() => {
    if (show) {
      filterRef.current = {
        cid: customer?.id,
      };
      applyFilter();
    }
  }, [show]);

  const handleDial = async () => {
    setDailing(true);
    const r = await CustomerService.callToCustomer({
      customerId: customer?.id,
    });
    setDailing(false);
    if (r.statusCode == 200) {
      if (r.resp?.status !== "Failed") {
        Toaster.success("Call to customer initiated successfully");
      } else {
        Toaster.error(r.resp?.message || "Failed to initiate call");
      }
    } else {
      Toaster.error(r.resp?.message || "Failed to initiate call");
    }
    applyFilter();
  };

  const onClose = () => {
    if (playingAudioId) {
      const audioElement = audioRefs.current[playingAudioId];
      if (audioElement) {
        audioElement.pause();
        audioElement.currentTime = 0;
      }
      setPlayingAudioId(null);
    }
    callback && callback({ action: "close" });
  };

  const handlePlay = (id) => {
    const audioElement = audioRefs.current[id];

    if (playingAudioId && playingAudioId !== id) {
      const prevAudio = audioRefs.current[playingAudioId];
      if (prevAudio) {
        prevAudio.pause();
        prevAudio.currentTime = 0;
      }
    }

    if (audioElement) {
      if (playingAudioId === id) {
        audioElement.pause();
        audioElement.currentTime = 0;
        setPlayingAudioId(null);
      } else {
        audioElement.play();
        setPlayingAudioId(id);
      }
    }
  };

  const paginationCb = (paginationConfig) => {
    paginationRef.current = {
      ...paginationRef.current,
      ...paginationConfig,
    };
    applyFilter();
  };

  const sortCb = (sort) => {
    sortRef.current = sort;
    applyFilter();
  };

  const handleLoginToCall = () => {
    CommonService.attachOzonetelIframe();
    setIsLoggedIn(true);
  };

  return (
    <Modal show={show} onHide={onClose} size="lg" scrollable={true}>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">Customer Call Logs</Modal.Title>
      </Modal.Header>

      <Modal.Body className="modal-bg">
        <AppCard title="Customer Details">
          <div className="d-flex align-items-center gap-4">
            <div
              className="d-flex align-items-center justify-content-center rounded-circle bg-light overflow-hidden"
              style={{ width: "60px", height: "60px" }}
            >
              {customer?.image ? (
                <ImgRender assetId={customer.image} className="h-100" />
              ) : (
                <i className="bi bi-person fs-4"></i>
              )}
            </div>
            <div className="customer-info flex-grow-1">
              <div className="mb-1 fs-val-normal fw-bold">{customer?.name}</div>
              <div className="d-flex gap-3 text-secondary fs-val-sm">
                <div>
                  <span>ID: {customer?.id}</span>
                </div>
                <div>
                  <i className="bi bi-calendar3 me-1"></i>
                  <span>
                    Registered:{" "}
                    <DateFormatter
                      date={customer?.createdAt}
                      format="dd MMM yyyy"
                    />
                  </span>
                </div>
                <div>
                  <i className="bi bi-telephone-fill me-1"></i>
                  <span>Mobile: {customer?.mobile || "N/A"}</span>
                </div>
              </div>
            </div>
            <div>
              {isLoggedIn ? (
                <button
                  className="btn btn-primary"
                  onClick={handleDial}
                  disabled={dailing}
                >
                  <i className="bi bi-telephone-fill me-2"></i>
                  {dailing ? "Dialing..." : "Call To Customer"}
                </button>
              ) : (
                <button
                  className="btn btn-secondary"
                  onClick={handleLoginToCall}
                >
                  Login to Call
                </button>
              )}
            </div>
          </div>
        </AppCard>

        <AppCard title={"Call Logs History"}>
          <div className="row">
            <div className="col fs-val-md text-muted">
              Showing last 60 days call logs
            </div>
            <div className="col-auto text-end">
              <button
                className="btn btn-link btn-sm fs-val-md"
                onClick={applyFilter}
              >
                <i className="bi bi-arrow-clockwise"></i> Refresh
              </button>
            </div>
          </div>
          <div>
            <table className="table table-sm">
              <TableHeader
                data={tableColumns}
                sortCb={sortCb}
                sort={sortRef.current}
                sticky={true}
                noBg
              />
              <tbody className="fs-val-sm">
                {loading ? (
                  <TableSkeletonLoader cols={tableColumns.length} rows={10} />
                ) : null}

                {!loading && callLogs.length == 0 ? (
                  <tr>
                    <td colSpan={tableColumns.length} className="text-center">
                      <NoDataFound>No call logs found</NoDataFound>
                    </td>
                  </tr>
                ) : null}

                {callLogs.map((log, index) => (
                  <tr key={index}>
                    <td>{paginationRef.current.startSlNo + index}</td>
                    <td>
                      <DateFormatter
                        date={log.createdAt}
                        format="dd MMM yyyy hh:mm a"
                      />
                    </td>
                    <td>{log.callbackResponse?.CallDuration}</td>
                    <td>
                      <HighlightText type={log._statusType} template={2}>
                        {log.status}
                      </HighlightText>
                      {log.status === "Failed" &&
                        log.responseLog?.data?.message && (
                          <InfoPopover
                            content={log.responseLog.data.message}
                            placement="right"
                          />
                        )}
                    </td>
                    <td>{log._createdBy?.name || "--"}</td>
                    <td>
                      {log.audioUrl ? (
                        <>
                          <button
                            className={`btn ${
                              playingAudioId === log._id
                                ? "btn-primary"
                                : "btn-outline-secondary"
                            } rounded-circle btn-sm m-0`}
                            onClick={() => handlePlay(log._id)}
                          >
                            <i
                              className={`bi bi-${
                                playingAudioId === log._id
                                  ? "pause-fill"
                                  : "play-fill"
                              }`}
                            ></i>
                          </button>

                          <audio
                            ref={(element) =>
                              (audioRefs.current[log._id] = element)
                            }
                            className="d-none"
                          >
                            <source src={log.audioUrl} type="audio/mpeg" />
                          </audio>
                        </>
                      ) : (
                        "--"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="text-end pt-2">
            <PaginationBlock
              paginationConfig={paginationRef.current}
              loadingTotalRecords={totalCount.loading}
              paginationCb={paginationCb}
              size="sm"
            />
          </div>
        </AppCard>
      </Modal.Body>
    </Modal>
  );
};

export default CustomerCallLogs;
