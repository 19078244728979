import { CommonService } from "@sk/services";

import format from "date-fns/format";

import cloneDeep from "lodash/cloneDeep";

const rupeeSymbol = `<span class="rupee-symbol" >&#8377;</span>`;

function formatDate(d) {
  let date = new Date(d);
  return format(date, "dd-MM-yyyy");
}

function formatAmount(d, decimalPlace = 2) {
  return CommonService.rupeeFormat(d, decimalPlace);
}

const doPrint = (d) => {
  const data = cloneDeep(d);

  const { images, invoice, order, address } = data;

  let deals = invoice?.deals;

  let tBodyData = ``;

  const getPackageData = (packages) => {
    let packagesRow = `<div class="row ms-0">
    <div class="col-6 fw-bold">Package ID</div>
    <div class="col-6 fw-bold">Qty</div>
  </div>`;

    packages.forEach((pack) => {
      packagesRow += `<div class="row ms-0">
        <div class="col-6">${pack.packageNo}</div>
        <div class="col-6">${pack.quantity}</div>
      </div>`;
    });
    return packagesRow;
  };

  deals?.forEach((deal) => {
    deal.products?.forEach((product, i) => {
      tBodyData += `
        <tr>
            <td class="p-2 text-center">${deal.id}</td>
            <td class="p-2 text-center">${product.name}</td>
            <td class="p-2 text-center">${product.quantity}</td>
            <td class="p-2 text-center">${getPackageData(product.packages)}</td>
        </tr>
      `;
    });
  });

  const template = `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
    <link href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800" rel="stylesheet" />
    <style>
          * {
              padding: 0;
              margin: 0;
              box-sizing: border-box;
              font-family: "poppins";
  
          }

          .rupee-symbol{
            font-family: 'sans-serif';
          }
  
          .text-sm {
              font-size: 0.875rem;
          }
  
          .text-right {
              text-align: right;
          }
  
          .text-end {
              text-align: end;
          }
  
          .text-center {
              text-align: center;
          }
  
          .flex {
              display: flex;
          }
  
          .justify-content-between {
              justify-content: space-between;
          }
  
          .align-items-center {
              align-items: center;
          }
  
          .bg-gray-200 {
              background-color: #edf2f7;
          }
  
          .bg-gray-400 {
              background-color: #cbd5e0;
          }
  
          .w-32 {
              width: 8rem;
          }
  
          .w-full {
              width: 100%;
          }
  
  
          .border {
              border: 1px solid #e2e8f0;
          }
  
  
  
  
          .container {
              width: 100%;
              margin-right: auto;
              margin-left: auto;
          }
  
  
          .row {
              display: flex;
              flex-wrap: wrap;
              margin-right: -15px;
              margin-left: -15px;
          }
  
  
          .col {
              flex-basis: 0;
              flex-grow: 1;
              max-width: 100%;
              padding-right: 15px;
              padding-left: 15px;
          }
  
          .col-auto {
              flex: 0 0 auto;
              width: auto;
              padding-right: .25rem;
              padding-left: .25rem;
          }
  
  
          .col-1 {
              flex: 0 0 8.33%;
              max-width: 8.33%;
          }
  
          .col-2 {
              flex: 0 0 16.66%;
              max-width: 16.66%;
          }
  
          .col-3 {
              flex: 0 0 25%;
              max-width: 25%;
          }
  
          .col-4 {
              flex: 0 0 33.33%;
              max-width: 33.33%;
          }
  
          .col-5 {
              flex: 0 0 41.66%;
              max-width: 41.66%;
          }
  
          .col-6 {
              flex: 0 0 50%;
              max-width: 50%;
          }
  
          .col-7 {
              flex: 0 0 58.33%;
              max-width: 58.33%;
          }
  
          .col-8 {
              flex: 0 0 66.66%;
              max-width: 66.66%;
          }
  
          .col-9 {
              flex: 0 0 75%;
              max-width: 75%;
          }
  
          .col-10 {
              flex: 0 0 83.33%;
              max-width: 83.33%;
          }
  
          .col-11 {
              flex: 0 0 91.66%;
              max-width: 91.66%;
          }
  
          .col-12 {
              flex: 0 0 100%;
              max-width: 100%;
          }
  
          .fs-1 {
              font-size: 2.5rem;
          }
  
          .fs-2 {
              font-size: 2rem;
          }
  
          .fs-3 {
              font-size: 1.75rem;
          }
  
          .fs-4 {
              font-size: 1.5rem;
          }
  
          .fs-5 {
              font-size: 1.25rem;
          }
  
          .fs-6 {
              font-size: 1rem;
          }
  
          .fw-bold {
              font-weight: 700;
          }
  
          .fw-normal {
              font-weight: 400;
          }
  
          .fw-light {
              font-weight: 300;
          }
  
          .fw-semibold {
              font-weight: 600;
          }
  
          .lh-1 {
              line-height: 1.5;
          }
  
          .p-0 {
              padding: 0;
          }
  
          .p-1 {
              padding: 0.25rem;
          }
  
          .p-2 {
              padding: 0.5rem;
          }
  
          .p-3 {
              padding: 1rem;
          }
  
          .p-4 {
              padding: 1.5rem;
          }
  
          .p-5 {
              padding: 3rem;
          }
  
          .py-0 {
              padding-top: 0;
              padding-bottom: 0;
          }
  
          .py-1 {
              padding-top: 0.25rem;
              padding-bottom: 0.25rem;
          }
  
          .py-2 {
              padding-top: 0.5rem;
              padding-bottom: 0.5rem;
          }
  
          .py-3 {
              padding-top: 1rem;
              padding-bottom: 1rem;
          }
  
          .py-4 {
              padding-top: 1.5rem;
              padding-bottom: 1.5rem;
          }
  
          .py-5 {
              padding-top: 3rem;
              padding-bottom: 3rem;
          }
  
          .px-0 {
              padding-left: 0;
              padding-right: 0;
          }
  
          .px-1 {
              padding-left: 0.25rem;
              padding-right: 0.25rem;
          }
  
          .px-2 {
              padding-left: 0.5rem;
              padding-right: 0.5rem;
          }
  
          .px-3 {
              padding-left: 1rem;
              padding-right: 1rem;
          }
  
          .px-4 {
              padding-left: 1.5rem;
              padding-right: 1.5rem;
          }
  
          .px-5 {
              padding-left: 3rem;
              padding-right: 3rem;
          }
  
          .ps-0 {
              padding-left: 0;
          }
  
          .pe-0 {
              padding-right: 0;
          }
  
          .pt-0 {
              padding-top: 0;
          }
  
          .pb-0 {
              padding-bottom: 0;
          }
  
          .ps-1 {
              padding-left: 0.25rem;
          }
  
          .pe-1 {
              padding-right: 0.25rem;
          }
  
          .pt-1 {
              padding-top: 0.25rem;
          }
  
          .pb-1 {
              padding-bottom: 0.25rem;
          }
  
          .ps-2 {
              padding-left: 0.5rem;
          }
  
          .pe-2 {
              padding-right: 0.5rem;
          }
  
          .pt-2 {
              padding-top: 0.5rem;
          }
  
          .pb-2 {
              padding-bottom: 0.5rem;
          }
  
          .ps-3 {
              padding-left: 1rem;
          }
  
          .pe-3 {
              padding-right: 1rem;
          }
  
          .pt-3 {
              padding-top: 1rem;
          }
  
          .pb-3 {
              padding-bottom: 1rem;
          }
  
          .ps-4 {
              padding-left: 1.5rem;
          }
  
          .pe-4 {
              padding-right: 1.5rem;
          }
  
          .pt-4 {
              padding-top: 1.5rem;
          }
  
          .pb-4 {
              padding-bottom: 1.5rem;
          }
  
  
          .ps-5 {
              padding-left: 3rem;
          }
  
          .pe-5 {
              padding-right: 3rem;
          }
  
          .pt-5 {
              padding-top: 3rem;
          }
  
          .pb-5 {
              padding-bottom: 3rem;
          }
  
  
          .m-0 {
              margin: 0;
          }
  
          .m-1 {
              margin: 0.25rem;
          }
  
          .m-2 {
              margin: 0.5rem;
          }
  
          .m-3 {
              margin: 1rem;
          }
  
          .m-4 {
              margin: 1.5rem;
          }
  
          .m-5 {
              margin: 3rem;
          }
  
          .my-0 {
              margin-top: 0;
              margin-bottom: 0;
          }
  
          .my-1 {
              margin-top: 0.25rem;
              margin-bottom: 0.25rem;
          }
  
          .my-2 {
              margin-top: 0.5rem;
              margin-bottom: 0.5rem;
          }
  
          .my-3 {
              margin-top: 1rem;
              margin-bottom: 1rem;
          }
  
          .my-4 {
              margin-top: 1.5rem;
              margin-bottom: 1.5rem;
          }
  
          .my-5 {
              margin-top: 3rem;
              margin-bottom: 3rem;
          }
  
          .mx-0 {
              margin-left: 0;
              margin-right: 0;
          }
  
          .mx-1 {
              margin-left: 0.25rem;
              margin-right: 0.25rem;
          }
  
          .mx-2 {
              margin-left: 0.5rem;
              margin-right: 0.5rem;
          }
  
          .mx-3 {
              margin-left: 1rem;
              margin-right: 1rem;
          }
  
          .mx-4 {
              margin-left: 1.5rem;
              margin-right: 1.5rem;
          }
  
          .mx-5 {
              margin-left: 3rem;
              margin-right: 3rem;
          }
  
          .ms-0 {
              margin-left: 0;
          }
  
          .me-0 {
              margin-right: 0;
          }
  
          .mt-0 {
              margin-top: 0;
          }
  
          .mb-0 {
              margin-bottom: 0;
          }
  
          .ms-1 {
              margin-left: 0.25rem;
          }
  
          .me-1 {
              margin-right: 0.25rem;
          }
  
          .mt-1 {
              margin-top: 0.25rem;
          }
  
          .mb-1 {
              margin-bottom: 0.25rem;
          }
  
          .ms-2 {
              margin-left: 0.5rem;
          }
  
          .me-2 {
              margin-right: 0.5rem;
          }
  
          .mt-2 {
              margin-top: 0.5rem;
          }
  
          .mb-2 {
              margin-bottom: 0.5rem;
          }
  
          .ms-3 {
              margin-left: 1rem;
          }
  
          .me-3 {
              margin-right: 1rem;
          }
  
          .mt-3 {
              margin-top: 1rem;
          }
  
          .mb-3 {
              margin-bottom: 1rem;
          }
  
          .ms-4 {
              margin-left: 1.5rem;
          }
  
          .me-4 {
              margin-right: 1.5rem;
          }
  
          .mt-4 {
              margin-top: 1.5rem;
          }
  
          .mb-4 {
              margin-bottom: 1.5rem;
          }
  
  
          .ms-5 {
              margin-left: 3rem;
          }
  
          .me-5 {
              margin-right: 3rem;
          }
  
          .mt-5 {
              margin-top: 3rem;
          }
  
          .mb-5 {
              margin-bottom: 3rem;
          }
  
          .border {
              border: 1px solid #dee2e6;
          }
  
          .border-0 {
              border: 0;
          }
  
  
          .border-top {
              border-top: 1px solid #dee2e6;
          }
  
          .border-right {
              border-right: 1px solid #dee2e6 !important;
          }
  
          .border-bottom {
              border-bottom: 1px solid #dee2e6;
          }
  
          .border-left {
              border-left: 1px solid #dee2e6;
          }
  
          .border-1 {
              border-width: 1px;
          }
  
          .border-2 {
              border-width: 2px;
          }
  
          .border-3 {
              border-width: 3px;
          }
  
          .border-4 {
              border-width: 4px;
          }
  
          .border-5 {
              border-width: 5px;
          }
  
          .border-bottom-0 {
              border-bottom: none;
          }
  
          .border-top-0 {
              border-top: none;
          }
  
          .border-left-0 {
              border-left: none;
          }
  
          .border-right-0 {
              border-right: none;
          }
  
          table {
              width: 100%;
              margin-bottom: 1rem;
              color: #212529;
              border-collapse: collapse;
          }
  
          table th,
          table td {
              padding: 0.75rem;
              vertical-align: middle;
          }
  
          table thead th {
              vertical-align: middle;
              border-bottom: 2px solid #dee2e6;
          }
  
          table tbody+tbody {
              border-top: 2px solid #dee2e6;
          }
  
          .table-bordered {
              border: 1px solid #dee2e6;
          }
  
          .table-bordered th,
          .table-bordered td {
              border: 1px solid #dee2e6;
          }
  
          .table-bordered thead th,
          .table-bordered thead td {
              border-bottom-width: 2px;
          }
  
          ul {
              padding-left: 2rem;
          }
  
          ul li {
              margin-bottom: .5rem;
          }
  
  
          .fs-val-lg {
              font-size: 0.94vw;
          }
  
          .fs-val-normal {
              font-size: 0.9vw;
          }
  
          .fs-val-md {
              font-size: 0.8vw;
          }
  
          .fs-val-sm {
              font-size: 0.66vw;
          }
  
          .fs-val-xs {
              font-size: 0.55vw;
          }
  
          .key-lbl {
              font-size: 0.7vw;
              color: #5e5e5e;
          }

          img{
            vertical-align:middle;
        }

        .border-none{
            border: none !important ;
        }

        @media print {
            * {
                font-size: 12px;
            }
            .fs-val-sm {
                font-size: 0.9vw;
            }
            .fs-val-md {
                font-size: 1vw;
            }
        }
      </style>    

</head>

<body>
    <div class="container">
        <div class="p-5">
            <div class="row border border-1 justify-content-between align-items-center p-3">
                <div class="col-3">
                    <img src= "${images.logo}" />
                </div>
                <div class="col-9 fs-3 fw-bold" style="width: 100%;">
                    <div class="mb-3">Product Wise Package List</div>
                    <div>
                    <span>Invoice Number : </span>
                    <span class="fw-bold">${invoice?._id}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <table class="table table-bordered mb-0">
                    <thead class="bg-light">
                        <tr class="fs-val-md">
                            <th class="p-2 text-center" style="width:10%">Item ID</th>
                            <th class="p-2 text-center" style="width:35%">Item Name</th>
                            <th class="p-2 text-center" style="width:15%">Qty</th>
                            <th class="p-2 text-center">Package Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${tBodyData}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</body>

</html>`;

  const w = window.open("", "_blank");

  w.document.title = "Batch Processing - Print Order vs Invoice";

  w.document.write(template);

  const t = setTimeout(() => {
    w.print();
    clearTimeout(t);
    w.close();
  }, 2000);
};

export default { doPrint };
