import { DealService, FranchiseService } from "@sk/services";
import {
  Amount,
  DateFormatter,
  NoDataFound,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { useCallback, useEffect, useRef, useState } from "react";

const headers = [
  { label: "Sl No", key: "slNo", width: "5%" },
  { label: "Stock ID & Location", key: "_id", width: "25%" },
  { label: "MRP", key: "mrp", width: "10%", isCentered: true },
  {
    label: "Purchase Price",
    key: "purchasePrice",
    width: "12%",
    isCentered: true,
  },
  {
    label: "Shelf Life",
    key: "expiry",
    width: "20%",
    isCentered: true,
  },
  { label: "Barcode", key: "barcode", width: "15%" },
  {
    label: "Available Stock",
    key: "_quantity",
    width: "13%",
    isCentered: true,
  },
];

const defaultPagination = {
  activePage: 1,
  rowsPerPage: 30,
  totalRecords: 0,
  startSlNo: 1,
  endSlNo: 30,
};

const getData = async (dealId, sellerId, isLooseDeal) => {
  const response = await DealService.getPosStockMaster(
    dealId,
    sellerId,
    {
      page: 1,
      count: 1000,
    },
    isLooseDeal
  );
  return { data: Array.isArray(response.resp) ? response.resp : [] };
};

const tblContainerStyle = {
  maxHeight: "calc(100vh - 200px)",
  overflow: "auto",
};

const LedgerMaster = ({ storeId, dealId, isLooseDeal }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showInStockOnly, setShowInStockOnly] = useState(true);

  const filterDataRef = useRef({
    storeId,
    dealId,
    sellerId: "",
    isLooseDeal,
    showInStockOnly: true,
  });
  const paginationRef = useRef(defaultPagination);
  const sortRef = useRef({ key: "stockMasterId", value: "desc" });
  const origDataRef = useRef([]);

  const loadList = async () => {
    setLoading(true);
    setData([]);
    const r = await getData(
      dealId,
      filterDataRef.current.sellerId,
      filterDataRef.current.isLooseDeal
    );
    origDataRef.current = r.data;
    setData(prepareData());
    setLoading(false);
  };

  const prepareData = () => {
    if (filterDataRef.current.showInStockOnly) {
      return origDataRef.current.filter((item) => item._quantity > 0);
    }
    return origDataRef.current;
  };

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPagination };
    loadList();
  }, []);

  useEffect(() => {
    filterDataRef.current = {
      ...filterDataRef.current,
      storeId,
      dealId,
      sellerId: "",
      isLooseDeal,
    };

    setLoading(true);

    const fetchFranchiseDetails = async () => {
      try {
        const response = await FranchiseService.getFranchise(
          filterDataRef.current.storeId
        );
        if (response && response.resp) {
          filterDataRef.current.sellerId = response.resp.sellerId;
          applyFilter();
        } else {
          setLoading(false);
          setData([]);
        }
      } catch (error) {
        console.error("Error fetching franchise details:", error);
        setLoading(false);
        setData([]);
      }
    };

    if (storeId && dealId) {
      fetchFranchiseDetails();
    }
  }, [applyFilter, storeId, dealId, isLooseDeal]);

  const sortCb = (data) => {
    sortRef.current = data;
    applyFilter();
  };

  const handleShowInStockOnly = () => {
    const newShowInStockOnly = !showInStockOnly;
    setShowInStockOnly(newShowInStockOnly);
    filterDataRef.current.showInStockOnly = newShowInStockOnly;
    applyFilter();
  };

  return (
    <>
      <div className="row mb-2">
        <div className="col text-sm">Showing {data.length} records</div>
        <div className="col-auto">
          <input
            type="checkbox"
            checked={showInStockOnly}
            onChange={handleShowInStockOnly}
          />
          <span className="fs-val-sm ms-2 align-middle">
            Show only In Stock
          </span>
        </div>
      </div>
      <div className="tbl-scroll-container" style={tblContainerStyle}>
        <table className="table table-striped table-sm table-hover">
          <TableHeader
            data={headers}
            sort={sortRef.current}
            sortCb={sortCb}
            noBg
            isSticky={true}
          />
          <tbody className="fs-val-md">
            {loading && <TableSkeletonLoader cols={headers.length} rows={15} />}

            {!loading && data.length === 0 && (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No data found</NoDataFound>
                </td>
              </tr>
            )}

            {data.map((item, index) => (
              <tr key={item._id}>
                <td>{paginationRef.current.startSlNo + index}</td>
                <td>
                  <div>{item._id}</div>
                  <div className="text-muted fs-val-sm">
                    Location: {item.location?.name || "--"} /{" "}
                    {item.location?.rackName || "--"} /{" "}
                    {item.location?.binName || "--"}
                  </div>
                </td>
                <td className="text-center">
                  <Amount value={item.mrp} decimalPlace={2} />
                </td>
                <td className="text-center">
                  <Amount value={item.purchasePrice} decimalPlace={2} />
                </td>
                <td className="text-center">
                  <div>
                    {item.expiry ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <DateFormatter
                          date={item.expiry}
                          format="dd MMM yyyy"
                        />
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">N/A</div>
                    )}
                    <div className="d-flex align-items-center mt-1 fs-val-sm justify-content-center">
                      {item.expiry && (
                        <>
                          <span className="text-danger">
                            {item._expired
                              ? "Expired"
                              : `Expires in ${item._shelfLifeRemaining}`}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </td>
                <td>{item.barcode || "N/A"}</td>
                <td className="text-center text-success">
                  {item._quantity}{" "}
                  {filterDataRef.current.isLooseDeal ? "kg" : ""}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default LedgerMaster;
