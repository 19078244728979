import { CoinsDashService, NavService, PosService } from "@sk/services"; // Add Nav import
import {
  Amount,
  DateFormatter,
  DateInput,
  HighlightText,
  NoDataFound,
  Pagination,
  PaginationSummary,
  SelectInput,
  TableHeader,
  TableSkeletonLoader,
  TextInput,
} from "@sk/uis";
import { endOfDay, startOfDay } from "date-fns";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";

const tableStyle = {
  minWidth: "1240px",
};

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 15,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 15,
};

const headers = [
  { label: "Sl No", key: "slNo", width: "40px" },
  { label: "Order ID", key: "OrderID", width: "90px", enableSort: true },
  {
    label: "Customer",
    key: "Customer",
    width: "120px",
    enableSort: true,
  },
  { label: "Store", key: "Store", width: "200px", enableSort: true },
  {
    label: "Order Date",
    key: "OrderDate",
    width: "113.75px",
    enableSort: true,
  },
  {
    label: "Order Value",
    key: "OrderValue",
    width: "80px",
    enableSort: true,
  },
  { label: "Status", key: "OrderStatus", width: "90px", enableSort: true },
  {
    label: "Order Type",
    key: "OrderType",
    width: "100px",
    enableSort: true,
  },
];

const statusOptions = PosService.getPosOrderStatusGroups().map((e) => ({
  label: e.name,
  value: e.key,
}));
statusOptions.unshift({ label: "All", value: "" });

const dateConfig = {
  maxDate: new Date(),
  mode: "range",
};

const getData = async (id, params) => {
  const r = await CoinsDashService.getClubOrders(params);
  return { data: Array.isArray(r.resp) ? r.resp : [] };
};

const getCount = async (id, params) => {
  const p = { ...params, outputType: "count" };

  delete p.page;
  delete p.count;

  const r = await CoinsDashService.getClubOrders(p);
  return {
    count: Array.isArray(r.resp) && r.resp.length > 0 ? r.resp[0].total : 0,
  };
};

const prepareFilterParams = (filter, pagination, sort) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      senderRefId: filter.cid,
    },
    orderFilter: {},
    projectFilter: {},
  };

  if (sort?.key) {
    p.sort = { [sort.key]: sort.value == "asc" ? 1 : -1 };
  }

  if (filter.search) {
    const s = filter.search.trim();
    p.projectFilter.$or = [
      { OrderID: s },
      { Customer: { $regex: s, $options: "gi" } },
      { Store: { $regex: s, $options: "gi" } },
    ];
  }

  if (Array.isArray(filter.dateRange) && filter.dateRange.length) {
    p.projectFilter["OrderDate"] = {
      $gte: startOfDay(new Date(filter.dateRange[0])).toISOString(),
      $lte: endOfDay(new Date(filter.dateRange[1])).toISOString(),
    };
  }

  if (filter.status) {
    const statusGroup = PosService.getPosOrderStatusGroups().find(
      (e) => e.key == filter.status
    );
    if (statusGroup) {
      p.projectFilter["Status"] = {
        $in: statusGroup.statusGroup,
      };
    }
  }

  if (!Object.keys(p.filter).length) {
    delete p.filter;
  }

  if (!Object.keys(p.orderFilter).length) {
    delete p.orderFilter;
  }

  return p;
};

const ClubOrders = ({ cid, callback, startDate }) => {
  const { register, getValues, control } = useForm();

  const [data, setData] = useState([]); // Added state for data
  const [loadingData, setLoadingData] = useState(true); // Added loading state
  const [records, setRecords] = useState({ loading: true, value: 0 }); // Added records state

  const filterDataRef = useRef({}); // Updated to use ref for filter data
  const paginationRef = useRef({ ...defaultPaginationConfig }); // Added pagination ref
  const sortRef = useRef({});

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };
    setLoadingData(true);

    paginationRef.current = {
      ...defaultPaginationConfig,
    };

    filterDataRef.current = {
      ...filterDataRef.current,
      ...getValues(), // Assuming getValues() includes search and status
    };

    loadList(); // Load the list

    const p = prepareFilterParams(
      filterDataRef.current,
      paginationRef.current,
      sortRef.current
    );

    const r = await getCount(filterDataRef.current.cid, p); // Get count
    setRecords({ loading: false, value: r.count }); // Update records state
    paginationRef.current.totalRecords = r.count; // Update pagination total records
  }, [getValues, loadList]);

  const loadList = useCallback(async () => {
    const p = prepareFilterParams(
      filterDataRef.current,
      paginationRef.current,
      sortRef.current
    );

    setLoadingData(true);
    setData([]);
    const r = await getData(filterDataRef.current.cid, p);
    setData(r.data);
    setLoadingData(false);
  }, []);

  useEffect(() => {
    if (cid) {
      filterDataRef.current.cid = cid;
      filterDataRef.current.startDate = startDate;
      applyFilter(); // Call applyFilter on mount
    }
  }, [applyFilter, cid, startDate]);

  const paginationCb = (data) => {
    paginationRef.current.startSlNo = data.startSlNo; // Update starting serial number
    paginationRef.current.endSlNo = data.endSlNo; // Update ending serial number
    paginationRef.current.activePage = data.activePage; // Update active page
    loadList(); // Load the list with updated pagination
  };

  const onSearch = useCallback(
    debounce(() => {
      applyFilter(); // Call applyFilter to fetch filtered data
    }, 800), // Debounce for 800 milliseconds
    []
  );

  const onStatusChange = () => {
    applyFilter();
  };

  const onDateChange = (onChange) => {
    return (value) => {
      onChange(value);
      applyFilter();
    };
  };

  const sortCb = (data) => {
    sortRef.current = data;
    applyFilter();
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col">
          <TextInput
            register={register}
            callback={onSearch} // Assuming onSearch is defined to handle input changes
            gap={0}
            label="Search by Order ID/Customer Name/Store Name"
            name="search" // Ensure this matches your form data
          />
        </div>
        <div className="col-4">
          <Controller
            name="dateRange"
            control={control}
            render={({ field }) => (
              <DateInput
                label="Date Range"
                callback={onDateChange(field.onChange)}
                config={dateConfig}
                gap={0}
                value={field.value}
                template={2}
              />
            )}
          />
        </div>
        <div className="col-auto">
          <SelectInput
            label="Order Status"
            options={statusOptions}
            register={register}
            name="status" // Ensure this matches your form data
            callback={onStatusChange} // Assuming onStatusChange is defined to handle dropdown changes
            gap={0}
          />
        </div>
      </div>
      <PaginationSummary
        paginationConfig={paginationRef.current}
        loadingTotalRecords={records.loading}
        fwSize="md"
        className="mb-2"
      />
      <div className="tbl-scroll-cnt custom-scrollbar mb-2">
        <table
          className="table bg-white table-hover table-sm table-striped"
          style={tableStyle}
        >
          <TableHeader
            data={headers}
            sortCb={sortCb}
            sort={sortRef.current}
            noBg={true}
          />
          <tbody className="fs-val-md">
            {loadingData ? (
              <TableSkeletonLoader
                cols={headers.length}
                rows={10}
                height={20}
              />
            ) : null}

            {!loadingData && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Data found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {data.map((e, index) => (
              <tr key={e.OrderID + ":" + index}>
                <td>{paginationRef.current.startSlNo + index}</td>
                <td>
                  <button
                    className="btn btn-link p-0 fs-val-md text-dark"
                    onClick={() =>
                      callback({ action: "viewOrder", id: e.OrderID })
                    }
                  >
                    {e.OrderID}
                  </button>
                </td>
                <td>
                  <button
                    className="btn btn-link p-0 fs-val-md text-dark"
                    onClick={() =>
                      callback({ action: "viewCustomer", id: e.customerId })
                    }
                  >
                    {e.Customer}
                  </button>
                </td>
                <td>
                  <button
                    className="btn btn-link p-0 fs-val-md text-dark"
                    onClick={() =>
                      NavService.openInNewTab("/pos/sales-analytics", {
                        fid: e.storeId,
                      })
                    }
                  >
                    {e.Store}
                  </button>
                  {" - "}
                  <button
                    className="btn btn-link p-0 fs-val-md text-dark"
                    onClick={() =>
                      NavService.openInNewTab("/pos/franchise", {
                        fid: e.storeId,
                      })
                    }
                  >
                    ({e.storeId})
                  </button>
                </td>
                <td>
                  <DateFormatter date={e.OrderDate} />
                </td>
                <td>
                  <Amount value={e.OrderValue} />
                </td>
                <td>
                  <HighlightText template={2} type={e.statusType}>
                    {e.displayStatus}
                  </HighlightText>
                </td>
                <td>{e.OrderType}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="text-end">
        <Pagination
          callback={paginationCb}
          activePage={paginationRef.current.activePage}
          rowsPerPage={paginationRef.current.rowsPerPage}
          totalRecords={paginationRef.current.totalRecords}
        />
      </div>
    </>
  );
};

export default ClubOrders;
