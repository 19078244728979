import { useState, useEffect } from "react";
import { NotificationService } from "@sk/services";
import { Alert, Spinner, Toaster } from "@sk/uis";

const CallPartnerAgentAssign = ({ empId }) => {
  const [agent, setAgent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const fetchAssignedAgent = async () => {
      if (!empId) {
        setIsFetching(false);
        return;
      }

      try {
        const response = await NotificationService.getAssignedAgentList({
          filter: {
            employeeId: empId,
            status: "Assigned",
          },
        });
        if (response.statusCode === 200 && response.resp?.length > 0) {
          const latestAssignment = response.resp[0]; // Assuming the latest assignment is first
          setAgent({
            id: latestAssignment.agentId,
            name: latestAssignment.agentName,
          });
        } else {
          setAgent(null);
        }
      } catch (error) {
        console.error("Error fetching assigned agent:", error);
        Toaster.error("Failed to fetch agent information");
        setAgent(null);
      } finally {
        setIsFetching(false);
      }
    };

    fetchAssignedAgent();
  }, [empId]);

  const onAssignAgent = async () => {
    if (!empId) {
      Toaster.error("Employee ID is required to assign an agent");
      return;
    }

    try {
      setIsLoading(true);
      const response = await NotificationService.assignAgent(empId);
      if (response.statusCode === 200) {
        setAgent({
          id: response.resp?.agentId,
          name: response.resp?.agentName,
        });
        Toaster.success("Support agent assigned successfully");
      } else {
        Toaster.error(
          response?.resp?.message || "Failed to assign support agent"
        );
      }
    } catch (error) {
      console.error("Error assigning agent:", error);
      Toaster.error("Something went wrong while assigning the agent");
    } finally {
      setIsLoading(false);
    }
  };

  const onRemoveAgent = async () => {
    const result = await Alert.confirm({
      title: "Remove Support Agent",
      text: "Are you sure you want to remove the assigned support agent?",
      icon: "warning",
      okText: "Yes, Remove",
      cancelText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        setIsLoading(true);
        const response = await NotificationService.removeAgent(empId);

        if (response.statusCode === 200) {
          setAgent(null);
          Toaster.success("Support agent removed successfully");
        } else {
          Toaster.error(
            response?.resp?.message || "Failed to remove support agent"
          );
        }
      } catch (error) {
        console.error("Error removing agent:", error);
        Toaster.error("Failed to remove support agent");
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (isFetching) {
    return (
      <div className="d-flex align-items-center justify-content-center p-4">
        <Spinner className="me-2" />
        <span className="text-muted">Loading agent information...</span>
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center flex-grow-1">
        <div className="text-muted me-3" style={{ minWidth: "120px" }}>
          Support Agent:
        </div>
        {agent ? (
          <div className="d-flex align-items-center bg-light rounded p-2 flex-grow-1">
            <div className="d-flex align-items-center w-100">
              <div
                className="rounded-circle bg-primary d-flex align-items-center justify-content-center me-2"
                style={{ width: "28px", height: "28px" }}
              >
                <i className="bi bi-headset text-white"></i>
              </div>
              <span className="fw-bold me-2">{agent.name}</span>
              <span className="badge bg-success me-2">Active</span>
              <small className="text-muted">Agent ID: {agent.id}</small>
            </div>
          </div>
        ) : (
          <div className="bg-light rounded p-2 flex-grow-1">
            <div className="d-flex align-items-center text-muted">
              <i className="bi bi-headset me-2"></i>
              <span>No support agent assigned to this employee</span>
            </div>
          </div>
        )}
      </div>

      <div className="ms-3">
        {agent ? (
          <button
            className="btn btn-outline-danger btn-sm"
            onClick={onRemoveAgent}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <Spinner size="sm" className="me-1" />
                Removing...
              </>
            ) : (
              <>
                <i className="bi bi-headset-x me-1"></i>
                Remove Agent
              </>
            )}
          </button>
        ) : (
          <button
            className="btn btn-primary btn-sm"
            onClick={onAssignAgent}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <Spinner size="sm" className="me-1" />
                Assigning...
              </>
            ) : (
              <>
                <i className="bi bi-headset-plus me-1"></i>
                Assign Support Agent
              </>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default CallPartnerAgentAssign;
