import { DealService, FranchiseService, PosService } from "@sk/services";
import {
  Amount,
  AppCard,
  DateFormatter,
  DisplayUnit,
  PageLoader,
} from "@sk/uis";
import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";

const canvasStyle = {
  width: "60%",
};

const StockLedgerDetailModal = ({
  show,
  callback,
  ledgerId,
  dealId,
  storeId,
}) => {
  const [deal, setDeal] = useState({});
  const [loading, setLoading] = useState(true);
  const [ledgerData, setLedgerData] = useState([]);

  const onClose = () => {
    callback({ action: "close" });
  };

  const init = async () => {
    setLoading(true);
    try {
      // Get deal details
      const r = await PosService.getDealsStock({
        filter: { _id: storeId },
        dealFilter: {
          _id: dealId,
        },
      });
      const d = Array.isArray(r.resp) ? r.resp[0] : r.resp;
      setDeal(d);

      // Get franchise details and ledger data
      if (d?._id) {
        const franchiseResponse = await FranchiseService.getFranchise(storeId);
        if (franchiseResponse?.resp?.sellerId) {
          const ledgerResponse = await DealService.getPosStockLedger(
            {
              filter: {
                status: "Success",
                "sellerInfo._id": franchiseResponse.resp.sellerId,
                _id: ledgerId,
              },
            },
            d.sellInLooseQty
          );

          setLedgerData(
            Array.isArray(ledgerResponse.resp) && ledgerResponse.resp.length
              ? ledgerResponse.resp[0]
              : ledgerResponse.resp
          );
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (show) {
      init();
    }
  }, [show, ledgerId, dealId]);

  return (
    <Offcanvas show={show} onHide={onClose} placement="end" style={canvasStyle}>
      <Offcanvas.Header closeButton className="bg-white">
        <Offcanvas.Title>
          <i className="bi bi-journal-text me-2"></i>
          Stock Ledger Detail
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modal-bg">
        {loading ? <PageLoader /> : null}

        {!loading && ledgerData ? (
          <>
            {/* Product Header Card */}
            <AppCard className="border-primary border-start border-4">
              <div className="mb-2">
                <span className="fs-val-lg fw-semibold">{deal.name}</span>
                <span className="badge bg-primary ms-2">{deal._id}</span>
              </div>
              <div className="fs-val-sm text-muted mb-3">
                <i className="bi bi-box-seam me-2"></i>
                {deal.categoryName} • {deal.brandName} • {deal.menuName}
              </div>
              <div className="row g-3">
                <div className="col-3">
                  <div className="border rounded p-2">
                    <div className="text-muted fs-val-sm mb-1">MRP</div>
                    <div className="fw-semibold">
                      <Amount value={deal.mrp} decimalPlace={2} />
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="border rounded p-2">
                    <div className="text-muted fs-val-sm mb-1">
                      Available Stock
                    </div>
                    <div className="fw-semibold">
                      <DisplayUnit
                        isLoose={deal.sellInLooseQty}
                        value={deal.qty}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="border rounded p-2">
                    <div className="text-muted fs-val-sm mb-1">
                      Selling Price
                    </div>
                    <div className="fw-semibold">
                      <Amount
                        value={deal._finalSellingPrice}
                        decimalPlace={2}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="border rounded p-2">
                    <div className="text-muted fs-val-sm mb-1">UOM</div>
                    <div className="fw-semibold">
                      {deal.sellInLooseQty ? "KG" : "Units"}
                    </div>
                  </div>
                </div>
              </div>
            </AppCard>

            {/* Basic Information */}
            <AppCard>
              <div className="d-flex align-items-center mb-3">
                <i className="bi bi-info-circle fs-5 me-2"></i>
                <span className="fs-val-md fw-semibold">Basic Information</span>
              </div>
              <div className="row g-3">
                <div className="col-4">
                  <div className="border rounded p-2">
                    <div className="text-muted fs-val-sm mb-1">Ledger ID</div>
                    <div className="text-break">{ledgerData._id}</div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="border rounded p-2">
                    <div className="text-muted fs-val-sm mb-1">
                      Stock Master ID
                    </div>
                    <div className="text-break">{ledgerData.stockMasterId}</div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="border rounded p-2">
                    <div className="text-muted fs-val-sm mb-1">Created On</div>
                    <div>
                      <DateFormatter
                        date={ledgerData.createdAt}
                        format="dd MMM yyyy, hh:mm a"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </AppCard>

            {/* Transaction Details */}
            <AppCard className="border-secondary border-start border-4">
              <div className="d-flex align-items-center mb-3">
                <i className="bi bi-receipt fs-5 me-2"></i>
                <span className="fs-val-md fw-semibold">
                  Transaction Details
                </span>
              </div>
              <div className="row g-3">
                <div className="col-6">
                  <div className="border rounded p-2">
                    <div className="text-muted fs-val-sm mb-1">
                      Reference ID
                    </div>
                    <div className="fw-semibold text-break">
                      {ledgerData.refId || "--"}
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="border rounded p-2">
                    <div className="text-muted fs-val-sm mb-1">
                      Reference Type
                    </div>
                    <div className="fw-semibold">{ledgerData._msg || "-"}</div>
                  </div>
                </div>
              </div>
            </AppCard>

            {/* Stock Movement */}
            <AppCard className="border-warning border-start border-4">
              <div className="d-flex align-items-center mb-3">
                <i className="bi bi-arrow-left-right fs-5 me-2"></i>
                <span className="fs-val-md fw-semibold">Stock Movement</span>
              </div>
              <div className="row g-3">
                <div className="col-3">
                  <div className="border rounded p-2">
                    <div className="text-muted fs-val-sm mb-1">
                      Opening Stock
                    </div>
                    <div className="fw-semibold">
                      {ledgerData._effectiveOldQty}{" "}
                      {ledgerData._effectiveOldQtyUom}
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="border rounded p-2">
                    <div className="text-muted fs-val-sm mb-1">Quantity</div>
                    <div
                      className={`fw-semibold ${
                        ledgerData.direction === "OUT"
                          ? "text-danger"
                          : "text-success"
                      }`}
                    >
                      {ledgerData.direction === "OUT" ? "" : "+"}{" "}
                      {ledgerData._quantity} {ledgerData._quantityUom}
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="border rounded p-2">
                    <div className="text-muted fs-val-sm mb-1">
                      Closing Stock
                    </div>
                    <div className="fw-semibold">
                      {ledgerData._effectiveNewQty}{" "}
                      {ledgerData._effectiveNewQtyUom}
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="border rounded p-2">
                    <div className="text-muted fs-val-sm mb-1">Direction</div>
                    <div
                      className={`badge ${
                        ledgerData.direction === "OUT"
                          ? "bg-danger"
                          : "bg-success"
                      }`}
                    >
                      {ledgerData.direction}
                    </div>
                  </div>
                </div>
              </div>
            </AppCard>

            {/* Location Details */}
            <AppCard className="border-info border-start border-4">
              <div className="d-flex align-items-center mb-3">
                <i className="bi bi-geo-alt fs-5 me-2"></i>
                <span className="fs-val-md fw-semibold">Location Details</span>
              </div>
              <div className="row g-3">
                <div className="col-4">
                  <div className="border rounded p-2">
                    <div className="text-muted fs-val-sm mb-1">Location</div>
                    <div className="fw-semibold">
                      {ledgerData.location?.name || "-"}
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="border rounded p-2">
                    <div className="text-muted fs-val-sm mb-1">Rack</div>
                    <div className="fw-semibold">
                      {ledgerData.location?.rackName || "-"}
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="border rounded p-2">
                    <div className="text-muted fs-val-sm mb-1">Bin</div>
                    <div className="fw-semibold">
                      {ledgerData.location?.binName || "-"}
                    </div>
                  </div>
                </div>
              </div>
            </AppCard>

            {/* Customer Information - Only if exists */}
            {ledgerData.customerInfo && (
              <AppCard className="border-success border-start border-4">
                <div className="d-flex align-items-center mb-3">
                  <i className="bi bi-person fs-5 me-2"></i>
                  <span className="fs-val-md fw-semibold">
                    Customer Details
                  </span>
                </div>
                <div className="row g-3">
                  <div className="col-6">
                    <div className="border rounded p-2">
                      <div className="text-muted fs-val-sm mb-1">Customer</div>
                      <div className="fw-semibold">
                        {ledgerData.customerInfo.isGuestCustomer
                          ? "Guest"
                          : ledgerData.customerInfo.name}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="border rounded p-2">
                      <div className="text-muted fs-val-sm mb-1">
                        Customer ID
                      </div>
                      <div className="fw-semibold">
                        {ledgerData.customerInfo.id}
                      </div>
                    </div>
                  </div>
                </div>
              </AppCard>
            )}
          </>
        ) : null}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default StockLedgerDetailModal;
