import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AppCard,
  DateInput,
  SelectInput,
  TextInput,
  PageLoader,
  DateFormatter,
  Toaster,
} from "@sk/uis";
import { CustomerService } from "@sk/services";

const canvasStyle = {
  width: "50%",
};

const schema = yup.object().shape({
  fName: yup.string().required("First name is required"),
  lName: yup.string(),
  age: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .positive("Age must be greater than 0")
    .integer("Age must be a whole number"),
  gender: yup.string().required().label("Gender"),
  dob: yup
    .array()
    .nullable()
    .transform((value) =>
      Array.isArray(value) ? value.map((v) => (isNaN(v) ? null : v)) : null
    ),
  email: yup.string().email("Invalid email"),
  maritalStatus: yup.string(),
  eductioanQualification: yup.string(),
  profession: yup.string(),
  familyMembers: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .positive("Number of family members must be greater than 0")
    .integer("Number of family members must be a whole number"),
  monthlyShoppingExpenses: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .positive("Monthly shopping expenses must be greater than 0"),
});

const genderOptions = [
  { value: "", label: "Select" },
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

const maritalStatusOptions = [
  { value: "", label: "Select" },
  { value: "Single", label: "Single" },
  { value: "Married", label: "Married" },
  { value: "Divorced", label: "Divorced" },
  { value: "Widowed", label: "Widowed" },
];

const qualificationOptions = [
  { value: "", label: "Select" },
  { value: "10th", label: "10th" },
  { value: "12th", label: "12th" },
  { value: "Undergraduate", label: "Undergraduate" },
  { value: "Graduate", label: "Graduate" },
  { value: "Post graduate", label: "Post graduate" },
];

const professionOptions = [
  { value: "", label: "Select" },
  { value: "SALARIED", label: "Salaried" },
  { value: "SELFEMPLOYED", label: "Self Employeed" },
  { value: "HOUSEWIFE", label: "HouseWife" },
  { value: "STUDENT", label: "Student" },
];

const CustomerUpdateModal = ({ show, customerId, callback }) => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [customerDetails, setCustomerDetails] = useState(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (show && customerId) {
      fetchCustomerDetails();
    }
  }, [show, customerId]);

  const fetchCustomerDetails = async () => {
    setLoading(true);
    try {
      const response = await CustomerService.getDetail(customerId);
      setCustomerDetails(response.resp);

      // Set form values with flattened structure
      setValue("fName", response.resp.fName);
      setValue("lName", response.resp.lName);
      setValue("age", response.resp.age);
      setValue("gender", response.resp.gender);
      setValue("dob", response.resp.dob ? [response.resp.dob] : []);
      setValue("email", response.resp.email);
      setValue("maritalStatus", response.resp.maritalStatus);
      setValue("eductioanQualification", response.resp.eductioanQualification);
      setValue("profession", response.resp.kycDetails?.profession);
      setValue(
        "familyMembers",
        response.resp.generalInformation?.numberOfFamilyMembers
      );
      setValue(
        "monthlyShoppingExpenses",
        response.resp.monthlyShoppingExpenses
      );
    } catch (error) {
      console.error("Error fetching customer details:", error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    setSubmitting(true);
    try {
      // Transform flat data to nested structure for API
      const transformedData = {
        ...data,
        dob: data.dob?.[0] || "",
        kycDetails: {
          ...customerDetails?.kycDetails,
          profession: data.profession,
        },
        generalInformation: {
          ...customerDetails?.generalInformation,
          numberOfFamilyMembers: data.familyMembers,
        },
      };

      if (!transformedData.dob) {
        delete transformedData.dob;
      }

      if (!data.monthlyShoppingExpenses) {
        delete transformedData.monthlyShoppingExpenses;
      }

      // Remove the flat fields that are now nested
      delete transformedData.profession;
      delete transformedData.familyMembers;

      const r = await CustomerService.updateCustomer(
        customerId,
        transformedData
      );

      if (r.statusCode == 200) {
        callback({ action: "close", updated: true });
      } else {
        Toaster.error("Failed to update");
      }
    } catch (error) {
      console.error("Error updating customer:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = () => {
    reset();
    callback({ action: "close" });
  };

  if (!show) return null;

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      style={canvasStyle}
      enforceFocus={false}
    >
      <Offcanvas.Header closeButton className="bg-white">
        <Offcanvas.Title>Update KYC Details</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modal-bg p-0">
        {loading ? (
          <div className="p-4">
            <PageLoader />
          </div>
        ) : (
          <>
            <div className="px-3 py-3">
              <AppCard>
                <div className="d-flex align-items-center gap-4">
                  <div
                    className="d-flex align-items-center justify-content-center rounded-circle bg-light overflow-hidden"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i className="bi bi-person fs-4"></i>
                  </div>
                  <div className="customer-info flex-grow-1">
                    <div className="mb-1 fs-val-normal fw-bold">
                      {customerDetails?.fName} {customerDetails?.lName}
                    </div>
                    <div className="d-flex gap-3 text-secondary fs-val-sm">
                      <div>
                        <span>ID: {customerId}</span>
                      </div>
                      <div>
                        <i className="bi bi-telephone me-1"></i>
                        <span>Mobile: {customerDetails?.mobile || "N/A"}</span>
                      </div>
                      <div>
                        <i className="bi bi-calendar3 me-1"></i>
                        <span>
                          Registered:{" "}
                          <DateFormatter
                            date={customerDetails?.createdAt}
                            format="dd MMM yyyy"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </AppCard>

              <form onSubmit={handleSubmit(onSubmit)}>
                <AppCard title="Update Information" className="mt-3">
                  <div className="row g-3">
                    <div className="col-6">
                      <TextInput
                        label="First Name"
                        name="fName"
                        register={register}
                        error={errors.fName?.message}
                        isMandatory
                      />
                    </div>
                    <div className="col-6">
                      <TextInput
                        label="Last Name"
                        name="lName"
                        register={register}
                        error={errors.lName?.message}
                      />
                    </div>
                    <div className="col-6">
                      <TextInput
                        label="Age"
                        name="age"
                        type="number"
                        register={register}
                        error={errors.age?.message}
                      />
                    </div>
                    <div className="col-6">
                      <SelectInput
                        label="Gender"
                        name="gender"
                        register={register}
                        error={errors.gender?.message}
                        options={genderOptions}
                        isMandatory={true}
                      />
                    </div>
                    <div className="col-6">
                      <Controller
                        name="dob"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <DateInput
                            label="Date of Birth"
                            name="dob"
                            error={errors.dob?.message}
                            value={value}
                            callback={onChange}
                          />
                        )}
                      />
                    </div>
                    <div className="col-6">
                      <TextInput
                        label="Email"
                        name="email"
                        register={register}
                        error={errors.email?.message}
                      />
                    </div>
                    <div className="col-6">
                      <SelectInput
                        label="Marital Status"
                        name="maritalStatus"
                        register={register}
                        error={errors.maritalStatus?.message}
                        options={maritalStatusOptions}
                      />
                    </div>
                    <div className="col-6">
                      <SelectInput
                        label="Educational Qualification"
                        name="eductioanQualification"
                        register={register}
                        error={errors.eductioanQualification?.message}
                        options={qualificationOptions}
                      />
                    </div>
                    <div className="col-6">
                      <SelectInput
                        label="Profession"
                        name="profession"
                        register={register}
                        error={errors.profession?.message}
                        options={professionOptions}
                      />
                    </div>
                    <div className="col-6">
                      <TextInput
                        label="Number of Family Members"
                        name="familyMembers"
                        type="number"
                        register={register}
                        error={errors.familyMembers?.message}
                      />
                    </div>
                    <div className="col-6">
                      <TextInput
                        label="Monthly Shopping Expenses (in Rs)"
                        name="monthlyShoppingExpenses"
                        type="number"
                        register={register}
                        error={errors.monthlyShoppingExpenses?.message}
                      />
                    </div>
                  </div>
                </AppCard>
              </form>
            </div>

            <div className="border-top bg-white p-3 d-flex justify-content-end position-sticky bottom-0 shadow-sm z-100">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={submitting}
                onClick={handleSubmit(onSubmit)}
              >
                {submitting ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-2" />
                    Updating...
                  </>
                ) : (
                  "Update Details"
                )}
              </button>
            </div>
          </>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default CustomerUpdateModal;
