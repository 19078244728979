import { WarehouseService } from "@sk/services";
import { Spinner } from "@sk/uis";
import { useEffect, useState } from "react";

const WarehouseStock = ({ whId, pid }) => {
  const [loading, setLoading] = useState(false);
  const [stockInfo, setStockInfo] = useState({});

  useEffect(() => {
    if (whId) {
      const loadWarehouseStock = async () => {
        setLoading(true);
        const res = await WarehouseService.getStockInfo(whId, pid);
        setStockInfo(res.resp || {});
        setLoading(false);
      };
      loadWarehouseStock();
    }
  }, [whId, pid]);

  if (loading) {
    return <Spinner isSmall={true} />;
  }

  return stockInfo.avaiableQty || 0;
};

export default WarehouseStock;
