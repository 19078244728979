import { BannerService } from "@sk/services";
import { SummaryCard } from "@sk/uis";
import { format, set } from "date-fns";
import { memo, useEffect, useState } from "react";

const BannerSummaryCard = ({ label, filterParams, color, img, status }) => {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);

  useEffect(() => {
    loadCount(filterParams);
  }, [filterParams]);

  const loadCount = async (filter) => {
    setLoading(true);
    let p = {
      filter,
      outputType: "count",
      groupBy: "totalBanners",
    };
    if (status == "Live") {
      p.subBannerStatus = "LIVE";
    }
    if (status == "Upcoming") {
      p.subBannerStatus = "UPCOMING";
    }
    if (status == "Expired") {
      p.subBannerStatus = "EXPIRED";
    }
    if (!status) {
      p.subBannerStatus = "ALL";
    }

    const r = await BannerService.getBannerList(p);
    let t = Array.isArray(r.resp) ? r.resp[0] : { total: 0 };
    setValue(t?.total || 0);
    setLoading(false);
  };

  return (
    <SummaryCard
      value={value}
      title={label}
      loading={loading}
      valueColor={color}
      img={img}
      template={2}
    />
  );
};

export default memo(BannerSummaryCard);
