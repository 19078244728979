import { DateFormatter } from "@sk/uis";
import { useEffect, useState } from "react";

const DataRefreshNote = ({ lastSyncDate, refreshTime }) => {
  const [isDelayed, setIsDelayed] = useState(false);

  useEffect(() => {
    const lastSync = new Date();
    lastSync.setMinutes(lastSync.getMinutes() - refreshTime);
    setIsDelayed(new Date(lastSyncDate) < lastSync);
  }, [refreshTime, lastSyncDate]);

  return (
    <>
      {isDelayed ? (
        <div className="text-danger fs-val-sm fw-semibold">
          <span className="fw-bold">Data Sync got delayed,</span> last sync was
          on <DateFormatter date={lastSyncDate} />
        </div>
      ) : (
        <div className="fs-val-sm d-flex align-items-center">
          <i className="bi bi-info-circle me-1"></i>
          Data as of{" "}
          <span className="fw-bold mx-1">
            <DateFormatter date={lastSyncDate} />
          </span>
          , Refreshed every {refreshTime} mins
        </div>
      )}
    </>
  );
};

export default DataRefreshNote;
