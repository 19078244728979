import { PosService } from "@sk/services";
import {
  Amount,
  AppCard,
  HighlightText,
  DateInput,
  NoDataFound,
  TableHeader,
  TableSkeletonLoader,
  PaginationBlock,
  PaginationSummary,
  DateFormatter,
  Spinner,
} from "@sk/uis";
import { useEffect, useState, useCallback, useRef } from "react";
import { startOfDay, endOfDay } from "date-fns";
import { useAttachAdditionalData } from "@sk/hooks";

const headerData = [
  { key: "slNo", label: "Sl No", width: "5%" },
  { key: "oldRsp", label: "Old RSP", width: "10%" },
  { key: "oldMrp", label: "Old MRP", width: "10%" },
  { key: "newRsp", label: "New RSP", width: "10%" },
  { key: "newMrp", label: "New MRP", width: "10%" },
  { key: "reason", label: "Reason", width: "20%" },
  { key: "updatedBy", label: "Updated On", width: "20%" },
];

const tdStyle = {
  newRsp: { backgroundColor: "#e7f3ff" },
};

const getData = async (params) => {
  const r = await PosService.getPosPriceChangeLog(params);
  return { data: Array.isArray(r.resp) ? r.resp : [] };
};

const getCount = async (params) => {
  let p = { ...params };
  delete p.page;
  delete p.count;
  const r = await PosService.getPosPriceChangeLogCount(p);
  return { count: r.resp || 0 };
};

const prepareFilterParams = (dealId, storeId, dateRange, pagination) => {
  let p = {
    page: pagination?.activePage,
    count: pagination?.rowsPerPage,
    filter: {
      dealId,
      franchiseId: storeId,
    },
  };

  if (dateRange?.length) {
    p.filter.createdAt = {
      $gte: startOfDay(dateRange[0]),
      $lte: endOfDay(dateRange[1]),
    };
  }

  return p;
};

const RspChangeLog = ({ dealId, storeId, hideDealInfo }) => {
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [details, setDetails] = useState(null);
  const [logs, setLogs] = useState([]);
  const [recordsCount, setRecordsCount] = useState({
    loading: false,
    count: 0,
  });

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const dateRangeRef = useRef([]);
  const paginationRef = useRef({
    totalRecords: 0,
    rowsPerPage: 50,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 50,
  });

  useEffect(() => {
    if (!hideDealInfo) {
      const fetchDealDetails = async () => {
        setLoading(true);
        const r = await PosService.getDealsStock({
          filter: {
            _id: storeId,
          },
          dealFilter: {
            _id: dealId,
          },
        });
        setDetails(Array.isArray(r.resp) ? r.resp[0] : {});
        setLoading(false);
      };

      fetchDealDetails();
    } else {
      setLoading(false);
      setDetails({});
    }
  }, [dealId, storeId, hideDealInfo]);

  const loadLogs = useCallback(async () => {
    setTableLoading(true);
    setLogs([]);
    const r = await getData(
      prepareFilterParams(
        dealId,
        storeId,
        dateRangeRef.current,
        paginationRef.current
      )
    );

    let tmp = [];
    setAdditionalData(
      r.data,
      [
        {
          key: "createdBy",
          api: "user",
          loadingKey: "userLoading",
          dataKey: "_user",
          filter: (ids) => ({ filter: { _id: { $in: ids } } }),
        },
      ],
      (updatedData) => {
        tmp.push(updatedData);
        if (tmp.length === 1) {
          setLogs([...attachAllData(r.data, tmp)]);
        }
      }
    );

    setTableLoading(false);
  }, [dealId, storeId, setAdditionalData, attachAllData]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = {
      totalRecords: 0,
      rowsPerPage: 50,
      activePage: 1,
      startSlNo: 1,
      endSlNo: 50,
    };

    loadLogs();

    setRecordsCount({ loading: true, count: 0 });
    const r = await getCount(
      prepareFilterParams(
        dealId,
        storeId,
        dateRangeRef.current,
        paginationRef.current
      )
    );
    paginationRef.current.totalRecords = r.count;
    setRecordsCount({ loading: false, count: r.count });
  }, [dealId, storeId, loadLogs]);

  useEffect(() => {
    applyFilter();
  }, [applyFilter]);

  const handleDateRangeChange = (value) => {
    dateRangeRef.current = value;
    applyFilter();
  };

  const onPaginationCb = (data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    applyFilter();
  };

  return (
    <>
      {loading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : null}

      {!loading && details?._id && !hideDealInfo ? (
        <>
          <AppCard>
            <div className="fs-val-lg fw-semibold mb-2">{details.name}</div>
            <div className="row fs-val-sm">
              <div className="col-auto text-muted">
                <HighlightText type="secondary" isSoft={true} template={2}>
                  ID<span className="px-1">:</span> {details._id}
                </HighlightText>
              </div>
              <div className="col-auto text-muted">
                <HighlightText type="secondary" isSoft={true} template={2}>
                  MRP <span className="px-1">:</span>{" "}
                  <Amount value={details.mrp} decimalPlace={2} />
                </HighlightText>
              </div>
              <div className="col-auto text-muted">
                <HighlightText type="secondary" template={2}>
                  Current RSP
                  <span className="px-1"> :</span>
                  <Amount value={details._finalSellingPrice} decimalPlace={2} />
                </HighlightText>
              </div>
            </div>
          </AppCard>
        </>
      ) : null}

      {!loading && (details?._id || hideDealInfo) ? (
        <AppCard>
          <div className="row mb-2">
            <div className="col align-self-center">
              <PaginationSummary
                loadingTotalRecords={recordsCount.loading}
                paginationConfig={paginationRef.current}
              />
            </div>
            <div className="col-4">
              <DateInput
                value={dateRangeRef.current}
                callback={handleDateRangeChange}
                template={2}
                size="sm"
                gap={0}
              />
            </div>
          </div>

          <table className="table table-sm">
            <TableHeader data={headerData} noBg />
            <tbody className="fs-val-sm">
              {tableLoading ? (
                <TableSkeletonLoader cols={headerData.length} rows={5} />
              ) : null}

              {!tableLoading && !logs.length ? (
                <tr>
                  <td colSpan={headerData.length}>
                    <NoDataFound>No logs found</NoDataFound>
                  </td>
                </tr>
              ) : null}

              {!tableLoading &&
                logs.map((log, index) => (
                  <tr key={index}>
                    <td>{paginationRef.current.startSlNo + index}</td>
                    <td>
                      <Amount value={log.oldData.price} decimalPlace={2} />
                    </td>
                    <td>
                      <Amount value={log.oldData.mrp} decimalPlace={2} />
                    </td>
                    <td style={tdStyle.newRsp}>
                      <Amount value={log.newData.price} decimalPlace={2} />
                    </td>
                    <td>
                      <Amount value={log.newData.mrp} decimalPlace={2} />
                    </td>
                    <td>{log._reason}</td>
                    <td>
                      <div>
                        <DateFormatter date={log.createdAt} />
                      </div>
                      <div className="fs-val-sm text-muted">
                        by {log._user?.name || log.updatedBy}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="d-flex justify-content-end mt-3">
            <PaginationBlock
              loadingTotalRecords={recordsCount.loading}
              paginationCb={onPaginationCb}
              size="sm"
              paginationConfig={paginationRef.current}
            />
          </div>
        </AppCard>
      ) : null}
    </>
  );
};

export default RspChangeLog;
