import { PosService } from "@sk/services";
import { Amount, NoDataFound, TableHeader, TableSkeletonLoader } from "@sk/uis";
import { endOfDay, startOfDay, subMonths } from "date-fns";
import { useCallback, useEffect, useState } from "react";

const headers = [
  { label: "Month", width: "25%" },
  { label: "Sales", width: "25%" },
  { label: "Coins Earned", width: "25%" },
  { label: "Coins Redeemed", width: "25%" },
];

const CustMontlySales = ({ cid }) => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const init = useCallback(async () => {
    setLoadingData(true);

    const now = new Date();
    const sixMonthsAgo = subMonths(now, 6);

    const params = {
      filter: {
        createdAt: {
          $gte: startOfDay(sixMonthsAgo), // 6 months ago at 00:00:00
          $lte: endOfDay(now),
        },
      },
    };

    const r = await PosService.getCustMonthlySales(cid, params);

    setData(r.data || []);
    setLoadingData(false);
  }, [cid]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader data={headers} />
        <tbody>
          {!loadingData && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Data found</NoDataFound>
              </td>
            </tr>
          ) : null}

          {loadingData ? (
            <TableSkeletonLoader cols={headers.length} rows={10} height={40} />
          ) : null}

          {data.map((x, index) => (
            <tr key={index} className="fs-val-md">
              <td>{x.month}</td>
              <td>
                <Amount value={x.sales || 0} decimalPlace={2} />
              </td>
              <td className="text-success">{x.coinsEarned || 0}</td>
              <td className="text-danger">{x.coinsRedeemed || 0}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default CustMontlySales;
