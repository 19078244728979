import { CustomerService, NavService } from "@sk/services";
import {
  DateFormatter,
  HighlightText,
  InfoPopover,
  NoDataFound,
  PaginationBlock,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAttachAdditionalData } from "@sk/hooks";

const tableColumns = [
  { label: "#", key: "slNo", enableSort: false, width: "5%" },
  { label: "Logged On", key: "createdAt", enableSort: true, width: "15%" },
  { label: "Content", key: "content", width: "35%" },
  { label: "Sent By", key: "createdBy", width: "15%", isCentered: true },
  {
    label: "Status",
    key: "status",
    enableSort: true,
    width: "15%",
    isCentered: true,
  },
];

const additionalDataConfig = [
  {
    key: "createdBy",
    api: "user",
    loadingKey: "createdByLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 15,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 15,
};

const getData = async (params) => {
  const r = await CustomerService.getCallLogs(params);
  const d = Array.isArray(r.resp) ? r.resp : [];
  return { data: d };
};

const getCount = async (params) => {
  let p = { ...params, outputType: "count" };
  delete p.page;
  delete p.count;

  const r = await CustomerService.getCallLogs(p);
  return { count: r.resp };
};

const prepareFilterParams = (filter, pagination) => {
  let p = {
    page: pagination?.activePage,
    count: pagination?.rowsPerPage,
    filter: {
      "customerInfo.id": filter.cid,
      type: filter.type,
    },
    sort: "-createdAt",
  };
  return p;
};

const getMessagePreview = (message, maxLength = 50) => {
  if (!message) return "";
  const strippedMessage = message.replace(/<[^>]*>/g, ""); // Remove HTML tags
  return strippedMessage.length > maxLength
    ? strippedMessage.substring(0, maxLength) + "..."
    : strippedMessage;
};

const CustMsgLogsTable = ({ customerId, type }) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const [totalCount, setTotalCount] = useState({
    loading: false,
    value: 0,
  });

  const filterRef = useRef({
    cid: customerId,
    type: type,
  });
  const sortRef = useRef({
    key: "createdAt",
    value: "desc",
  });
  const paginationRef = useRef({ ...defaultPaginationConfig });

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };
    loadList();

    setTotalCount({ loading: true, value: 0 });
    const p = await getCount(
      prepareFilterParams(filterRef.current, paginationRef.current)
    );
    setTotalCount({ loading: false, value: p.count });
  }, []);

  const loadList = useCallback(async () => {
    setLoading(true);
    setLogs([]);

    const p = await prepareFilterParams(
      filterRef.current,
      paginationRef.current
    );
    const r = await getData(p);
    const d = r.data;

    let tmp = [];
    setAdditionalData(d, additionalDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == additionalDataConfig.length) {
        setLogs([...attachAllData(d, tmp)]);
      }
    });

    setLogs(d);
    setLoading(false);
  }, [attachAllData, setAdditionalData]);

  useEffect(() => {
    filterRef.current.cid = customerId;
    filterRef.current.type = type;
    applyFilter();
  }, [customerId, type]);

  const paginationCb = (paginationConfig) => {
    paginationRef.current = {
      ...paginationRef.current,
      ...paginationConfig,
    };
    applyFilter();
  };

  const sortCb = (sort) => {
    sortRef.current = sort;
    applyFilter();
  };

  const viewTemplateCb = (templateId) => {
    NavService.openInNewTab("/utility/whatsapp-template/view", {
      id: templateId,
    });
  };

  return (
    <>
      <div className="fs-val-md mb-1 text-muted">Showing last 60 days logs</div>
      <div>
        <table className="table table-sm">
          <TableHeader
            data={tableColumns}
            sortCb={sortCb}
            sort={sortRef.current}
            noBg
          />
          <tbody className="fs-val-sm">
            {loading ? (
              <TableSkeletonLoader cols={tableColumns.length} rows={5} />
            ) : null}

            {!loading && logs.length == 0 ? (
              <tr>
                <td colSpan={tableColumns.length} className="text-center">
                  <NoDataFound>No logs found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {logs.map((log, index) => (
              <tr key={index}>
                <td>{paginationRef.current.startSlNo + index}</td>
                <td>
                  <div>
                    <DateFormatter date={log.createdAt} format="dd MMM yyyy" />
                  </div>
                  <div>
                    <DateFormatter date={log.createdAt} format="hh:mm a" />
                  </div>
                </td>
                <td>
                  <div className="fs-val-xs mb-1">
                    <span className="align-middle">ID: </span>
                    <button
                      className="btn btn-sm btn-link text-muted fs-val-xs p-0"
                      onClick={() =>
                        viewTemplateCb(log.notificationLog?.templateId)
                      }
                    >
                      {log.notificationLog?.templateId}
                    </button>
                  </div>
                  <div className="d-flex align-items-center fs-val-xs text-muted">
                    {getMessagePreview(log.notificationLog?.message)}
                    {log.notificationLog?.message &&
                      log.notificationLog.message.length > 50 && (
                        <InfoPopover
                          content={
                            <div
                              dangerouslySetInnerHTML={{
                                __html: log.notificationLog.message,
                              }}
                            ></div>
                          }
                          placement="right"
                        >
                          <span className="bi bi-three-dots ms-2"></span>
                        </InfoPopover>
                      )}
                  </div>
                </td>
                <td className="text-muted text-center">
                  {log._createdBy?.name || "--"}
                </td>
                <td className="text-center">
                  <HighlightText type={log._statusType} template={2}>
                    {log.status}
                  </HighlightText>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="text-end">
        <PaginationBlock
          paginationConfig={paginationRef.current}
          loadingTotalRecords={totalCount.loading}
          paginationCb={paginationCb}
          size="sm"
        />
      </div>
    </>
  );
};

export default CustMsgLogsTable;
