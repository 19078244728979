import {
  CommonService,
  DealService,
  FranchiseService,
  NavService,
} from "@sk/services";
import {
  Amount,
  BtnLink,
  BusyLoader,
  DateFormatter,
  InfoPopover,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
  Toaster,
} from "@sk/uis";
import { useCallback, useEffect, useRef, useState } from "react";
import LedgerListFilter from "./LedgerListFilter";
import { endOfDay, startOfDay } from "date-fns";

const headers = [
  { label: "Sl No", key: "slNo", width: "5%" },
  {
    label: "Date",
    key: "date",
    width: "15%",
  },
  { label: "ID", key: "id", width: "15%" },
  { label: "Type", key: "type", width: "23%" },
  { label: "MRP", key: "mrp", width: "8%", isCentered: true },
  {
    label: "Selling Price",
    key: "sellingPrice",
    width: "8%",
    isCentered: true,
  },
  {
    label: "Opening Stock",
    key: "openingStock",
    width: "10%",
    isCentered: true,
  },
  { label: "In/Out", key: "stockChange", width: "8%", isCentered: true },
  {
    label: "Closing Stock",
    key: "closingStock",
    width: "10%",
    isCentered: true,
  },
];

const defaultPagination = {
  activePage: 1,
  rowsPerPage: 30,
  totalRecords: 0,
  startSlNo: 1,
  endSlNo: 30,
};

const containerStyle = {
  maxHeight: "calc(100vh - 300px)",
  overflowY: "auto",
};

const getData = async (params, isLooseDeal) => {
  const response = await DealService.getPosStockLedger(params, isLooseDeal);
  return { data: Array.isArray(response.resp) ? response.resp : [] };
};

const getCount = async (params, isLooseDeal) => {
  const p = { ...params };
  delete p.page;
  delete p.count;

  const response = await DealService.getPosStockLedgerCount(p, isLooseDeal);
  return {
    count: response.resp || 0,
  };
};

const prepareFilterParams = (filter, pagination, sort) => {
  const p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      status: "Success",
      "sellerInfo._id": filter.sellerId,
      dealId: filter.dealId,
    },
  };

  // Handle search
  if (filter.search?.trim()) {
    const regEx = {
      $regex: filter.search,
      $options: "gi",
    };
    p.filter.$or = [
      { refId: regEx },
      { "customerInfo.id": regEx },
      { "customerInfo.mobile": regEx },
      { "customerInfo.name": regEx },
    ];
  }

  // Handle date range
  if (filter.dateRange?.[0] && filter.dateRange?.[1]) {
    p.filter.createdAt = {
      $gte: startOfDay(new Date(filter.dateRange[0])),
      $lte: endOfDay(new Date(filter.dateRange[1])),
    };
  }

  // Handle transaction type
  if (filter.transactionType && filter.transactionType !== "all") {
    p.filter.referenceType = filter.transactionType;
  }

  // Handle credit/debit type
  if (filter.type && filter.type !== "all") {
    p.filter.direction = filter.type === "credit" ? "IN" : "OUT";
  }

  // Handle sorting
  if (sort.key) {
    p.sort = {
      [sort.key]: sort.value === "asc" ? 1 : -1,
    };
  }

  return p;
};

const LedgerList = ({ storeId, dealId, callback, isLooseDeal }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingCount, setLoadingCount] = useState(true);
  const [transactionDesc, setTransactionDesc] = useState("");
  const [busyLoader, setBusyLoader] = useState({ show: false });

  const filterDataRef = useRef({ storeId, dealId });
  const paginationRef = useRef(defaultPagination);
  const sortRef = useRef({ key: "date", value: "desc" });

  const loadList = async () => {
    setLoading(true);
    setData([]);
    const r = await getData(
      prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortRef.current
      ),
      filterDataRef.current.isLooseDeal
    );
    setData(r.data);
    setLoading(false);
  };

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPagination };
    loadList();

    setLoadingCount(true);
    const r = await getCount(
      prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortRef.current
      ),
      filterDataRef.current.isLooseDeal
    );
    paginationRef.current.totalRecords = r.count;
    setLoadingCount(false);
  }, []);

  useEffect(() => {
    filterDataRef.current = { storeId, dealId, sellerId: "", isLooseDeal };

    setLoading(true);

    const fetchFranchiseDetails = async () => {
      try {
        const response = await FranchiseService.getFranchise(
          filterDataRef.current.storeId
        );
        if (response && response.resp) {
          filterDataRef.current.sellerId = response.resp.sellerId;
          applyFilter();
        } else {
          setLoading(false);
          setData([]);
        }
      } catch (error) {
        console.error("Error fetching franchise details:", error);
        setLoading(false);
        setData([]);
      }
    };

    if (storeId && dealId) {
      fetchFranchiseDetails();
    }
  }, [applyFilter, storeId, dealId, isLooseDeal]);

  const paginationCb = (data) => {
    paginationRef.current.activePage = data.activePage;
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    loadList();
  };

  const sortCb = (data) => {
    sortRef.current = data;
    applyFilter();
  };

  const filterCb = (data) => {
    if (data.action === "download") {
      downloadLedger();
      return;
    }

    filterDataRef.current = {
      ...filterDataRef.current,
      ...data.formData,
    };
    setTransactionDesc(data.formData.tranTypeDesc || "");
    applyFilter();
  };

  const handleViewClick = (ledger) => {
    callback?.({
      action: "viewLedgerDetail",
      ledgerId: ledger._id,
      dealId,
    });
  };

  const downloadLedger = async () => {
    setBusyLoader({ show: true });
    try {
      const params = prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortRef.current
      );

      const response = await DealService.downloadDealStockLedger({
        sellerId: filterDataRef.current.sellerId,
        dealId,
        filter: params.filter || {},
      });

      if (response?.resp?.downloadLink) {
        CommonService.downloadAsset(response.resp.downloadLink);
      } else {
        Toaster.error("Failed to download, please try again");
      }
    } catch (error) {
      console.error("Download error:", error);
      Toaster.error("Failed to download, please try again");
    } finally {
      setBusyLoader({ show: false });
    }
  };

  return (
    <>
      <LedgerListFilter callback={filterCb} />

      <div className="d-flex align-items-center mb-2 fs-val-md text-muted">
        <PaginationSummary
          loadingTotalRecords={loadingCount}
          paginationConfig={paginationRef.current}
          className="me-3"
          fwSize="md"
        />
        {transactionDesc && (
          <div>
            <i className="bi bi-info-circle me-2"></i>
            <span>{transactionDesc}</span>
          </div>
        )}
      </div>

      <div
        style={containerStyle}
        className="tbl-scroll-container custom-scrollbar thin-scrollbar mb-3 fixed-width-table border rounded"
      >
        <table className="table table-striped table-sm table-hover">
          <TableHeader
            data={headers}
            sort={sortRef.current}
            sortCb={sortCb}
            isSticky={true}
          />
          <tbody className="fs-val-md">
            {loading && <TableSkeletonLoader cols={headers.length} rows={15} />}

            {!loading && data.length === 0 && (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No data found</NoDataFound>
                </td>
              </tr>
            )}

            {data.map((item, index) => (
              <tr
                key={item._id}
                onClick={() => handleViewClick(item)}
                className="cursor-pointer"
              >
                <td>{paginationRef.current.startSlNo + index}</td>
                <td>
                  <DateFormatter date={item.createdAt} format={"dd MMM yyyy"} />
                  <div className="ms-1 fs-val-sm d-inline-block text-black-50">
                    <DateFormatter date={item.createdAt} format={"hh:mm a"} />
                  </div>
                </td>
                <td>
                  <div className="d-inline-flex">
                    {(item.lbls || []).map((x, k) =>
                      x.redirect?.path ? (
                        <BtnLink
                          key={k}
                          className="fs-val-md inline-block"
                          onClick={(e) => {
                            e.stopPropagation();
                            NavService.openInNewTab(
                              x.redirect.path,
                              x.redirect.params
                            );
                          }}
                        >
                          {x.val}
                        </BtnLink>
                      ) : (
                        <span key={k}>{x.val}</span>
                      )
                    )}
                    {item.lbls?.length > 0 ? (
                      <InfoPopover
                        header="Actual Reference ID"
                        content={
                          <div>
                            <div className="fs-val-xs text-muted">
                              Reference ID in the Stock Snapshot
                            </div>
                            <div className="fs-val-sm">{item.refId}</div>
                          </div>
                        }
                      />
                    ) : null}
                  </div>
                </td>
                <td>{item._msg}</td>
                <td className="text-center">
                  <Amount value={item.mrp} />
                </td>
                <td className="text-center">
                  {item.isFree ? (
                    "FREE"
                  ) : (
                    <Amount value={item.sellingPrice || 0} decimalPlace={2} />
                  )}
                </td>
                <td className="text-center">
                  {item._effectiveOldQty || 0} {item._effectiveOldQtyUom}
                </td>
                <td
                  className={`text-center ${
                    item.direction === "OUT" ? "text-danger" : "text-success"
                  }`}
                >
                  {item.direction === "OUT" ? "" : "+"} {item._quantity}{" "}
                  {item._quantityUom}
                </td>
                <td className="text-center">
                  {item._effectiveNewQty || 0} {item._effectiveNewQtyUom}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="text-end">
        <PaginationBlock
          loadingTotalRecords={loadingCount}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          size="sm"
        />
      </div>

      <BusyLoader show={busyLoader.show} />
    </>
  );
};

export default LedgerList;
