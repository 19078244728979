/* eslint-disable jsx-a11y/interactive-supports-focus */
import { Amount, HighlightText } from "@sk/uis";
import classNames from "classnames";
import { memo } from "react";
import styles from "./PoItemCard.module.scss";
import { CommonService } from "@sk/services";

const damageOptions = [
  { label: "Please select reason", value: "" },
  { label: "Courier Damage", value: "Courier Damage" },
  { label: "Vendor Damage", value: "Vendor Damage" },
  { label: "Warehouse Damage", value: "Warehouse Damage" },
];

const PoItemCard = ({
  index,
  data = {},
  onSchemeMarginChange,
  onQtyChange,
  onQtyTypeChange,
  mode = "edit",
  onRemove,
  feature = "SK-PO",
  vendorPriceCb,
  approveCb,
  rejectCb,
  productMrpCb,
  damageCb,
}) => {
  return (
    <table
      className={classNames(
        "table table-bordered mb-4 table-centered bg-white",
        styles["products-table"]
      )}
    >
      <tbody>
        <tr>
          <td width="1.5%" rowSpan={6}>
            {index + 1}
          </td>
          <td className="position-relative" width="33%" rowSpan={3}>
            {/* <div className={classNames(styles["ribbon"], "fw-bold text-light")}>
              Slow Moving
            </div> */}
            <div
              className={classNames(
                "fw-semibold text-primary mb-4 mt-3",
                styles["p-name"]
              )}
            >
              {data?.name} ({data?._id})
            </div>
            <div className="mb-3">
              <span className={classNames("fw-semibold me-2", styles["lbl"])}>
                Brand/Category:
              </span>
              <span className={classNames(styles["val"], "text-primary")}>
                {data.brand?.name} / {data.category?.name}
              </span>
            </div>
            <div className="row">
              <div className="col-5">
                <span className={classNames("me-2 text-muted", styles["lbl"])}>
                  Barcode:
                </span>
                <span className={classNames("fw-semibold", styles["val"])}>
                  {data.barcode || "N/A"}
                </span>
              </div>
              <div className="col-3">
                <span className={classNames("me-2 text-muted", styles["lbl"])}>
                  HSN:
                </span>
                <span className={classNames("fw-semibold", styles["val"])}>
                  {data?.hsnNumber || "N/A"}
                </span>
              </div>
              <div className="col-4 text-end">
                <span className={classNames("me-2 text-muted", styles["lbl"])}>
                  Tax:
                </span>
                <span className={classNames("fw-semibold", styles["val"])}>
                  {data?.tax || 0}%
                </span>
              </div>
            </div>
          </td>
          <td width="40%">
            <div className="row">
              <div className="col-12">
                <div className={classNames("fw-semibold mb-1", styles["hdr"])}>
                  Price Details
                </div>
              </div>
              <div className="col-auto">
                <div className="me-3">
                  <span className={classNames(styles["lbl"], "me-2")}>
                    MRP:
                  </span>
                  <span
                    className={classNames(
                      styles["val"],
                      "text-danger fw-semibold pe-1"
                    )}
                  >
                    <Amount value={data?.mrp} decimalPlace="2" />
                  </span>
                  {mode == "edit" && feature != "POS-PO" ? (
                    <span
                      className={classNames(
                        "text-primary cursor-pointer bi",
                        "bi-plus-circle-fill",
                        styles.lbl
                      )}
                      role={"button"}
                      onClick={() => productMrpCb(index)}
                    ></span>
                  ) : null}
                  {mode == "view" && data?.resp?.mrpList?.length > 1 && (
                    <span
                      className={classNames(
                        "text-primary cursor-pointer bi",
                        "bi-eye",
                        styles.lbl
                      )}
                      role={"button"}
                      onClick={() => productMrpCb(index)}
                    ></span>
                  )}
                </div>
              </div>
              <div className="col-auto">
                <div className="me-3">
                  <span className={classNames(styles["lbl"], "me-2")}>
                    {feature == "POS-PO" ? "Selling Price :" : "B2B Price :"}
                  </span>
                  <span
                    className={classNames(
                      styles["val"],
                      "text-warning fw-primary"
                    )}
                  >
                    <Amount
                      value={
                        feature == "POS-PO" ? data.sellingPrice : data?.b2bPrice
                      }
                      decimalPlace={2}
                    />
                  </span>
                </div>
              </div>
              <div className="col-auto">
                <div className="me-3">
                  <span className={classNames(styles["lbl"], "me-2")}>
                    Purchase Price:
                  </span>
                  <span
                    className={classNames(
                      styles["val"],
                      "text-dark fw-semibold"
                    )}
                  >
                    <Amount value={data?.purchasePrice} decimalPlace={2} />
                  </span>
                </div>
              </div>
              {feature != "POS-PO" ? (
                <div className="col-auto">
                  <div className="me-3">
                    <span className={classNames(styles["lbl"], "me-2")}>
                      CM 1:
                    </span>
                    <span
                      className={classNames(
                        styles["val"],
                        "text-success fw-semibold"
                      )}
                    >
                      {data?.cm1}%
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          </td>
          <td rowSpan={5} className="bg-white">
            <div
              className={classNames(styles["lbl"], "fw-semibold text-center")}
            >
              Total Value
            </div>
            <div
              className={classNames(
                styles["total-val"],
                "fw-semibold text-warning text-center"
              )}
            >
              <Amount value={data?.totalValue} decimalPlace={2} />
            </div>
          </td>
          {mode == "edit" && feature == "SK-PO" && (
            <td width="10%" className="text-center bg-white" rowSpan={5}>
              <button
                className={classNames("btn btn-link text-danger", styles.lbl)}
                onClick={() => onRemove(index)}
              >
                <span className="bi bi-trash"></span>
                Remove
              </button>
            </td>
          )}

          {mode == "view" && (
            <td width="10%" className="text-center bg-white" rowSpan={5}>
              <HighlightText status={data.status} />
            </td>
          )}
        </tr>
        <tr>
          <td width="40%" className="bg-white">
            <div className="row align-items-center">
              <div className="col-12">
                <div className={classNames("fw-semibold mb-1", styles["hdr"])}>
                  Margins
                </div>
              </div>
              <div className="col-auto">
                <div className={classNames(styles["lbl"])}>Base Margin</div>
              </div>
              <div className="col-auto">
                <div
                  className={classNames("py-1 px-2", styles["b-margin-box"])}
                >
                  {data?.baseMargin}%
                </div>
              </div>
              <div className="col-auto">
                <div className={classNames(styles.lbl, "fw-bold")}>
                  <span className="bi bi-plus-lg"></span>
                </div>
              </div>
              <div className="col-auto">
                <div className={classNames(styles.lbl)}>Additional Margin</div>
              </div>
              <div className="col-auto">
                {mode == "edit" && (
                  <input
                    type="number"
                    min="0"
                    className={classNames(styles["margin-inp"])}
                    value={data?.additionalMargin}
                    onChange={(ev) =>
                      onSchemeMarginChange(ev.target.value, index)
                    }
                    disabled={
                      feature == "POS-PO" &&
                      (data.status == "Approved" || data.status == "Rejected")
                    }
                  />
                )}
                {mode == "view" && (
                  <span
                    className={classNames("py-1 px-2", styles["b-margin-box"])}
                  >
                    {CommonService.roundedByDecimalPlace(
                      data?.additionalMargin,
                      3
                    )}
                    %
                  </span>
                )}
              </div>
              <div className="col-auto">
                <div className={classNames(styles.lbl)}>=</div>
              </div>
              <div className="col-auto">
                <div className={classNames("fw-semibold", styles.lbl)}>
                  <span className="me-2">Final Margin:</span>
                  <span className="text-success">{data?.finalMargin}%</span>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td width="40%" rowSpan={3}>
            <div className="mb-4 row align-items-center">
              <div className="col-6">
                <div className={classNames("fw-semibold mb-1", styles["hdr"])}>
                  Purchase Qty
                </div>
              </div>
              <div className="col-6">
                {feature != "POS-PO" && (
                  <>
                    <span className={classNames("me-2 mb-1", styles["lbl"])}>
                      Previous Purchase Price:
                    </span>

                    <span
                      className={classNames(
                        "text-primary fw-semibold",
                        styles["lbl"]
                      )}
                    >
                      <Amount value={data?.previousPurchasePrice || 0} />
                      <i
                        className="bi bi-eye"
                        role="button"
                        onClick={() => vendorPriceCb(index)}
                      ></i>
                    </span>
                  </>
                )}
              </div>
              {feature != "POS-PO" && (
                <div className={classNames("col-auto", styles["case-qty-blk"])}>
                  <div className="pe-3">
                    <div className="mb-2">
                      <span className={classNames("me-2", styles["lbl"])}>
                        Case Qty:
                      </span>
                      <span
                        className={classNames(
                          "text-primary fw-semibold",
                          styles["lbl"]
                        )}
                      >
                        {data?.caseQty || 0}
                      </span>
                    </div>
                    <div>
                      <span className={classNames("me-2", styles["lbl"])}>
                        InnerCase Qty:
                      </span>
                      <span
                        className={classNames(
                          "text-primary fw-semibold",
                          styles["lbl"]
                        )}
                      >
                        {data?.innerPackQty || 0}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {mode == "edit" && (
                <>
                  {feature != "POS-PO" && (
                    <div className="col-auto">
                      <div className="ps-3">
                        <div className={classNames(styles["lbl"])}>
                          Purchase qty in
                        </div>
                        <select
                          className={styles["qty-select"]}
                          onChange={(ev) =>
                            onQtyTypeChange(ev.target.value, index)
                          }
                        >
                          <option value="units">in Unit</option>
                          <option value="case">in Case</option>
                          <option value="innercase">in Inner-case</option>
                        </select>
                      </div>
                    </div>
                  )}
                  <div className="col-auto">
                    <>
                      <div className={classNames(styles["lbl"])}>
                        Enter no. of Qty
                      </div>
                      <input
                        type="number"
                        value={data.qty}
                        className={classNames(styles["purchase-qty-inp"])}
                        onChange={(ev) => onQtyChange(ev.target.value, index)}
                        disabled={
                          feature == "POS-PO" &&
                          (data.status == "Approved" ||
                            data.status == "Rejected")
                        }
                      />
                    </>
                  </div>
                  <div className="col-auto">
                    <div className={classNames(styles.lbl, "mt-3")}>=</div>
                  </div>
                  <div className="col-auto align-self-center">
                    <div className={classNames("fw-semibold mt-3", styles.lbl)}>
                      <span className="me-2">Qty:</span>
                      <span className="text-success">
                        {data.qty || 0} {data?._displayUnitType}
                      </span>
                    </div>
                  </div>
                </>
              )}
              {mode == "view" && (
                <>
                  <div className="fs-val-md col-auto">
                    Requested Qty :
                    <span
                      className={classNames(
                        "py-1 px-2",
                        styles["b-margin-box"]
                      )}
                    >
                      {data.qty} {data?._displayUnitType}
                    </span>
                  </div>

                  {/* {feature == "POS-PO" && data?.receivedQuantity ? (
                    <div className="fs-val-md col-auto">
                      Received Qty :
                      <span
                        className={classNames(
                          "py-1 px-2",
                          styles["b-margin-box"]
                        )}
                      >
                        {data?.receivedQuantity}
                      </span>
                    </div>
                  ) : null} */}
                </>
              )}
            </div>
            {/* damaged  Reason */}
            {/* {feature == "POS-PO" && (
              <div className="row">
                <div className="col-3 mb-3">
                  <input
                    disabled={data.inputDisabled}
                    type="checkbox"
                    checked={data.isDamaged}
                    onChange={(e) =>
                      damageCb({
                        key: "isDamaged",
                        value: e.target.checked,
                        index,
                      })
                    }
                  />
                  <span className="fs-val-sm"> is Damaged ?</span>
                </div>

                If damaged is true then only we will will capture qty and reason 
                {data.isDamaged ? (
                  <>
                    <div className="col-auto">
                      <div className="mb-3">
                        <div className="fs-val-sm">Damaged Reason</div>
                        <select
                          className=" form-select form-select-sm"
                          value={data.damagedReason}
                          onChange={(e) =>
                            damageCb({
                              key: "damagedReason",
                              value: e.target.value,
                              index,
                            })
                          }
                        >
                          {damageOptions.map((x) => (
                            <option value={x.value} key={x.label}>
                              {x.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="mb-3">
                        <div className="fs-val-sm">Damaged Qty</div>
                        <input
                          disabled={data.inputDisabled}
                          className="form-control form-control-sm"
                          type="number"
                          value={data.damagedQty}
                          onChange={(e) =>
                            damageCb({
                              key: "damagedQty",
                              value: e.target.value,
                              index,
                            })
                          }
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            )} */}

            {/* Stock Details */}
            {(mode == "edit" || feature == "POS-PO") && (
              <div className="row">
                <div className="col-auto">
                  <div className="me-3">
                    <span className={classNames(styles["lbl"], "me-2")}>
                      Current Stock:
                    </span>
                    <span
                      className={classNames(
                        styles["val"],
                        "text-success fw-semibold"
                      )}
                    >
                      {data?.currentStock || 0} {data?._displayUnitType}
                    </span>
                  </div>
                </div>
                {feature != "POS-PO" && (
                  <div className="col-auto">
                    <div className="me-3">
                      <span className={classNames(styles["lbl"], "me-2")}>
                        Pending Order:
                      </span>
                      <span
                        className={classNames(
                          styles["val"],
                          "text-danger fw-primary"
                        )}
                      >
                        {data?.pendingStock || 0}
                      </span>
                    </div>
                  </div>
                )}
                <div className="col-auto">
                  <div className="me-3">
                    <span className={classNames(styles["lbl"], "me-2")}>
                      Open PO:
                    </span>
                    <span
                      className={classNames(
                        styles["val"],
                        "text-danger fw-semibold"
                      )}
                    >
                      {data?.openPo || 0}
                    </span>
                  </div>
                </div>
                {feature != "POS-PO" && (
                  <div className="col-auto">
                    <div className="me-3">
                      <span className={classNames(styles["lbl"], "me-2")}>
                        Suggested:
                      </span>
                      <span
                        className={classNames(
                          styles["val"],
                          "text-dark fw-semibold"
                        )}
                      >
                        {data?.suggestedQuantity || 0}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </td>
        </tr>
        {/* Product Sold and Product Purchase */}
        {(mode == "edit" || feature == "POS-PO") && (
          <>
            <tr>
              <td width="33%" className="bg-white">
                <div className="row">
                  <div className="col-12">
                    <div
                      className={classNames("fw-semibold mb-1", styles["lbl"])}
                    >
                      Product Purchased in:
                    </div>
                  </div>
                  <div className="col-4">
                    <span
                      className={classNames("me-2 text-muted", styles["lbl"])}
                    >
                      15 Days:
                    </span>
                    <span className={classNames("fw-semibold", styles["val"])}>
                      {data?.purchase?.lastFifteenDays || 0}
                      {data._displayUnitType}
                    </span>
                  </div>
                  <div className="col-4">
                    <span
                      className={classNames("me-2 text-muted", styles["lbl"])}
                    >
                      30 Days:
                    </span>
                    <span className={classNames("fw-semibold", styles["val"])}>
                      {data?.purchase?.lastThirtyDays || 0}
                      {data._displayUnitType}
                    </span>
                  </div>
                  <div className="col-4 text-end">
                    <span
                      className={classNames("me-2 text-muted", styles["lbl"])}
                    >
                      60 Days:
                    </span>
                    <span className={classNames("fw-semibold", styles["val"])}>
                      {data?.purchase?.lastSixtyDays || 0}
                      {data._displayUnitType}
                    </span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td width="33%" className="bg-white">
                <div className="row">
                  <div className="col-12">
                    <div
                      className={classNames("fw-semibold mb-1", styles["lbl"])}
                    >
                      Product Sold in:
                    </div>
                  </div>
                  <div className="col-4">
                    <span
                      className={classNames("me-2 text-muted", styles["lbl"])}
                    >
                      30 Days:
                    </span>
                    <span className={classNames("fw-semibold", styles["val"])}>
                      {data?.sales?.lastThirtyDays || 0} {data._displayUnitType}
                    </span>
                  </div>
                  <div className="col-4">
                    <span
                      className={classNames("me-2 text-muted", styles["lbl"])}
                    >
                      60 Days:
                    </span>
                    <span className={classNames("fw-semibold", styles["val"])}>
                      {data?.sales?.lastSixtyDays || 0} {data._displayUnitType}
                    </span>
                  </div>
                  <div className="col-4 text-end">
                    <span
                      className={classNames("me-2 text-muted", styles["lbl"])}
                    >
                      90 Days:
                    </span>
                    <span className={classNames("fw-semibold", styles["val"])}>
                      {data?.sales?.lastNinetyDays || 0} {data._displayUnitType}
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </>
        )}

        {/* POS PO ACTION BUTTONS START */}
        {feature == "POS-PO" && mode == "edit" ? (
          <tr>
            <td className="bg-white text-end" colSpan={5}>
              {data.status == "Pending" ? (
                <>
                  <button
                    className="btn  btn-outline-success me-2"
                    onClick={() => approveCb(index)}
                  >
                    Approve
                  </button>
                  <button
                    className="btn  btn-outline-danger"
                    onClick={() => rejectCb(index)}
                  >
                    Reject
                  </button>
                </>
              ) : null}
              {data.status == "Approved" ? (
                <div className="text-success">Approved</div>
              ) : null}
              {data.status == "Rejected" ? (
                <div className="text-danger">Rejected</div>
              ) : null}
            </td>
          </tr>
        ) : null}
        {/* POS PO ACTION BUTTONS END */}

        {/* Reject Remarks */}
        {data.rejectedRemarks ? (
          <tr>
            <td colSpan={5} className="fs-val-md text-danger">
              <span className="fw-semibold">Rejected Remarks : </span>
              <span>"{data.rejectedRemarks}"</span>
            </td>
          </tr>
        ) : null}
      </tbody>
    </table>
  );
};

export default memo(PoItemCard);
