import { NavService, OmsService, PosService } from "@sk/services";
import {
  Amount,
  DateFormatter,
  ImgRender,
  TableHeader,
  Toaster,
} from "@sk/uis";
import { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";

const AddToPurchaseBasketModal = ({
  show,
  dealId,
  storeId,
  callback,
  editId = null,
  useDealInfo = null, // New prop
  hideStockSalesData = false, // New prop
}) => {
  const [dealDetails, setDealDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [error, setError] = useState("");
  const [previousSuggestion, setPreviousSuggestion] = useState(null);
  const [openOrders, setOpenOrders] = useState([]);

  useEffect(() => {
    const fetchDealDetails = async () => {
      if (show && dealId && storeId && !useDealInfo) {
        try {
          setLoading(true);
          const response = await PosService.getDealsStock({
            page: 1,
            count: 1,
            dealFilter: { _id: dealId },
            filter: { _id: storeId },
          });

          if (response.resp && response.resp.length > 0) {
            setDealDetails(response.resp[0]);
          } else {
            Toaster.error("Deal details not found");
          }
        } catch (err) {
          Toaster.error("Failed to fetch deal details");
          console.error(err);
        } finally {
          setLoading(false);
        }
      } else if (useDealInfo) {
        setDealDetails(useDealInfo);
      }
    };

    const fetchPreviousPurchase = async () => {
      if (show && dealId) {
        try {
          const response = await PosService.getPurchaseBaskets({
            filter: { "dealInfo.id": dealId, franchiseId: storeId },
          });
          if (response.resp && response.resp.length > 0) {
            setPreviousSuggestion(response.resp[0]);
          } else {
            setPreviousSuggestion(null);
          }
        } catch (err) {
          console.error("Failed to fetch previous purchase", err);
        }
      }
    };

    const fetchOpenOrders = async () => {
      if (show && dealId) {
        try {
          const response = await OmsService.getOpenOrders({
            filter: {
              "subOrders.id": dealId,
              "franchise.id": storeId,
            },
          });
          if (response.resp && response.resp.length > 0) {
            setOpenOrders(response.resp);
          } else {
            setOpenOrders([]);
          }
        } catch (err) {
          console.error("Failed to fetch open orders", err);
        }
      }
    };

    if (show) {
      setQuantity(1);
      setDealDetails(null);
      setPreviousSuggestion(null);
      fetchDealDetails();
      fetchPreviousPurchase();
      fetchOpenOrders();
    }
  }, [show, dealId, storeId, useDealInfo]);

  const handleQuantityChange = (e) => {
    const value = e.target.value;

    if (
      value === "" ||
      (Number.isInteger(Number(value)) && Number(value) > 0)
    ) {
      const newQuantity = value === "" ? "" : Number(value);
      setQuantity(newQuantity);
      setError("");
    } else {
      setError("Please enter a valid whole number greater than 0");
    }
  };

  const handleSubmit = async () => {
    // Validate quantity before submission
    if (!quantity || quantity <= 0) {
      setError("Quantity is required and must be greater than 0");
      return;
    }

    setSubmitting(true);
    try {
      const payload = {
        franchiseId: storeId,
        dealInfo: {
          id: dealId,
          name: dealDetails.name,
          menuId: dealDetails.menuId,
          menuName: dealDetails.menuName,
          categoryId: dealDetails.categoryId,
          categoryName: dealDetails.categoryName,
          brandId: dealDetails.brandId,
          brandName: dealDetails.brandName,
        },
        quantity,
      };

      let response;
      if (editId) {
        response = await PosService.updatePurchaseBasket(editId, payload);
      } else {
        response = await PosService.createPurchaseBasket(payload);
      }

      if (response.statusCode === 200) {
        callback({
          action: "addToBasket",
          purchaseDetails: response.resp,
        });
      } else {
        Toaster.error(
          response.message || "Failed to add item to purchase basket"
        );
      }
    } catch (err) {
      console.error(err);
      Toaster.error("Failed to add item to purchase basket");
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = () => {
    if (!submitting) {
      callback({ action: "close" });
    }
  };

  if (!show) return null;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      backdrop="static"
      keyboard={!submitting}
      size="md"
    >
      <Modal.Header closeButton={!submitting}>
        <Modal.Title as="h6" className="mb-0 text-white">
          {editId ? "Edit Purchase Basket" : "Add to Purchase Basket"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-3">
        {loading ? (
          <div className="text-center py-3">
            <Spinner animation="border" size="sm" />
          </div>
        ) : dealDetails ? (
          <div>
            {/* Product Info - Simplified */}
            <div className="d-flex align-items-center mb-3 gap-3">
              <div className="border rounded overflow-hidden p-1">
                <ImgRender
                  alt={dealDetails.name}
                  assetId={dealDetails.images[0]}
                  isAsset={true}
                  width={60}
                  height={60}
                />
              </div>
              <div>
                <h6 className="mb-1 fs-val-normal fw-semibold">
                  {dealDetails.name}
                </h6>
                <div className="d-flex gap-3 mb-2 fs-val-md">
                  <small className="text-muted">
                    MRP: <Amount value={dealDetails.mrp} />
                  </small>
                  <small className="text-danger fw-medium">
                    B2B Price:{" "}
                    <Amount value={dealDetails.b2bprice} decimalPlace={2} />
                  </small>
                </div>

                {dealDetails.lastOrderDate && (
                  <div className="fs-val-sm">
                    <span className="text-muted me-1">Last Purchased:</span>
                    {dealDetails.lastOrderQty || 0} units (
                    <DateFormatter
                      date={dealDetails.lastOrderDate}
                      format={"dd MMM yyyy"}
                    />
                    )
                  </div>
                )}
              </div>
            </div>

            {/* Key Info - Compact */}
            {!hideStockSalesData && (
              <div className="row g-2 mb-3">
                <div className="col-4">
                  <div className="border rounded p-2 text-center h-100">
                    <div className="text-muted small mb-1">Stock in Store</div>
                    <div className="fw-medium">
                      {dealDetails._qty}{" "}
                      <span className="fs-val-sm">
                        {dealDetails.sellInLooseQty ? "kg" : "units"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="border rounded p-2 text-center h-100">
                    <div className="text-muted small mb-1">Case Qty</div>
                    <div className="fw-medium">{dealDetails.caseQty || 0}</div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="border rounded p-2 text-center h-100">
                    <div className="text-muted small mb-1">Inner Case</div>
                    <div className="fw-medium">
                      {dealDetails.innerPackQty || 0}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!hideStockSalesData && (
              <div className="row g-2 mb-3">
                <div className="col-4">
                  <div className="border rounded p-2 text-center h-100">
                    <div className="text-muted small mb-1">Sold In 7 days</div>
                    <div className="fw-medium">
                      {dealDetails.salesData?.sevenday?._qty || 0}{" "}
                      <span className="fs-val-sm">
                        {dealDetails.sellInLooseQty ? "kg" : "units"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="border rounded p-2 text-center h-100">
                    <div className="text-muted small mb-1">Sold In 15 days</div>
                    <div className="fw-medium">
                      {dealDetails.salesData?.fifteenday?._qty || 0}{" "}
                      <span className="fs-val-sm">
                        {dealDetails.sellInLooseQty ? "kg" : "units"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="border rounded p-2 text-center h-100">
                    <div className="text-muted small mb-1">Sold In 30 days</div>
                    <div className="fw-medium">
                      {dealDetails.salesData?.thirtyday?._qty || 0}{" "}
                      <span className="fs-val-sm">
                        {dealDetails.sellInLooseQty ? "kg" : "units"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Previous Suggestion - Compact */}
            {previousSuggestion && (
              <div className="card mb-3 border-warning border-opacity-50">
                <div className="card-body p-2">
                  <div className="d-flex align-items-center gap-2 mb-1">
                    <i className="bi bi-info-circle text-warning"></i>
                    <small className="fw-medium">Previous Suggested</small>
                  </div>
                  <div className="d-flex justify-content-between">
                    <small>Qty: {previousSuggestion.quantity}</small>
                    <small>
                      Date:{" "}
                      <DateFormatter
                        date={previousSuggestion.createdAt}
                        format="dd/MM/yy"
                      />
                    </small>
                    <small>
                      <span className="badge bg-warning bg-opacity-10 text-warning">
                        {previousSuggestion.status}
                      </span>
                    </small>
                  </div>
                </div>
              </div>
            )}

            {/* Order Input - Simplified */}
            <div className="mt-3">
              <label
                htmlFor="quantityInput"
                className="form-label fw-medium mb-1"
              >
                Suggest Quantity
              </label>
              <div className="input-group">
                <input
                  id="quantityInput"
                  type="number"
                  min="1"
                  value={quantity}
                  onChange={handleQuantityChange}
                  className={`form-control ${error ? "is-invalid" : ""}`}
                  disabled={submitting}
                />
                <button
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  disabled={!quantity || !!error || loading || submitting}
                >
                  {submitting ? (
                    <Spinner size="sm" />
                  ) : (
                    <>
                      <i className="bi bi-cart-plus"></i>{" "}
                      {editId ? "Update" : "Add"}
                    </>
                  )}
                </button>
              </div>
              {error && <div className="text-danger small mt-1">{error}</div>}
            </div>

            {openOrders.length > 0 && (
              <div className="mt-3">
                <div className="fs-val-sm mb-2 fw-semibold text-black-50">
                  Open Orders for this deal at the selected store
                </div>
                <div
                  style={{ height: "120px", overflow: "auto" }}
                  className="border rounded custom-scrollbar"
                >
                  <table className="table table-sm">
                    <TableHeader
                      data={[
                        { label: "Order ID" },
                        { label: "Date" },
                        { label: "Amount" },
                      ]}
                      fwSize="sm"
                      isSticky={true}
                    />
                    <tbody>
                      {openOrders.map((order) => (
                        <tr key={order._id} className="fs-val-sm">
                          <td>
                            <button
                              className="btn btn-link p-0 text-dark text-start"
                              onClick={() => {
                                NavService.openInNewTab("/oms/orders/details", {
                                  id: order._id,
                                });
                              }}
                            >
                              {order._id}
                            </button>
                          </td>
                          <td>
                            <DateFormatter date={order.createdAt} />
                          </td>
                          <td>
                            <Amount value={order.orderAmount} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="text-center text-danger py-3">
            Unable to load deal details
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddToPurchaseBasketModal;
