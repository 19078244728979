import { NavService, PosService } from "@sk/services";
import { AppCard, InfoBlk, KeyVal, PageLoader, Tabs } from "@sk/uis";
import classNames from "classnames";
import produce from "immer";
import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import PosDeliveryDetails from "./PosDeliveryDetails";
import PosOrderBasic from "./PosOrderBasic";
import PosOrderItem from "./PosOrderItem";
import PosOrderTimeline from "./PosOrderTimeline";

const canvasStyle = {
  width: "950px",
};

const tabs = [
  {
    tabName: "Items",
    key: "items",
  },
  {
    tabName: "Timeline",
    key: "timeline",
  },
];

const getData = async (orderId) => {
  const response = await PosService.getOrders({
    filter: {
      _id: orderId,
    },
  });
  const data =
    Array.isArray(response.resp) && response.resp.length
      ? response.resp[0]
      : response.resp;

  if (data._id) {
    const shipmentResponse = await PosService.getPosShipments({
      filter: {
        orderId: data._id,
      },
    });
    data._shipment =
      Array.isArray(shipmentResponse.resp) && shipmentResponse.resp.length
        ? shipmentResponse.resp[0]
        : shipmentResponse.resp;

    if (data._shipment?._statusLog) {
      data._shipment._statusLog[0].log = {
        loggedOn: data.createdAt,
        remark: "Request processed and order generated",
      };
    }
  }

  return {
    order: data,
  };
};

const PosOrderDetailModal = ({ show, callback, orderId }) => {
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tabData, setTabData] = useState([...tabs]);
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const onHide = () => {
    callback({ action: "close" });
  };

  useEffect(() => {
    if (orderId && show) {
      const fetchData = async () => {
        setLoading(true);
        const data = await getData(orderId);
        setOrder({
          ...data.order,
          basic: {
            customerInfo: data.order.customerInfo,
            statusType: data.order.statusType,
            displayStatus: data.order.displayStatus,
            _id: data.order._id,
            _payableAmt: data.order._payableAmt,
            createdAt: data.order.createdAt,
            amountReceivedVia: data.order.amountReceivedVia || "N/A",
            shippingCharges: data.order.shippingCharges || 0,
            couponCode: data.order.couponCode || "",
            couponType: data.order.couponType || "",
            couponValue: data.order.couponValue || 0,
            smartCoinsRedeemed:
              data.order.loyaltyPoints?.customerRedeemedPoints || 0,
          },
          deliveryDetails: {
            deliveryDistance: data.order.deliveryDistance || "N/A",
            franchiseInfo: {
              name: data.order.franchiseInfo?.name,
            },
            deliveryTimeSlot: data.order.deliveryTimeSlot,
            shippingAddress: data.order.shippingAddress,
            lat: data.order.shippingAddress?.geolocation?.coordinates[0],
            lng: data.order.shippingAddress?.geolocation?.coordinates[1],
          },
        });
        setTabData(
          produce((draft) => {
            draft[0].tabName = `${data.order.deals.length} Item${
              data.order.deals.length > 1 ? "s" : ""
            }`;
          })
        );
        setLoading(false);
      };

      setActiveTab(tabs[0]);
      fetchData();
    }
  }, [orderId, show]);

  const onTabChange = ({ value }) => {
    setActiveTab(value);
  };

  return (
    <Offcanvas show={show} onHide={onHide} style={canvasStyle} placement="end">
      <Offcanvas.Header closeButton className="bg-white">
        <Offcanvas.Title>Order Details #{orderId}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modal-bg">
        {loading ? <PageLoader /> : null}
        {!loading && order?._id ? (
          <>
            {order.customerInfo.isGuestCustomer ? (
              <InfoBlk variant="danger">
                <div className="fs-val-sm">
                  Customer information is not available for this order. This may
                  occur if the order was placed by a guest customer who did not
                  provide their details.
                </div>
              </InfoBlk>
            ) : null}

            <div className="row">
              <div className="col-6">
                <div className="position-sticky top-0">
                  <AppCard title="Billing Details">
                    <PosOrderBasic order={order.basic} callback={callback} />
                  </AppCard>
                  <AppCard title="Store Details">
                    <KeyVal
                      label="Store Name"
                      labelCol="col-5"
                      contentCol="col-7"
                    >
                      <div className="d-flex">
                        <span>:</span>
                        <button
                          className="btn btn-link p-0 text-dark fs-val-md text-start ms-1"
                          onClick={() =>
                            NavService.openInNewTab("/pos/sales-analytics", {
                              fid: order.franchiseInfo.id,
                            })
                          }
                        >
                          {order.franchiseInfo.name}
                        </button>
                      </div>
                    </KeyVal>
                    <KeyVal
                      label="Location"
                      labelCol="col-5"
                      contentCol="col-7"
                    >
                      <div className="d-flex">
                        <span className="me-1">:</span>
                        <span>
                          {order.franchiseInfo.town},{" "}
                          {order.franchiseInfo.district},{" "}
                          {order.franchiseInfo.state} -{" "}
                          {order.franchiseInfo.pincode}
                        </span>
                      </div>
                    </KeyVal>
                  </AppCard>
                  {order.requestedId ? (
                    <AppCard title="Delivery Details">
                      <PosDeliveryDetails order={order.deliveryDetails} />
                    </AppCard>
                  ) : null}
                </div>
              </div>
              <div className="col-6">
                {order.loyaltyPoints?.customerPoints ? (
                  <InfoBlk variant="success">
                    Customer earned {order.loyaltyPoints.customerPoints}{" "}
                    <img
                      src="https://app.storeking.in/pos/assets//images/kingcoins/coins-v2.png"
                      width={60}
                    />{" "}
                    for this order!
                  </InfoBlk>
                ) : null}
                <div className="mb-2">
                  <Tabs
                    data={tabData}
                    activeTab={activeTab}
                    callback={onTabChange}
                    template={3}
                  />
                </div>

                {activeTab.key === "items" ? (
                  <AppCard>
                    {order.deals.map((o) => (
                      <div
                        key={o._id}
                        className={classNames("py-3", {
                          "border-bottom":
                            order.deals.indexOf(o) !== order.deals.length - 1,
                        })}
                      >
                        <PosOrderItem order={o} />
                      </div>
                    ))}
                  </AppCard>
                ) : null}

                {activeTab.key === "timeline" ? (
                  <AppCard>
                    {order.requestedId ? (
                      <PosOrderTimeline
                        statusLog={order._shipment?._statusLog}
                      />
                    ) : (
                      <div className="text-center fs-val-sm text-muted">
                        Logs not found to display, as it was purchased directly
                        from the store.
                      </div>
                    )}
                  </AppCard>
                ) : null}
              </div>
            </div>
          </>
        ) : null}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default PosOrderDetailModal;
