import React, { useEffect, useState } from "react";
import { AuthService, EmployeeService, RolesService } from "@sk/services";
import { BusyLoader } from "@sk/uis";

const LoadUserRoles = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserRoles = async () => {
      try {
        if (AuthService.isUserLoggedIn()) {
          const empId = AuthService.getLoggedInEmpId();
          const groupsResp = await RolesService.getEmpAssignedGroups(empId);
          AuthService.setUserRolesInLocal(groupsResp?.resp);
          AuthService.setUserStoreIdsInLocal(groupsResp?.resp?.stores || []);
        }
      } catch (err) {
        console.error("Error loading user roles:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserRoles();
  }, []);

  return <BusyLoader show={loading} message="Loading, please wait..." />;
};

export default LoadUserRoles;
