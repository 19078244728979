import { memo, useEffect, useState } from "react";
import { AppSlide, AppSwiper, SummaryCard } from "@sk/uis";
import { PoService } from "@sk/services";
import { merge } from "lodash";

const swiperConfig = {
  slidesPerView: "auto",
  spaceBetween: 20,
  mousewheel: {
    forceToAxisMode: true,
  },
};

const PoListSummary = ({ filterParams }) => {
  const [summaryData, setSummaryData] = useState([]);

  useEffect(() => {
    const fetchSummary = async () => {
      const statuses = PoService.getPoStatuses();

      setSummaryData(
        statuses.map((x) => ({
          ...x,
          value: 0,
          loading: true,
        }))
      );

      let p = { ...filterParams };

      const promises = statuses.map((x) => {
        return getCount(
          merge({}, p, x.value ? { filter: { status: x.value } } : {})
        );
      });

      const c = await Promise.all(promises);
      setSummaryData(
        statuses.map((x, i) => ({
          ...x,
          label: x.label == "All" ? "Total" : x.label,
          value: c[i].count || 0,
          loading: false,
        }))
      );
    };

    if (filterParams) {
      fetchSummary();
    }
  }, [filterParams]);

  return (
    <AppSwiper config={swiperConfig}>
      {summaryData.map((item, index) => (
        <AppSlide key={index} autoWidth={true}>
          <SummaryCard
            title={item.label}
            value={item.value}
            loading={item.loading}
            template={3}
            valueColor={item.color}
          />
        </AppSlide>
      ))}
    </AppSwiper>
  );
};

const getCount = async (params) => {
  const r = await PoService.getCount(params);
  return {
    count: r.resp,
  };
};

export default memo(PoListSummary);
