import { Tabs } from "@sk/uis";
import { memo } from "react";

const tabs = [
  {
    key: "reservation",
    tabName: "Reservation",
    redirect: {
      path: "/dashboard/reservation",
    },
  },
  {
    key: "orders",
    tabName: "Orders",
    redirect: {
      path: "/oms/reserve/order-reserve",
    },
  },
  {
    key: "create_po",
    tabName: "Manage PO",
    redirect: {
      path: "/oms/reserve/purchase-order",
    },
  },
];

const ReservationTabs = ({ tabKey, onTabChange }) => {
  const index = tabs.findIndex((tab) => tab.key === tabKey);
  const activeTab = tabs[index] || tabs[0];

  return (
    <Tabs
      data={tabs}
      activeTab={activeTab}
      callback={({ value }) => onTabChange(value)}
      template={4}
    />
  );
};

export default memo(ReservationTabs);
