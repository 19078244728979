import totalImg from "../assets/imgs/total.svg";
import activeImg from "../assets/imgs/active.svg";
import inactiveImg from "../assets/imgs/inactive.svg";
import upcomingImg from "../assets/imgs/not-linked.svg";

const formView = {};
export const listView = {
  breadcrumbs: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Banner List",
    },
  ],

  summaryData: [
    {
      label: "Total Sub Banners",
      value: 0,
      loading: true,
      filter: {},
      color: "primary",
      img: totalImg,
    },
    {
      label: "Live Sub Banners",
      value: 0,
      loading: true,
      filter: {},
      color: "success",
      img: activeImg,
      status: "Live",
    },
    {
      label: "Upcoming Sub Banners",
      value: 0,
      loading: true,
      filter: {},
      color: "warning",
      img: upcomingImg,
      status: "Upcoming",
    },
    {
      label: "Expired Sub Banners",
      value: 0,
      loading: true,
      filter: {},
      color: "danger",
      img: inactiveImg,
      status: "Expired",
    },
  ],

  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },

  formLabels: {
    dateRange: {
      label: "Date Range",
      type: "dateRange",
    },
    page: {
      label: "Page",
    },
    location: {
      label: "Location",
    },
    status: {
      label: "Status",
    },
    endTime: {
      label: "End Time",
      type: "time",
    },
    startTime: {
      label: "Start Time",
      type: "time",
    },
    applicableFor: {
      label: "Applicable For",
    },
    state: {
      label: "State",
    },
    district: {
      label: "District",
    },
    town: {
      label: "Town",
    },
    rf: {
      label: "RF",
      valuePath: "[0]['label']",
    },
    rmf: {
      label: "RMF",
      valuePath: "[0]['label']",
    },
    sf: {
      label: "SF",
      valuePath: "[0]['label']",
    },
    accessLevel: {
      label: "Access Level",
    },
    fran: {
      label: "Franchise",
      valuePath: "[0]['label']",
    },
    bannerLocationCode: {
      label: "Banner Location Code",
    },
    platformType: {
      label: "Platform Type",
    },
    filterByFranchiseGroupType: {
      label: "Filter By Franchise Group Type",
    },
    filterByFranchiseType: {
      label: "Filter By Franchise Type",
    },
    filterByConditionType: {
      label: "Input Criteria",
    },
  },

  additionalTableDataConfig: [
    {
      key: "filterByFranchiseId",
      api: "franchise",
      loadingKey: "franchiseLoading",
      dataKey: "_filterByFranchiseId",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name,state,district,town,pincode",
      }),
    },
  ],
};
// list View ends

export const filterView = {
  statusOptions: [
    { label: "All", value: "" },
    { label: "Live", value: "LIVE" },
    { label: "Upcoing", value: "UPCOMING" },
    { label: "Expired", value: "EXPIRED" },
  ],

  bannerwiseStatusOptions: [
    { label: "All", value: "" },
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ],

  filterFormData: {
    dateRange: [],
    status: "",
    startTime: [],
    endTime: [],
    accessLevel: "",
    applicableFor: "",
    state: "",
    district: "",
    town: "",
    rmf: "",
    rf: "",
    sf: "",
    bannerLocationCode: "",
    platformType: "",
    fran: "",
    filterByFranchiseType: "",
    filterByFranchiseGroupType: "",
    page: "",
    location: "",
  },

  applicableForOptions: [
    { label: "All", value: "" },
    { label: "With Login", value: "Franchise" },
    { label: "Without Login", value: "Customer" },
  ],

  appTypeOptions: [
    { label: "All", value: "" },
    { label: "SK", value: "SK" },
    { label: "Club", value: "Club" },
  ],

  accessLevelOptions: [
    { label: "All", value: "" },
    { label: "State", value: "state" },
    { label: "RMF", value: "rmf" },
    { label: "RF", value: "rf" },
    { label: "SF", value: "sf" },
  ],

  platformTypeOptions: [
    { label: "All ", value: "" },
    { label: "Retailer App", value: "RetailerApp" },
    { label: "Club App", value: "ClubApp" },
  ],

  filterByFranchiseTypeOptions: [
    { label: "All", value: "" },
    { label: "By Group", value: "ByGroup" },
    { label: "By Franchise", value: "ByFranchise" },
  ],

  filterByFranchiseGroupTypeOptions: [
    { label: "ALL", value: "" },
    { label: "ALL", value: "ALL" },
    { label: "COCO", value: "COCO" },
    { label: "FOFO", value: "FOFO" },
  ],

  filterByConditionTypeOptions: [
    {
      label: "Select",
      value: "",
      key: ["Brand", "Category", "Brand-Category"],
    },
    { label: "No Input", value: "No Input", key: ["No Input"] },
    { label: "Brand", value: "Brand", key: ["Brand"] },
    { label: "Category", value: "Category", key: ["Category"] },
    {
      label: "Brand-Category",
      value: "Brand-Category",
      key: ["Brand-Category", "Brand", "Category"],
    },
    { label: "Deal", value: "Deal", key: ["Product"] },
    { label: "Menu", value: "Menu", key: ["Menu"] },
    // { label: "Keywords", value: "Keywords", key: ["Keywords"] },
  ],
};
// filter View ends

export const manageView = {
  breadcrumbs: [
    {
      name: "Home",
      link: "/configs/banner/list",
    },

    {
      name: "Banner List",
      link: "/configs/banner/list",
    },
    {
      name: "Add Banner",
    },
  ],

  platformTypeOptions: [
    { label: "Retailer App", value: "RetailerApp" },
    { label: "Club App", value: "ClubApp" },
  ],

  applicableForOptions: [
    { label: "Select ", value: "" },
    { label: "With Login", value: "Franchise" },
    { label: "Without Login", value: "Customer" },
  ],

  appTypeOptions: [
    { label: "SK", value: "SK" },
    { label: "Club", value: "Club" },
  ],

  displayTypeOptions: [
    { label: "Image Banner", value: "image" },
    { label: "Text Banner", value: "text" },
  ],

  subTypeOptions: [
    { label: "Select ", value: "" },
    { label: "B2B", value: "B2B" },
    { label: "B2C", value: "B2C" },
    { label: "B2B & B2C", value: "B2B & B2C" },
  ],

  bannerTypeOptions: [
    { label: "Select ", value: "" },
    { label: "No Action", value: "NOACTION" },
    { label: "Deal", value: "deal" },
    { label: "Brand", value: "brand" },
    { label: "Category", value: "category" },
    { label: "Brand & Category", value: "brandAndCategory" },
    // { label: "Marketplace Seller", value: "marketPlaceSeller" },
    // { label: "KingSale", value: "kingsale" },
    { label: "Keyword", value: "keyword" },
    // { label: "Recharge", value: "recharge" },
    // { label: "DMT", value: "dmt" },
    // { label: "Wallet", value: "wallet" },
    { label: "Menu", value: "menu" },
    { label: "Multi Deals", value: "multiDeals" },
    { label: "Multi Categories", value: "multiCategories" },
    { label: "Multi Brands", value: "multiBrands" },
    // { label: "Service Partner", value: "servicePartner" },
    // { label: "Enrollment", value: "enrollment" },
  ],

  enrollmentTypeOptions: [
    { label: "Select ", value: "" },
    { label: "AEPS ", value: "AEPS" },
    { label: "Axis CDM", value: "AxisCDM" },
    { label: "SK1", value: "SK1" },
    { label: "Amazon Easy", value: "AmazonEasy" },
    { label: "DMT", value: "DMT" },
    { label: "Gold", value: "Gold" },
    { label: "HDFC", value: "HDFC" },
    { label: "HDFC CUG", value: "HDFC CUG" },
    { label: "HDFC Account", value: "HDFCAccount" },
    { label: "Insurance", value: "Insurance" },
    { label: "Pick Up Point(PUP)", value: "PUP" },
    { label: "Two Wheeler", value: "Two Wheeler Insurance" },
    { label: "Personal Accident", value: "Personal Accident Insurance" },
    { label: "CUG", value: "CUG" },
    { label: "Smart MiniStore", value: "SmartMiniStore" },
    { label: "GPAY SmartMiniStore", value: "GPAY_SMART_MINI_STORE" },
    { label: "Health Assistance", value: "Health Assistance" },
  ],

  activePeriodTypeOpotions: [
    { label: "Select", value: "" },
    { label: "All day", value: "all" },
    { label: "Custom Slot", value: "range" },
  ],

  accessLevelOptions: [
    { label: "All", value: "" },
    { label: "State", value: "state" },
    { label: "RMF", value: "rmf" },
    { label: "RF", value: "rf" },
    { label: "SF", value: "sf" },
  ],

  // To Attach Additional Config
  attachAdditionalDataConfig: [
    {
      key: "filterByFranchiseId",
      api: "franchise",
      loadingKey: "franchiseLoading",
      dataKey: "_filterByFranchiseName",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name,district,town,city,state",
      }),
    },
  ],

  filterByFranchiseTypeOptions: [
    { label: "Choose", value: "" },
    { label: "By Group", value: "ByGroup" },
    { label: "By Franchise", value: "ByFranchise" },
  ],

  filterByFranchiseGroupTypeOptions: [
    { label: "All", value: "" },
    { label: "ALL", value: "ALL" },
    { label: "COCO", value: "COCO" },
    { label: "FOFO", value: "FOFO" },
  ],

  filterByConditionTypeOptions: [
    {
      label: "Choose",
      value: "",
      key: ["Brand", "Category", "Brand-Category"],
    },
    { label: "No Input", value: "No Input", key: ["No Input"] },
    { label: "Brand", value: "Brand", key: ["Brand"] },
    { label: "Category", value: "Category", key: ["Category"] },
    {
      label: "Brand-Category",
      value: "Brand-Category",
      key: ["Brand-Category", "Brand", "Category"],
    },
    { label: "Deal", value: "Deal", key: ["Product"] },
    { label: "Menu", value: "Menu", key: ["Menu"] },
    { label: "Keywords", value: "Keywords", key: ["Keywords"] },
  ],
};
// manage View ends

export default formView;
