import { PosService } from "@sk/services";
import { Amount, AppCard, DisplayUnit, PageLoader, Tabs } from "@sk/uis";
import { useEffect, useMemo, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import LedgerList from "../../components/stock-ledger/LedgerList";
import LedgerMaster from "../../components/stock-ledger/LedgerMaster";

const tabsData = [
  {
    key: "ledger",
    tabName: "Stock Ledger",
  },
  {
    key: "summary",
    tabName: "Inventory Summary",
  },
];

const StockLedgerModal = ({ show, callback, storeId, dealId, noView }) => {
  const [deal, setDeal] = useState({});
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(tabsData[0]);

  const filterDataRef = useRef({});

  const canvasStyle = useMemo(() => {
    let style = {
      width: "80%",
      transition: "opacity 0.3s ease-in-out",
    };
    if (noView) {
      style.opacity = 0;
    } else {
      style.opacity = 1;
    }
    return style;
  }, [noView]);

  const onClose = () => {
    callback({ action: "close" });
  };

  const init = async () => {
    setLoading(true);
    const r = await PosService.getDealsStock({
      dealFilter: {
        _id: dealId,
      },
      filter: {
        _id: storeId,
      },
    });
    const d = Array.isArray(r.resp) ? r.resp[0] : r.resp;
    setDeal(d);
    setLoading(false);
  };

  useEffect(() => {
    if (show) {
      setActiveTab(tabsData[0]);
      filterDataRef.current = {
        storeId,
        dealId,
      };
      init();
    }
  }, [show, storeId, dealId]);

  const onTabChange = ({ value }) => {
    setActiveTab(value);
  };

  const handleLedgerCallback = (data) => {
    if (data.action === "viewLedgerDetail") {
      callback({
        action: "openLedgerDetail",
        ledgerId: data.ledgerId,
      });
    }
  };

  return (
    <Offcanvas
      show={show}
      onHide={onClose}
      placement="end"
      style={canvasStyle}
      backdrop={!noView}
    >
      <Offcanvas.Header closeButton className="bg-white">
        <Offcanvas.Title>Stock Ledger</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modal-bg">
        {loading ? <PageLoader /> : null}

        {!loading && deal?._id ? (
          <>
            <AppCard className="border-primary border-start border-4">
              <div className="mb-2">
                <span className="fs-val-lg fw-semibold">{deal.name}</span>
                <span className="badge bg-primary ms-2">{deal._id}</span>
              </div>
              <div className="fs-val-sm text-muted mb-3">
                <i className="bi bi-box-seam me-2"></i>
                {deal.categoryName} • {deal.brandName} • {deal.menuName}
              </div>
              <div className="row g-3">
                <div className="col-3">
                  <div className="border rounded p-2">
                    <div className="text-muted fs-val-sm mb-1">MRP</div>
                    <div className="fw-semibold">
                      <Amount value={deal.mrp} decimalPlace={2} />
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="border rounded p-2">
                    <div className="text-muted fs-val-sm mb-1">
                      Available Stock
                    </div>
                    <div className="fw-semibold">
                      <DisplayUnit
                        isLoose={deal.sellInLooseQty}
                        value={deal.qty}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="border rounded p-2">
                    <div className="text-muted fs-val-sm mb-1">
                      Selling Price
                    </div>
                    <div className="fw-semibold">
                      <Amount
                        value={deal._finalSellingPrice}
                        decimalPlace={2}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="border rounded p-2">
                    <div className="text-muted fs-val-sm mb-1">UOM</div>
                    <div className="fw-semibold">
                      {deal.sellInLooseQty ? "KG" : "Units"}
                    </div>
                  </div>
                </div>
              </div>
            </AppCard>

            <AppCard noPad={true}>
              <Tabs
                data={tabsData}
                activeTab={activeTab}
                callback={onTabChange}
              />
              <div className="p-4">
                {activeTab.key === "ledger" && (
                  <LedgerList
                    storeId={storeId}
                    dealId={dealId}
                    callback={handleLedgerCallback}
                    isLooseDeal={deal.sellInLooseQty}
                  />
                )}
                {activeTab.key === "summary" && (
                  <LedgerMaster
                    storeId={storeId}
                    dealId={dealId}
                    isLooseDeal={deal.sellInLooseQty}
                  />
                )}
              </div>
            </AppCard>
          </>
        ) : null}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default StockLedgerModal;
