import { CustomerService } from "@sk/services";
import {
  Alert,
  AppCard,
  BusyLoader,
  Spinner,
  TableHeader,
  Toaster,
  Rbac,
} from "@sk/uis";
import { RemarksModal } from "@sk/features";
import produce from "immer";
import { useEffect, useState } from "react";
import ClubSuspendLogsModal from "../../club-suspend-logs/ClubSuspendLogsModal";

const tableHeaders = [
  { label: "Feature" },
  { label: "Description" },
  { label: "Status", key: "status" },
  { label: "Logs", key: "showLog", isCentered: true },
];

const CustFeatureAccess = ({ cid }) => {
  const [loading, setLoading] = useState(true);
  const [access, setAccess] = useState([
    {
      name: "Block Coin Store Purchase",
      description: "Block customer from making coin store purchases",
      access: false,
      accessKey: "isBlockedSmartCoinStore",
      defaultValue: false,
      rbac: ["FeatureAccess"],
    },
    {
      name: "Block Club Purchase",
      description: "Suspend customer from making purchases in the club",
      access: false,
      accessKey: "customerSuspended",
      defaultValue: false,
      showLog: true,
      rbac: ["CustomerClubRequestSuspend"],
    },
    // Add more features here as needed
  ]);

  const [busyLoader, setBusyLoader] = useState({ show: false });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showRemarksModal, setShowRemarksModal] = useState(false);

  useEffect(() => {
    const loadDetails = async () => {
      setLoading(true);
      try {
        const r = await CustomerService.getDetail(cid);
        setAccess(
          produce((draft) => {
            draft.forEach((item) => {
              if (typeof r.resp[item.accessKey] === "boolean") {
                item.access = r.resp[item.accessKey];
              } else {
                item.access = item.defaultValue;
              }
            });
          })
        );
      } catch (error) {
        console.error("Error loading access details:", error);
      }
      setLoading(false);
    };

    if (cid) {
      loadDetails();
    }
  }, [cid]);

  const handleAccessChange = async (index, newValue) => {
    const feature = access[index];
    const action = newValue ? "Enable" : "Disable";

    const result = await Alert.confirm({
      title: `${action} "${feature.name}"?`,
      text: `Are you sure you want to ${action.toLowerCase()} ${
        feature.name
      } for this customer?`,
      icon: "warning",
      okText: `Yes, ${action.toLowerCase()}!`,
      cancelText: "Cancel",
    });

    if (result.isConfirmed) {
      if (feature.accessKey === "customerSuspended") {
        setShowRemarksModal(true);
        return;
      }

      setBusyLoader({ show: true });
      try {
        const resp = await CustomerService.updateCustomer(cid, {
          [feature.accessKey]: newValue,
        });

        if (resp.statusCode === 200) {
          setAccess(
            produce((draft) => {
              draft[index].access = newValue;
            })
          );
          Toaster.success("Access updated successfully");
        } else {
          Toaster.error(resp.message || "Failed to update access");
        }
      } catch (error) {
        Toaster.error("Failed to update access");
      }
      setBusyLoader({ show: false });
    }
  };

  const handleRemarksSubmit = async (remarks) => {
    setBusyLoader({ show: true });
    try {
      const feature = access.find(
        (item) => item.accessKey === "customerSuspended"
      );
      const response = await CustomerService.suspendCustomer(
        cid,
        !feature.access,
        remarks
      );

      if (response.statusCode === 200) {
        Toaster.success("Customer suspended successfully");
        setShowRemarksModal(false);

        setAccess(
          produce((draft) => {
            const index = draft.findIndex(
              (item) => item.accessKey === "customerSuspended"
            );
            if (index !== -1) {
              draft[index].access = !draft[index].access;
            }
          })
        );
      } else {
        Toaster.error(response.message || "Failed to suspend customer");
      }
    } catch (error) {
      Toaster.error("Failed to suspend customer");
    }
    setBusyLoader({ show: false });
  };

  const openLogsModal = () => {
    setIsModalOpen(true);
  };

  const closeLogsModal = () => {
    setIsModalOpen(false);
  };

  const handleRemarksModalCallback = ({ action, remarks }) => {
    setShowRemarksModal(false);
    if (action === "submit") {
      handleRemarksSubmit(remarks);
    }
  };

  return (
    <>
      <AppCard title="Feature Access Control">
        {loading ? (
          <div className="text-center">
            <Spinner size="sm" />
          </div>
        ) : (
          <div className="table-responsive">
            <table className="table table-sm table-striped">
              <TableHeader data={tableHeaders} noBg={true} />
              <tbody className="fs-val-md">
                {access.map((item, index) => (
                  <Rbac key={index} roles={item.rbac}>
                    <tr>
                      <td>{item.name}</td>
                      <td>{item.description}</td>
                      <td>
                        <select
                          className="form-select form-select-sm"
                          value={item.access}
                          onChange={(e) =>
                            handleAccessChange(index, e.target.value === "true")
                          }
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </td>
                      <td className="text-center">
                        {item.showLog && (
                          <button
                            className="btn btn-link text-dark btn-sm fs-val-sm"
                            onClick={openLogsModal}
                          >
                            View Logs
                          </button>
                        )}
                      </td>
                    </tr>
                  </Rbac>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </AppCard>
      <BusyLoader show={busyLoader.show} />

      <ClubSuspendLogsModal
        customerId={cid}
        onClose={closeLogsModal}
        show={isModalOpen}
      />

      <RemarksModal
        title={'Enter Remarks to "Suspend Customer"'}
        show={showRemarksModal}
        callback={handleRemarksModalCallback}
      />
    </>
  );
};

export default CustFeatureAccess;
