import { NavService, PosService } from "@sk/services";
import {
  Amount,
  DateFormatter,
  DateInput,
  HighlightText,
  NoDataFound,
  Pagination,
  PaginationSummary,
  SelectInput,
  TableHeader,
  TableSkeletonLoader,
  TextInput,
} from "@sk/uis";
import { set } from "date-fns";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";

const dateConfig = {
  mode: "range",
  maxDate: new Date(),
};

const tableStyle = {
  minWidth: "1400px",
};

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 15,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 15,
};

const defaultFilterData = {
  // Define any default filter data if necessary
};

const statusOptions = PosService.getPosOrderStatusGroups().map((e) => ({
  label: e.name,
  value: e.key,
}));
statusOptions.unshift({ label: "All", value: "" });

const defaultFormData = {
  search: "",
  dateRange: [], // For date range
  status: "", // For select dropdown
};

const headers = [
  { label: "Sl No", width: "40px", key: null },
  { label: "Order ID", width: "100px", key: "_id", enableSort: true },
  {
    label: "Store",
    width: "200px",
    key: "franchiseInfo.name",
    enableSort: true,
  },
  {
    label: "Store Town",
    width: "100px",
    key: "franchiseInfo.town",
    enableSort: true,
  },
  {
    label: "Store Pincode",
    width: "100px",
    key: "franchiseInfo.pincode",
    enableSort: true,
  },
  { label: "Order Date", width: "120px", key: "createdAt", enableSort: true },
  {
    label: "Order Value",
    width: "120px",
    key: "orderSummary.amount",
    enableSort: true,
  },
  { label: "Status", width: "90px", key: "status", enableSort: true },
  { label: "Order Type", width: "100px", key: "orderType", enableSort: true },
];

const getData = async (params) => {
  const r = await PosService.getOrders(params);
  const d = (Array.isArray(r.resp) ? r.resp : []).map((e) => {
    let status = "";
    if (e.status == "Closed") {
      status = "Yet to Deliver";
    } else {
      status = e.status;
    }
    return { ...e, _status: status };
  });
  return { data: d };
};

const getCount = async (params) => {
  let p = { ...params, outputType: "count" };
  delete p.page;
  delete p.count;

  const r = await PosService.getOrders(p);
  return { count: r.resp };
};

const prepareFilterParams = (filter, pagination, sort) => {
  let p = {
    page: pagination?.activePage,
    count: pagination?.rowsPerPage,
    filter: {
      "customerInfo.id": filter.cid,
    },
    sort: sort.value === "asc" ? sort.key : `-${sort.key}`,
  };

  const s = filter.search?.trim();

  if (s) {
    p.filter.$or = [
      {
        _id: s,
      },
      {
        "franchiseInfo.name": {
          $regex: s,
          $options: "gi",
        },
      },
    ];
  }

  if (filter.status) {
    const statusGroup = PosService.getPosOrderStatusGroups().find(
      (e) => e.key === filter.status
    )?.statusGroup;

    if (statusGroup) {
      p.filter.status = {
        $in: statusGroup,
      };
    }
  }

  if (filter?.dateRange?.length > 0) {
    p.filter.createdAt = {
      $gte: set(new Date(filter.dateRange[0]), {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(new Date(filter.dateRange[1]), {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (filter.status == "Debit") {
    p.filter.status = "Debit";
  }

  return p;
};

const CustOrders = ({ cid = "", callback }) => {
  const { control, register, getValues } = useForm({
    defaultValues: defaultFormData,
  });

  const [data, setData] = useState([]);

  const [records, setRecords] = useState({ loading: true, value: 0 });

  const [loadingData, setLoadingData] = useState(true);

  const filterDataRef = useRef({ ...defaultFilterData });

  const paginationRef = useRef({ ...defaultPaginationConfig });
  const sortDataRef = useRef({ key: "createdAt", value: "desc" });

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };

    filterDataRef.current = {
      ...filterDataRef.current,
      ...getValues(),
    };

    loadList();

    setRecords({ loading: true, value: 0 });
    const r = await getCount(
      prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortDataRef.current
      )
    );
    setRecords({ loading: false, value: r.count });
    paginationRef.current.totalRecords = r.count;
  }, [getValues, loadList]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    filterDataRef.current = { cid: cid };

    init();
  }, [cid, init]);

  const loadList = useCallback(async () => {
    setLoadingData(true);

    setData([]);

    const r = await getData(
      prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortDataRef.current
      )
    );

    const d = r.data || [];

    setData(d);

    setLoadingData(false);
  }, []);

  const onDateChange = (chngFn) => (val) => {
    chngFn(val);
    applyFilter();
  };

  const onStatusChange = () => {
    applyFilter();
  };

  const onSearch = useCallback(
    debounce(() => {
      applyFilter();
    }, 800),
    []
  );

  const paginationCb = (data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    loadList();
  };

  const sortCb = (data) => {
    sortDataRef.current = data;
    applyFilter();
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-4">
          <TextInput
            register={register}
            callback={onSearch}
            gap={0}
            label="Search Order ID/Store Name"
            name="search"
          />
        </div>
        <div className="col-4">
          <Controller
            name="dateRange"
            control={control}
            render={({ field }) => (
              <DateInput
                label="Date Range"
                onChange={field.onChange}
                value={field.value}
                callback={onDateChange(field.onChange)}
                config={dateConfig}
                gap={0}
                template={2}
              />
            )}
          />
        </div>
        <div className="col-4">
          <SelectInput
            label="Order Status"
            options={statusOptions}
            register={register}
            name="status"
            callback={onStatusChange}
            gap={0}
          />
        </div>
      </div>
      <PaginationSummary
        loadingTotalRecords={records.loading}
        paginationConfig={paginationRef.current}
        className="mb-1"
      />

      <div className="table-responsive custom-scrollbar">
        <table
          className="table bg-white table-striped table-sm"
          style={tableStyle}
        >
          <TableHeader
            data={headers}
            noBg={true}
            sortCb={sortCb}
            sort={sortDataRef.current}
          />
          <tbody className="fs-val-md">
            {!loadingData && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Data found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {loadingData ? (
              <TableSkeletonLoader
                cols={headers.length}
                rows={10}
                height={40}
              />
            ) : null}

            {data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                <td>{paginationRef.current.startSlNo + index}</td>
                <td>
                  <button
                    className="btn btn-link p-0 fs-val-md text-dark text-start"
                    onClick={() => callback({ action: "viewOrder", id: x._id })}
                  >
                    {x._id}
                  </button>
                </td>
                <td>
                  <button
                    className="btn btn-link p-0 fs-val-md text-dark text-start"
                    onClick={() =>
                      NavService.openInNewTab("/pos/sales-analytics", {
                        fid: x.franchiseInfo.id,
                      })
                    }
                  >
                    {x.franchiseInfo.name}
                  </button>
                </td>
                <td>{x.franchiseInfo.town || "N/A"}</td>
                <td>{x.franchiseInfo.pincode || "N/A"}</td>
                <td>
                  <DateFormatter date={x.createdAt} />
                </td>
                <td>
                  <Amount value={x.orderSummary.amount} />
                </td>
                <td>
                  <HighlightText template={2} type={x.statusType}>
                    {x.displayStatus}
                  </HighlightText>
                </td>
                <td>{x.orderType}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="text-end">
        <Pagination
          callback={paginationCb}
          activePage={paginationRef.current.activePage}
          rowsPerPage={paginationRef.current.rowsPerPage}
          totalRecords={paginationRef.current.totalRecords}
        />
      </div>
    </>
  );
};

export default CustOrders;
