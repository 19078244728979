import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useAttachAdditionalData } from "@sk/hooks";
import {
  AjaxService,
  AuthService,
  BannerService,
  BrandService,
  CategoryService,
  DealService,
  DigitalPartnerService,
  FranchiseService,
  SourcingService,
} from "@sk/services";
import { Alert, AppCard, PageLoader, Spinner, Toaster } from "@sk/uis";
import { memo, useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { manageView } from "../../constantService.js";
import BannerDetails from "./components/BannerDetails.jsx";

const canvasStyle = { width: "60%" };

const validationSchema = yup.object({
  filterByFranchiseType: yup
    .string()
    .trim()
    .label("Filter by Franchise Type")
    .required(),
  filterByFranchiseGroupType: yup
    .string()
    .trim()
    .when("filterByFranchiseType", {
      is: (filterByFranchiseType) => filterByFranchiseType == "ByGroup",
      then: (schema) => schema.label("Filter By Franchise Code").required(),
    }),
  franchise: yup.array().when("filterByFranchiseType", {
    is: (filterByFranchiseType) => filterByFranchiseType == "ByFranchise",
    then: (schema) => schema.label("Franchise").required(),
  }),

  title: yup.string().trim().label("Title").required(),
  status: yup.string(),
  displayType: yup.string().label("Display Type").required(),
  // appType: yup.string().default("SK").label("App type").required(),
  subType: yup.string().when("bannerType", {
    is: (bannerType) =>
      [
        "deal",
        "category",
        "brand",
        "brandAndCategory",
        "marketPlaceSeller",
        "kingsale",
      ].indexOf(bannerType) != -1,
    then: (schema) => schema.label("Sub Type"),
  }),
  bannerType: yup.string().label("Redirection To").required(),
  // image: yup.string().when("displayType", {
  //   is: (displayType) => displayType == "image",
  //   then: (schema) =>
  //     schema
  //       .min(1, "Banner Image is Required")
  //       .label("Banner Image")
  //       .required(),
  // }),
  // mobileImage: yup.string().when("displayType", {
  //   is: (displayType) => displayType == "image",
  //   then: (schema) =>
  //     schema
  //       .min(1, "Banner Mobile Image is Required")
  //       .label("Banner Mobile Image")
  //       .required(),
  // }),
  bannerText: yup.string().when("displayType", {
    is: (displayType) => displayType == "text",
    then: (schema) => schema.label("Banner Text").required(),
  }),
  url: yup.string(),
  startDate: yup
    .date()
    .label("Validity Start Date")
    .typeError("Validity Start Date is Required")
    .required(),
  endDate: yup
    .date()
    .label("Validity End Date")
    .typeError("Validity End Date is Required")
    .min(
      yup.ref("startDate"),
      "End date must be at least 1 hour after start date"
    )
    .test(
      "is-greater",
      "End date must be at least 1 hour after start date",
      function (value) {
        const { startDate } = this.parent;
        return (
          new Date(value).getTime() - new Date(startDate).getTime() >= 3600000
        ); // 3600000 ms = 1 hour
      }
    )
    .required(),
  startTime1: yup.array().when("activePeriod", {
    is: (activePeriod) => activePeriod == "range",
    then: (schema) => schema.min(1).label("Start Time").required(),
  }),
  endTime1: yup.array().when("activePeriod", {
    is: (activePeriod) => activePeriod == "range",
    then: (schema) => schema.min(1).label("End Time").required(),
  }),
  activePeriod: yup.string().label("Active Period"),
  // .required(),

  startTime2: yup.array(),

  endTime2: yup.array().when(["activePeriod", "startTime2"], {
    is: (activePeriod, startTime2) =>
      activePeriod == "range" && startTime2 && startTime2.length,
    then: (schema) =>
      schema.min(1, "End Time  is Required").label("End Time").required(),
  }),

  shortDescription: yup.string().label("Description"),

  // Banner Type data

  deal: yup.array().when("bannerType", {
    is: (bannerType) => bannerType == "deal",
    then: (schema) =>
      schema.min(1, "Deal is Required").label("Deal").required(),
  }),

  brand: yup.array().when("bannerType", {
    is: (bannerType) => ["brand", "brandAndCategory"].indexOf(bannerType) != -1,
    then: (schema) =>
      schema.min(1, "Brand is Required").label("Brand").required(),
  }),

  category: yup.array().when("bannerType", {
    is: (bannerType) =>
      ["category", "brandAndCategory", "menu"].indexOf(bannerType) != -1,
    then: (schema) =>
      schema.min(1, "Category is Required").label("Category").required(),
  }),

  marketPlaceSeller: yup.array().when("bannerType", {
    is: (bannerType) => bannerType == "marketPlaceSeller",
    then: (schema) =>
      schema
        .min(1, "MarketPlace Seller is Required")
        .label("MarketPlace Seller")
        .required(),
  }),

  kingSale: yup.array().when("bannerType", {
    is: (bannerType) => bannerType == "kingsale",
    then: (schema) =>
      schema.min(1, "King Sale is Required").label("kingSale").required(),
  }),

  keyword: yup.string().when("bannerType", {
    is: (bannerType) => bannerType == "keyword",
    then: (schema) =>
      schema.min(1, "Keyword is Required").max(300).label("keyword").required(),
  }),

  digitalPartner: yup.array().when("bannerType", {
    is: (bannerType) =>
      ["recharge", "dmt", "wallet", "servicePartner"].indexOf(bannerType) != -1,
    then: (schema) => schema.min(1, "Digital Partner is Required").required(),
  }),

  // multiDeals: yup.array().when("bannerType", {
  //   is: (bannerType) => bannerType == "multiDeals",
  //   then: (schema) =>
  //     schema.min(1, "Multi Deals Required").label("MultiDeals").required(),
  // }),

  ids: yup.string().when("bannerType", {
    is: (bannerType) =>
      ["multiDeals", "multiBrands", "multiCategories"].indexOf(bannerType) !=
      -1,
    then: (schema, bannerType) =>
      schema
        .label(
          bannerType == "multiDeals"
            ? "Multi Deals "
            : bannerType == "multiBrands"
            ? "Multi Brands "
            : "Multi Categories " + "IDs"
        )
        .required(),
  }),

  enrollment: yup.string().when("bannerType", {
    is: (bannerType) => bannerType == "enrollment",
    then: (schema) => schema.trim().label("Enrollment").required(),
  }),

  filterByConditionType: yup.string().label("Apply Banner on Type").required(),

  filterBrand: yup.array().when("filterByConditionType", {
    is: (filterByConditionType) =>
      ["Brand", "Brand-Category"].indexOf(filterByConditionType) != -1,
    then: (schema) => schema.min(1, "Criteria Brand").required(),
  }),

  filterCategory: yup.array().when("filterByConditionType", {
    is: (filterByConditionType) =>
      ["Category", "Brand-Category", "Menu"].indexOf(filterByConditionType) !=
      -1,
    then: (schema) => schema.min(1, "Criteria Category").required(),
  }),

  filterDeal: yup.array().when("filterByConditionType", {
    is: (filterByConditionType) =>
      ["Deal"].indexOf(filterByConditionType) != -1,
    then: (schema) => schema.min(1, "Criteria Deal").required(),
  }),

  location: yup.array().label("Access Level"),
  ordering: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .max(100)
    .min(0.1)
    .label("Slider Order Value")
    .required(),
});

const defaultFormData = {
  title: "",
  status: "",
  displayType: "image",
  subType: "B2B",
  bannerType: "",
  image: "",
  mobileImage: "",
  activePeriod: "",
  shortDescription: "",
  bannerText: "",
  url: "",

  // time slots
  startTime1: [],
  endTime1: [],
  startTime2: [],
  endTime2: [],

  //banner type inputs
  deal: [],
  brand: [],
  category: [],
  marketPlaceSeller: [],
  kingsale: [],
  keyword: "",
  digitalPartner: [],
  multiDeals: [],
  enrollment: "",

  // Apllicable on

  filterByFranchiseType: "",
  filterByFranchiseGroupType: "",
  franchise: [],
  bannerMasterId: "",
  bannerTrackingCode: "",
  bannerMasterData: {},
  ordering: null,
};

const isBannerForceEditConfig = AuthService.isValidUserRole([
  "BannerForceEditConfig",
])
  ? true
  : false;

const ManageBannerSliderModal = ({ show, bannerId, subBannerId, callback }) => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormData,
  });

  // To show Spinner on Submit Button While Submitting Form
  const [submitting, setSubmitting] = useState(false);

  // Page Loader
  const [pageLoading, setPageLoading] = useState(false);

  const [details, setDetails] = useState({});

  const [selectedLocation, setSelectedLocation] = useState({
    loading: false,
    data: {},
  });

  const [canEdit, setCanEdit] = useState(true);

  const [data, setData] = useState({});

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const loadExcludedFranchises = useCallback(
    async (excludedFranchiseIds) => {
      if (!excludedFranchiseIds || excludedFranchiseIds.length === 0) return;

      try {
        const response = await FranchiseService.getFranchises({
          filter: { _id: { $in: excludedFranchiseIds } },
          select: "_id,name", // Add any other fields you need
        });

        if (Array.isArray(response?.resp)) {
          const formattedFranchises = response.resp.map((franchise) => ({
            label: `${franchise.name} (${franchise._id})`,
            value: franchise,
          }));

          methods.setValue("selectedFranchises", formattedFranchises);
        }
      } catch (error) {
        console.error("Error loading excluded franchises:", error);
        methods.setValue("selectedFranchises", []);
        // Handle error (e.g., show a toast notification)
      }
    },
    [methods]
  );

  useEffect(() => {
    if (show && bannerId && subBannerId) {
      loadData(bannerId);
      setCanEdit(
        isBannerForceEditConfig ? true : data?._canEdit == "yes" ? false : true
      );
    }
  }, [show, bannerId, subBannerId, setCanEdit]);

  const loadData = async (id) => {
    setPageLoading(true);
    const r = await BannerService.getBannerDetailsById(id);
    const d = r.resp || {};

    if (!d._id) {
      Toaster.error("Failed to fetch details, please try again");
      setPageLoading(false);
      closeModal();
      return;
    }
    let tmp = [];
    setAdditionalData([d], [...manageView.attachAdditionalDataConfig], (x) => {
      tmp.push(x);
      if (tmp.length == manageView.attachAdditionalDataConfig.length) {
        const a = [...attachAllData([d], tmp)][0];
        setDetails((v) => ({ ...v, ...a }));
      }
    });

    setDetails(d);
    let f = d.banners.find((e) => {
      return e._id == subBannerId;
    });
    setData(f?._id ? f : {});
    await updateForm(d, f);

    // Load excluded franchises
    if (f?.excludedFranchises && Array.isArray(f.excludedFranchises)) {
      await loadExcludedFranchises(f.excludedFranchises);
    }

    setPageLoading(false);
  };

  const loadBannerSizeList = async (id) => {
    setSelectedLocation({ loading: true, data: {} });
    let params = {
      filter: {
        _id: id,
      },
    };
    const r = await BannerService.getBannerDesignMasterList(params);
    setSelectedLocation({
      loading: false,
      data: Array.isArray(r.resp) ? r.resp[0] : [],
    });
  };

  const updateForm = (k, f) => {
    const ignoreFormKeys = [
      "validityPeriod",
      "status",
      "resolutionWidth",
      "banners",
      // "appType",
      // "filterByFranchiseType",
      // "filterByFranchiseGroupType",
      // "franchise",
    ];
    // methods.setValue("appType", data.appType)
    Object.keys(defaultFormData).forEach((key) => {
      if (
        ignoreFormKeys.indexOf(key) == -1 &&
        f &&
        typeof f[key] != "undefined"
      ) {
        methods.setValue(key, f[key]);
      }
      methods.setValue("status", f?.status == "Active");
    });

    if (f?.location?.length) {
      let franList = f.location.filter((e) => {
        return e.rmf || e.rf || e.sf;
      });

      let ids = franList.map((e) => {
        return e.rmf || e.rf || e.sf;
      });

      if (ids?.length) {
        loadFranchiseDetails(ids, f);
      }
    }

    let type = f.bannerType;

    if (["multiDeals", "multiCategories", "multiBrands"].indexOf(type) != -1) {
      let ids = f.bannerTypeNames[type].join(",");
      methods.setValue("ids", ids);
    }

    // Deals
    if (["deal"].indexOf(type) != -1) {
      let ids =
        type == "deal"
          ? [f?.bannerTypeNames?.deal]
          : f?.bannerTypeNames?.multiDeals;
      loadDealDetails(ids, f.bannerType);
    }

    // brand
    if (["brand", "brandAndCategory"].indexOf(type) != -1) {
      loadBrandDetails([f.bannerTypeNames?.brand], "brand");
    }

    // category
    if (["category", "brandAndCategory", "menu"].indexOf(type) != -1) {
      loadCategoryDetails([f.bannerTypeNames?.category], "category");
    }

    //Market Place Seller
    if (type == "marketPlaceSeller") {
      loadMarketPlaceSellerDetails([f.bannerTypeNames?.marketPlaceSeller]);
    }

    // recharge
    if (["recharge", "dmt", "wallet", "servicePartner"].indexOf(type) != -1) {
      let ids = [f?.bannerTypeNames[type]];
      loadDigitalPartnersDetails(ids, type);
    }

    // enrollment
    if (type == "enrollment") {
      methods.setValue("enrollment", f.bannerTypeNames.enrollmentType);
    }

    if (["keyword"].indexOf(type) != -1) {
      methods.setValue("keyword", f.bannerTypeNames.keyword);
    }

    if (f.filterByFranchiseType == "ByFranchise") {
      loadFranchise(f.filterByFranchiseId);
    }
    methods.setValue("filterByConditionType", f.filterByConditionType);

    // Criteria brand category,deal and filterByConditionType
    if (f?.filterByConditionType == "Brand") {
      loadBrandDetails(f?.filterByCondition, "filterBrand");
    } else if (["Menu", "Category"].indexOf(f?.filterByConditionType) != -1) {
      loadCategoryDetails(f?.filterByCondition, "filterCategory");
    } else if (f?.filterByConditionType == "Deal") {
      loadDealDetails(f?.filterByCondition, "filterDeal");
    } else if (f?.filterByConditionType == "Brand-Category") {
      let ids = f?.filterByCondition[0].split("-");
      loadBrandDetails([ids[0]], "filterBrand");
      loadCategoryDetails([ids[1]], "filterCategory");
    }
    if (f.bannerTrackingCode) {
      methods.setValue("bannerTrackingCode", f.bannerTrackingCode);
    }
    if (f?.bannerMasterId?.length) {
      loadBannerMasterData(f.bannerMasterId, "bannerMasterData");
    }
    methods.setValue("endDate", f?.period?.date?.endDate);
    methods.setValue("startDate", f?.period?.date?.startDate);
    methods.setValue("activePeriod", f?.allDayRange ? "all" : "range");

    // methods.setValue("image", f.image);
    // methods.setValue("mobileImage", f.mobileImage);
    methods.setValue("location", f.location);
    if (location.length) {
      methods.setValue("location", f.location);
    }
    if (k?.banners?.length && k?.banners[0].bannerMasterId) {
      loadBannerSizeList(k?.banners[0].bannerMasterId);
    }
    if (!f.allDayRange) {
      methods.setValue(
        "startTime1",
        f?.period?.timeRanges?.length > 0
          ? [new Date(f?.period?.timeRanges[0]?.startTime || "")]
          : ""
      );
      methods.setValue(
        "endTime1",
        f?.period?.timeRanges?.length > 0
          ? [new Date(f?.period?.timeRanges[0]?.endTime || "")]
          : ""
      );
      if (f?.period?.timeRanges.length > 1) {
        methods.setValue(
          "startTime2",
          [new Date(f?.period?.timeRanges[1]?.startTime) || ""] || ""
        );
        methods.setValue(
          "endTime2",
          [new Date(f?.period?.timeRanges[1]?.endTime) || ""] || ""
        );
      }
    }
  };

  const loadFranchise = useCallback(async (id) => {
    let m = await FranchiseService.getFranchise(id);
    let f = [];
    if (m.resp && m.resp._id) {
      f = [{ label: m.resp.name + " (" + m.resp._id + ")", value: m.resp }];
    }
    methods.setValue("franchise", f);
  }, []);

  const loadFranchiseDetails = useCallback(async (ids, d) => {
    let m = await FranchiseService.getFranchises({
      filter: { _id: { $in: ids } },
      select: "name,state,district,town,city",
    });

    if (Array.isArray(m?.resp)) {
      let list = [...d.location];
      list.forEach((e) => {
        m.resp.forEach((k) => {
          if ([e.rmf, e.rf, e.sf].indexOf(k._id) != -1) {
            if (e.rmf == k._id) {
              e.rmfDetails = k;
              e.rmfv = k._id;
            } else if (e.rf == k._id) {
              e.rfDetails = k;
              e.rfv = k._id;
            } else if (e.sf == k._id) {
              e.sfDetails = k;
              e.sfv = k._id;
            }
          }
        });
      });
      methods.setValue("location", list);
    }
  });

  const loadDealDetails = useCallback(async (ids, type) => {
    let m = await DealService.getDeals({ filter: { _id: { $in: ids } } });
    if (Array.isArray(m?.resp)) {
      if (type == "deal") {
        let x = [
          {
            label: m.resp[0]?.name + " (" + m.resp[0]?._id + ")",
            value: m.resp[0],
          },
        ];
        methods.setValue("deal", x);
      } else if (type == "filterDeal") {
        let x = [
          {
            label: m.resp[0]?.name + " (" + m.resp[0]?._id + ")",
            value: m.resp[0],
          },
        ];
        methods.setValue("filterDeal", x);
      } else {
        let x = m.resp.map((e) => {
          return { label: e.name, value: e };
        });
        methods.setValue("multiDeals", x);
      }
    }
  }, []);

  const loadBrandDetails = useCallback(
    async (ids, key) => {
      let m = await BrandService.getList({
        filter: { _id: { $in: ids } },
      });
      if (Array.isArray(m?.resp) && m.resp.length > 0) {
        let x = [
          {
            label: m.resp[0]?.name + " (" + m.resp[0]?._id + ")",
            value: m.resp[0],
          },
        ];
        methods.setValue(key, x);
        methods.trigger();
      }
    },
    [methods]
  );

  const loadCategoryDetails = useCallback(
    async (ids, key) => {
      let m = await CategoryService.getList({
        filter: { _id: { $in: ids } },
      });
      if (Array.isArray(m?.resp) && m.resp.length > 0) {
        let x = [
          {
            label: m.resp[0]?.name + " (" + m.resp[0]?._id + ")",
            value: m.resp[0],
          },
        ];
        methods.setValue(key, x);
        methods.trigger();
      }
    },
    [methods]
  );

  const loadBannerMasterData = useCallback(
    async (id, key) => {
      let m = await BannerService.getBannerDesignMasterById(id);
      if (m.resp?._id) {
        methods.setValue(key, m.resp);
        methods.trigger();
      }
    },
    [methods]
  );

  const loadMarketPlaceSellerDetails = useCallback(async (ids) => {
    let m = await SourcingService.getList({
      filter: { _id: { $in: ids } },
    });
    if (Array.isArray(m?.resp)) {
      let x = [{ label: m.resp[0]["name"], value: m.resp[0] }];
      methods.setValue("marketPlaceSeller", x);
      methods.trigger();
    }
  }, []);

  const loadDigitalPartnersDetails = useCallback(async (ids, type) => {
    let m = await DigitalPartnerService.getlist({
      filter: { _id: { $in: ids } },
    });

    if (Array.isArray(m?.resp) && m.resp.length > 0) {
      let x = [{ label: m.resp[0].name || "", value: m.resp[0] }];
      methods.setValue("digitalPartner", x);
    }
  }, []);

  const onSubmit = async (d) => {
    let p = prepareSliderData(d);

    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to proceed?",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }

    setSubmitting(true);

    let r = await BannerService.updateBanner(details._id, p);

    setSubmitting(false);

    if (r.statusCode != 200) {
      let error = AjaxService.parseError(r.resp);
      Toaster.error(error.msg || "Failed to update");
      return;
    }

    methods.reset();
    Toaster.success("Banner Details Updated Successfully");
    callback({ status: "success" });
  };

  const prepareSliderData = (data) => {
    const d = data;
    const md = details;
    let p = {
      _bannerType: getBannerType(d?.bannerType),
      bannerTrackingCode: data.bannerTrackingCode || "",
      bannerMasterId: d.bannerMasterId || "",
      image: d.bannerMasterData?.assetId,
      mobileImage: d.bannerMasterData?.assetId,
      filterByConditionType: d?.filterByConditionType || "",
      filterByFranchiseType: d.filterByFranchiseType,
      filterByFranchiseGroupType:
        d.filterByFranchiseType == "ByGroup"
          ? d.filterByFranchiseGroupType
          : "",
      filterByFranchiseId:
        d.filterByFranchiseType == "ByFranchise"
          ? d.franchise[0].value._id
          : "",
      accessLevel: "all",
      allDayRange: true,
      appType: ["SK"],
      bannerText: d?.bannerText || "",
      bannerType: d?.bannerType,
      bannerTypeNames: {},
      displayType: d?.displayType,
      isPartnerApp: false,
      period: {
        date: {
          startDate: new Date(d?.startDate),
          endDate: new Date(d?.endDate),
        },
        timeRanges: [
          {
            startTime:
              d?.startTime1?.length > 0
                ? new Date(d?.startTime1[0]).toISOString()
                : "",
            endTime:
              d?.endTime1?.length > 0
                ? new Date(d?.endTime1[0]).toISOString()
                : "",
          },
        ],
      },
      shortDescription: d?.shortDescription,
      subType: d?.subType,
      title: d?.title,
      url: d?.url || "",
      status: d?.status == "true" ? "Active" : "Inactive",
      ordering: d?.ordering,
      location: d?.location || [],
      isEdit: true,
      excludedFranchises: d.selectedFranchises
        ? d.selectedFranchises.map((franchise) => franchise.value._id)
        : [],
    };

    if (d?.startTime2?.length && d?.endTime2?.length) {
      p.period.timeRanges.push({
        startTime: new Date(
          d?.startTime2?.length ? d?.startTime2[0] : ""
        ).toISOString(),
        endTime: new Date(
          d?.endTime2?.length ? d?.endTime2[0] : ""
        ).toISOString(),
      });
    } else {
      p.period.timeRanges.splice(1, 1);
    }

    if (d?.bannerType == "deal") {
      p.bannerTypeNames["deal"] = d?.deal[0]?.value?._id;
      p.deal = d.deal;
    }

    if (["brand", "brandAndCategory"].indexOf(d?.bannerType) != -1) {
      p.bannerTypeNames["brand"] = d?.brand[0]?.value?._id;
      p.brand = d.brand;
    }

    if (["category", "brandAndCategory", "menu"].indexOf(d?.bannerType) != -1) {
      p.bannerTypeNames["category"] = d?.category[0]?.value?._id;
    }

    if (["marketPlaceSeller"].indexOf(d?.bannerType) != -1) {
      p.bannerTypeNames["marketPlaceSeller"] =
        d?.marketPlaceSeller[0]?.value?._id;
    }

    if (["kingsale"].indexOf(d?.bannerType) != -1) {
      p.bannerTypeNames["kingsale"] = d?.kingsale[0]?.value?._id;
    }

    if (["keyword"].indexOf(d?.bannerType) != -1) {
      p.bannerTypeNames["keyword"] = d?.keyword;
    }

    if (d?.bannerType == "multiDeals") {
      let idsList = d.ids.split(",");
      p.bannerTypeNames["multiDeals"] = idsList;
    }

    if (d?.bannerType == "multiCategories") {
      let idsList = d.ids.split(",");
      p.bannerTypeNames["multiCategories"] = idsList;
    }

    if (d?.bannerType == "multiBrands") {
      let idsList = d.ids.split(",");
      p.bannerTypeNames["multiBrands"] = idsList;
    }

    // Criteria Type

    if (d?.filterByConditionType == "Brand-Category") {
      let id =
        d?.filterBrand[0]?.value?._id + "-" + d?.filterCategory[0]?.value?._id;
      p.filterByCondition = [id];
    } else if (d?.filterByConditionType == "Brand") {
      p.filterByCondition = [d?.filterBrand[0]?.value?._id];
    } else if (["Menu", "Category"].indexOf(d?.filterByConditionType) != -1) {
      p.filterByCondition = [d?.filterCategory[0]?.value?._id];
    } else if (d?.filterByConditionType == "Deal") {
      p.filterByCondition = [d?.filterDeal[0]?.value?._id];
    }

    if (
      ["recharge", "dmt", "wallet", "servicePartner"].indexOf(d?.bannerType) !=
      -1
    ) {
      p.bannerTypeNames[d.bannerType] = d[d.bannerType];
    }

    p._bannerType = getBannerType(d.bannerType);

    (d?.period?.timeRanges || []).forEach((e) => {
      e.startTime = d.startTime || "";
      e.endTime = d.endTime || "";
    });

    if (d.filterByFranchiseType == "ByFranchise") {
      p.location = [
        {
          accessLevel: "sf",
          sf: d?.filterByFranchiseId || d?.franchise[0]?.value?._id || "",
          rmf: "",
          rf: "",
          state: "",
          district: "",
          town: "",
          rmfType: "",
          rfType: "",
          sfType: "",
        },
      ];
    } else if (d.filterByFranchiseType == "ByGroup") {
      p.location = [{ accessLevel: "all" }];
    } else {
      p.location = p.location.map((e) => {
        if (e.accessLevel == "rmf") {
          e.rmf = e.rmfv;
        } else {
          e.rmf = "";
        }
        if (e.accessLevel == "rf") {
          e.rf = e.rfv;
        } else {
          e.rf = "";
        }
        if (e.accessLevel == "sf") {
          e.sf = e.sfv;
        } else {
          e.sf = "";
        }

        delete e.rmfv;
        delete e.rmfDetails;
        delete e.rfv;
        delete e.rfDetails;
        delete e.sfv;
        delete e.sfDetails;
        e.customer = "";
        return e;
      });
    }
    let params = {
      platformType: md.platformType,
      bannerLocationCode: md.bannerLocationCode || md.page + "::" + md.location,
      applicableFor: md.applicableFor,
      banners: [p],
      maxImageSize: selectedLocation?.assetAttributes?.maxSizeBytes,
      resolution: { height: md.resolutionHeight, width: md.resolutionWidth },
      sliderTime: selectedLocation?.assetAttributes?.slideTimeSecs || 0,
      resolution: {
        height: selectedLocation?.assetAttributes?.heightPxl,
        width: selectedLocation?.assetAttributes?.widthPxl,
      },
      // title: md.title,
      page: md.page,
      location: md.locationType,
    };
    return params;
  };

  const getBannerType = (key) => {
    let d = manageView.bannerTypeOptions.filter((e) => {
      return e.value == key;
    });
    return d.length ? d[0]?.label || "" : "";
  };

  // To Close Filter Modal
  const closeModal = () => {
    callback({ status: "closed" });
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-light">
          <Offcanvas.Title>
            <div className="fs-val-lg">
              Edit Banner Details- #{details?._id}{" "}
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-3 border-top">
          {pageLoading ? (
            <PageLoader />
          ) : (
            <FormProvider {...methods}>
              <form autoComplete="off">
                <div className="mb-3">
                  <AppCard title="Banner Details">
                    {selectedLocation.loading ? (
                      <Spinner />
                    ) : (
                      <BannerDetails
                        selectedLocation={selectedLocation.data}
                        canEdit={canEdit}
                      />
                    )}
                  </AppCard>
                </div>
                {/* Submit Button */}
                <div className="text-end py-3">
                  <button
                    className="btn btn-primary"
                    // type="submit"
                    onClick={methods.handleSubmit(onSubmit)}
                    disabled={submitting}
                  >
                    Submit {submitting ? <Spinner isSmall={true} /> : null}
                  </button>
                </div>
              </form>
            </FormProvider>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(ManageBannerSliderModal);
