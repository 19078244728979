import {
  FranchiseService,
  UtilityService,
  RolesService,
  appConfigs,
} from "@sk/services";
import { AppCard, SelectInput, Spinner, TextInput } from "@sk/uis";
import produce from "immer";
import { debounce, orderBy } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useForm } from "react-hook-form";

const offcanvasStyle = {
  width: "40%",
};

const groupTypeOptions = [
  { label: "COCO", value: "COCO" },
  { label: "FOCO", value: "FOCO" },
];

const getData = async (params) => {
  const response = await FranchiseService.getFranchises(params);
  return {
    data: (Array.isArray(response.resp) ? response.resp : []).map((x) => ({
      ...x,
      _checked: false,
    })),
  };
};

const getCount = async (params) => {
  let p = { ...params };
  delete p.page;
  delete p.count;
  const response = await FranchiseService.getCount(p);
  return response.resp || 0;
};

const prepareFilterParams = (
  filter,
  pagination,
  onlyAssignedStore,
  accessibleStoreIds
) => {
  const params = {
    page: pagination.page,
    count: pagination.count,
    filter: {
      groupType: filter.groupType,
      // "sk_franchise_details.franchise_sub_type": {
      //   $in: appConfigs.DARKSTORE_SUB_TYPES,
      // },
    },
    sort: { name: 1 },
  };

  if (filter.searchStoreName) {
    params.filter.name = { $regex: filter.searchStoreName, $options: "i" };
  }

  if (filter.subType && filter.subType !== "all") {
    params.filter["sk_franchise_details.franchise_sub_type"] = filter.subType;
  }

  if (onlyAssignedStore) {
    params.filter._id = { $in: accessibleStoreIds };
  }

  if (filter.state) params.filter.state = filter.state;
  if (filter.district) params.filter.district = filter.district;
  if (filter.town) params.filter.town = filter.town;

  if (Object.keys(params.filter).length === 0) delete params.filter;
  return params;
};

const mapSelectedStores = (selectedStoresIds, stores) => {
  return stores.map((store) => ({
    ...store,
    _checked: (selectedStoresIds || []).includes(store._id),
  }));
};

const StoresModal = ({
  show,
  callback,
  multiSelect = false,
  selectedStoresIds,
  onlyAssignedStore = false,
  rbackKey = "",
}) => {
  const { register, getValues, setValue } = useForm({
    defaultValues: {
      state: "",
      district: "",
      town: "",
      groupType: "COCO",
      subType: appConfigs.DARKSTORE_SUB_TYPES[0],
    },
  });

  const [states, setStates] = useState({
    loading: false,
    data: [{ label: "All States", value: "" }],
  });
  const [districts, setDistricts] = useState({
    loading: false,
    data: [{ label: "All Districts", value: "" }],
  });
  const [towns, setTowns] = useState({
    loading: false,
    data: [{ label: "All Towns", value: "" }],
  });
  const [stores, setStores] = useState({ loading: false, data: [] });
  const [loadingMore, setLoadingMore] = useState(false);
  const [showLoaderMore, setShowLoaderMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [loadingAssignedStores, setLoadingAssignedStores] = useState(false); // New loading state for assigned stores
  const [subTypeOptions, setSubTypeOptions] = useState([]);

  const allowedLocationsRefs = useRef({
    state: [],
    district: [],
    town: [],
  });

  const paginationRef = useRef({ page: 1, count: 20 });
  const assignedStoreIdsRef = useRef([]); // New ref to store assigned store IDs

  const applyFilter = useCallback(
    async (selectedIds) => {
      paginationRef.current.page = 1;
      const params = prepareFilterParams(
        getValues(),
        paginationRef.current,
        onlyAssignedStore,
        assignedStoreIdsRef.current
      ); // Pass onlyAssignedStore
      setTotalCount(await getCount(params));
      setStores({ loading: true });
      const r = await getData(params);
      setStores(
        produce((draft) => {
          draft.loading = false;
          draft.data = mapSelectedStores(selectedIds, r.data);
        })
      );
      setShowLoaderMore(r.data.length >= paginationRef.current.count);
    },
    [getValues, onlyAssignedStore, assignedStoreIdsRef]
  );

  useEffect(() => {
    if (show) {
      setSelectAll(false);
      setValue("state", "");
      setValue("district", "");
      setValue("town", "");
      setValue("groupType", "COCO");
      setValue("subType", "");

      const fetchData = async () => {
        if (onlyAssignedStore) {
          setLoadingAssignedStores(true); // Set loading state to true
          const { stores } = await RolesService.getDashboardEnabledStores(
            rbackKey.split(",")
          );
          assignedStoreIdsRef.current = stores; // Store the assigned store IDs in the ref

          const franResp = await FranchiseService.getFranchises({
            filter: {
              _id: { $in: assignedStoreIdsRef.current }, // Use ref to access assigned store IDs
            },
            select: "state,district,town",
          });
          setLoadingAssignedStores(false); // Set loading state to false after fetching
          if (Array.isArray(franResp.resp) && franResp.resp.length > 0) {
            franResp.resp.forEach((x) => {
              allowedLocationsRefs.current.state.push(x.state);
              allowedLocationsRefs.current.district.push(x.district);
              allowedLocationsRefs.current.town.push(x.town);
            });
          }
        }

        const sfSubTypesResponse = await FranchiseService.getSfSubTypes();
        if (Array.isArray(sfSubTypesResponse.resp)) {
          setSubTypeOptions([
            { label: "All", value: "" },
            ...orderBy(sfSubTypesResponse.resp, ["name"], ["asc"]).map((x) => ({
              label: x.name,
              value: x.name,
            })),
          ]);
        }

        setStates({ loading: true });
        let params = {};
        if (allowedLocationsRefs.current.state.length > 0) {
          params.filter = {
            name: { $in: allowedLocationsRefs.current.state },
          };
        }
        const response = await UtilityService.getStateList(params);
        setStates({
          loading: false,
          data: [
            { label: "All States", value: "" },
            ...response.resp.map((x) => ({ label: x.name, value: x.name })),
          ],
        });

        applyFilter(selectedStoresIds);
      };
      fetchData();
    }
  }, [
    applyFilter,
    setValue,
    show,
    onlyAssignedStore,
    rbackKey,
    selectedStoresIds,
  ]);

  const handleStateChange = async (e) => {
    if (!e) {
      setDistricts({
        loading: false,
        data: [{ label: "All Districts", value: "" }],
      });
      setTowns({ loading: false, data: [{ label: "All Towns", value: "" }] });
      setValue("district", ""); // Clear district value
      setValue("town", ""); // Clear town value
      applyFilter(selectedStoresIds);
      return;
    }

    let params = {};
    if (allowedLocationsRefs.current.district.length > 0) {
      params.filter = {
        name: { $in: allowedLocationsRefs.current.district },
      };
    }

    setDistricts({ loading: true, data: [] }); // Clear districts
    const response = await UtilityService.getDistrictList(e, params);
    setDistricts({
      loading: false,
      data: [
        { label: "All Districts", value: "" },
        ...response.resp.map((x) => ({ label: x.name, value: x.name })),
      ],
    });
    setTowns({ loading: false, data: [{ label: "All Towns", value: "" }] }); // Clear towns when state changes
    setValue("district", ""); // Clear district value
    setValue("town", ""); // Clear town value
    applyFilter(selectedStoresIds);
  };

  const handleDistrictChange = async (e) => {
    if (!e) {
      setTowns({ loading: false, data: [{ label: "All Towns", value: "" }] }); // Clear towns
      setValue("town", ""); // Clear town value
      applyFilter(selectedStoresIds);
      return;
    }

    let params = {};
    if (allowedLocationsRefs.current.town.length > 0) {
      params.filter = {
        name: { $in: allowedLocationsRefs.current.town },
      };
    }

    setTowns({ loading: true });
    const response = await UtilityService.getTownList(
      getValues("state"),
      e,
      params
    );
    setTowns({
      loading: false,
      data: [
        { label: "All Towns", value: "" },
        ...response.resp
          ?.filter((x) => {
            if (allowedLocationsRefs.current.town.length > 0) {
              return allowedLocationsRefs.current.town.includes(x.name);
            }
            return true;
          })
          .map((x) => ({ label: x.name, value: x.name })),
      ],
    });
    setValue("town", ""); // Clear town value
    applyFilter(selectedStoresIds);
  };

  const handleTownChange = () => {
    applyFilter(selectedStoresIds);
  };

  const handleLoadMore = async () => {
    setLoadingMore(true);
    setShowLoaderMore(true);
    paginationRef.current.page += 1; // Increment the page number for pagination
    const params = prepareFilterParams(
      getValues(),
      paginationRef.current,
      onlyAssignedStore,
      assignedStoreIdsRef.current
    ); // Pass onlyAssignedStore
    const r = await getData(params);
    setStores(
      produce((draft) => {
        draft.loading = false;
        draft.data.push(
          ...mapSelectedStores(selectedStoresIds, r.data).map((store) => ({
            ...store,
            _checked: selectAll ? true : store._checked, // Check all if selectAll is true
          }))
        ); // Append new data to existing data
      })
    );
    setLoadingMore(false);
    setShowLoaderMore(r.data.length >= paginationRef.current.count);
  };

  const handleStoreClick = (index) => {
    if (multiSelect) {
      setStores(
        produce((draft) => {
          draft.data[index]._checked = !draft.data[index]._checked;
        })
      );
    } else {
      callback({
        action: "selectedStore",
        store: multiSelect
          ? stores.data.filter((x) => x._checked)
          : [stores.data[index]],
      });
    }
  };

  const handleSubmit = () => {
    const selectedStores = multiSelect
      ? stores.data.filter((x) => x._checked)
      : [];
    callback({
      action: "selectedStore",
      store: selectedStores,
    });
  };

  const handleSelectAll = (e) => {
    setSelectAll(e.target.checked);
    setStores(
      produce((draft) => {
        draft.data.forEach((store) => (store._checked = e.target.checked));
      })
    );
  };

  const handleSearchStoreNameChange = useCallback(
    debounce(() => {
      applyFilter(selectedStoresIds);
    }, 800),
    [selectedStoresIds]
  );

  const handleGroupTypeChange = () => {
    applyFilter(selectedStoresIds);
  };

  const handleSubTypeChange = () => {
    applyFilter(selectedStoresIds);
  };

  return (
    <Offcanvas
      show={show}
      onHide={callback}
      placement="end"
      style={offcanvasStyle}
    >
      {/* Show loading state for assigned stores */}
      {loadingAssignedStores && <Spinner isSmall />}
      <Offcanvas.Header closeButton className="bg-white">
        <Offcanvas.Title>Choose Store</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modal-bg">
        <AppCard>
          <div className="row">
            <div className="col-4">
              <SelectInput
                label="State"
                options={states.data}
                register={register}
                name="state"
                callback={handleStateChange}
              />
            </div>
            <div className="col-4">
              <SelectInput
                label="District"
                options={districts.data}
                register={register}
                name="district"
                callback={handleDistrictChange}
              />
            </div>
            <div className="col-4">
              <SelectInput
                label="Town"
                options={towns.data}
                register={register}
                name="town"
                callback={handleTownChange}
              />
            </div>
            <div className="col-4">
              <SelectInput
                register={register}
                name="groupType"
                label="Group Type"
                options={groupTypeOptions}
                callback={handleGroupTypeChange}
              />
            </div>
            <div className="col-4">
              <SelectInput
                register={register}
                name="subType"
                label="Sub Type"
                options={subTypeOptions}
                callback={handleSubTypeChange}
              />
            </div>
          </div>
        </AppCard>

        {/* New Div for displaying store details */}
        <AppCard
          title={
            <div className="row">
              <div className="col">Stores ({totalCount})</div>
              {multiSelect && (
                <div className="col-auto">
                  <input
                    type="checkbox"
                    className="me-2 align-middle"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                  <span className="align-middle fs-val-sm">Select All</span>
                </div>
              )}
            </div>
          }
        >
          <div className="store-details">
            <div>
              <TextInput
                type="text"
                register={register}
                name="searchStoreName"
                placeholder="Enter store name to search"
                isMandatory={false}
                callback={handleSearchStoreNameChange}
              />
            </div>

            {!stores.loading && stores?.data?.length === 0 && (
              <div className="text-center mt-3">No stores available.</div>
            )}
            {stores.loading && (
              <div className="text-center">
                <Spinner isSmall />
              </div>
            )}
            {stores?.data?.map((store, index) => (
              <div
                key={index}
                className="store-item d-flex border-bottom py-2 cursor-pointer"
                onClick={() => handleStoreClick(index)}
              >
                {multiSelect && (
                  <input
                    type="checkbox"
                    className="align-self-start me-2 mt-1"
                    checked={store._checked}
                    onChange={() => {}}
                  />
                )}
                <div className="flex-grow-1">
                  <span className="fs-val-md fw-semibold">
                    {index + 1}. {store.name}
                  </span>
                  <div className="fs-val-sm text-muted">
                    <div className="mb-1">
                      <span>{store.town}</span>, <span>{store.district}</span>,{" "}
                      <span>{store.state}</span>, <span>{store.pincode}</span>
                    </div>
                    <div>
                      <span className="badge badge-soft-primary me-2">
                        {store.sk_franchise_details.franchise_sub_type}
                      </span>
                      <span className="badge badge-soft-primary">
                        {store._id}
                      </span>
                    </div>
                  </div>
                </div>
                <i className="bi bi-chevron-right align-self-center"></i>
              </div>
            ))}
          </div>

          {/* Load More Button */}
          {showLoaderMore && (
            <div className="text-center mt-3">
              <button
                className="btn btn-sm btn-light"
                onClick={handleLoadMore}
                disabled={loadingMore}
              >
                {loadingMore
                  ? "Loading..."
                  : `Load More (${stores.data?.length} / ${totalCount})`}
              </button>
            </div>
          )}
        </AppCard>
      </Offcanvas.Body>
      {multiSelect && (
        <div className="text-end p-2">
          <button className="btn btn-primary" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      )}
    </Offcanvas>
  );
};

export default StoresModal;
