import { CommonService } from "@sk/services";
import format from "date-fns/format";

const doPrint = (data = {}) => {
  let fAddr = "";

  fAddr += data.franchise.address.door_no || "";
  fAddr += data.franchise.address.full_address || "";
  fAddr +=
    data.franchise.town +
    ", " +
    data.franchise.district +
    ", " +
    data.franchise.state +
    " - " +
    data.franchise.pincode;

  const billToAddress = data?.billTo?.address?.full_address;
  const billToGst = data?.billTo?.gstNo || "N/A";

  const shipToAddress = data?.shipTo?.address?.full_address;
  const shipToGst = data?.shipTo?.gstNo || "N/A";

  const poDate = format(new Date(data.createdAt), "dd MMM yyyy hh:mm a");

  let totalVal = 0;

  // const printDate = format(new Date(), "dd MMM yyyy hh:mm a");

  // const invDetails = data.invoiceDetails[0] || {};

  // const invDt = invDetails.invoiceDate
  //   ? format(new Date(invDetails.invoiceDate), "dd MMM yyyy, hh:mm a")
  //   : "";

  let items = "";

  let productList = [];

  if (data.status == "Draft" || data.status == "Approval Pending") {
    productList = data.productList.filter((x) => x.status != "Rejected");
  } else {
    productList = data.requestProductList?.map((x) => {
      const curProduct = data.productList.find((p) => p.pid == x.pid);
      return {
        ...x,
        receivedQuantity: curProduct?.receivedQuantity,
        status: curProduct.status,
        auditLog: curProduct.auditLog,
        _displayUnitType: curProduct._displayUnitType,
      };
    });
  }
  (productList || []).forEach((x) => {
    const mrp = CommonService.roundedByDecimalPlace(x.mrp, 2);
    const price = CommonService.roundedByDecimalPlace(x.price, 2);
    const discount = CommonService.roundedByDecimalPlace(x.discount || 0, 2);
    const qty = x.requestedQuantity; // x.receivedQuantity || x.requestQuantity;
    const tax = x.taxInfo.gst || 0;
    const total = CommonService.roundedByDecimalPlace(x.totalValue, 2); // old value = x.price * qty
    totalVal += total;
    items += `
  <tr>
    <td class="text-center" style="border: 1px solid rgba(128, 128, 128, 0.2);">
      <div class="pad-5">
        <span class="font-16">${x.hsn}</span>
      </div>
    </td>
    <td class="text-center" style="border: 1px solid rgba(128, 128, 128, 0.2);">
      <div class="pad-5">
        <span class="font-16">${x.dealId}</span>
      </div>
    </td>
    <td class="text-center" style="border: 1px solid rgba(128, 128, 128, 0.2);">
      <div class="pad-5">
        <span class="font-16">${x.name}</span>
      </div>
    </td>
    
    <td class="text-center" style="border: 1px solid rgba(128, 128, 128, 0.2);">
      <div class="pad-5">
        <span class="font-16">${mrp}</span>
      </div>
    </td>
    <td class="text-center" style="border: 1px solid rgba(128, 128, 128, 0.2);">
      <div class="pad-5">
        <span class="font-16">${price}</span>
      </div>
    </td>
    <td class="text-left" style="border: 1px solid rgba(128, 128, 128, 0.2);">
      <div class="pad-5">
        <span class="text-right font-12">${discount}%</span>
      </div>
    </td>
    <td class="text-center" style="border: 1px solid rgba(128, 128, 128, 0.2);">
      <div class="pad-5">
        <span class="font-16">${qty} ${x._displayUnitType}</span>
      </div>
    </td>
    <td class="text-center" style="border: 1px solid rgba(128, 128, 128, 0.2);">
      <div class="pad-5">            
        <span class="font-16">${tax}</span>
      </div>          
    </td>
    <td class="text-center" style="border: 1px solid rgba(128, 128, 128, 0.2);">
      <div class="pad-5">
        <span class="font-16">
          Rs.${total}
        </span>
      </div>
    </td>
  </tr>
  `;
  });

  let template = `
<!DOCTYPE html>
<html>

<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width">
  <title>POS Purchase Order</title>
  <link href="https://fonts.googleapis.com/css?family=Poppins:400,500,700" rel="stylesheet">
  <style>
    #template {
      background: #FFF;
      padding: 4%;
    }

    #bg-text {
      font-size: 100px;
      transform: rotate(333deg);
      -webkit-transform: rotate(342deg);
      opacity: 0.2;

    }

    * {
      font-family: "poppins";
    }

    table {
      border-spacing: 0px;
      break-inside: auto;
    }

    tr {
      break-before: always;
      break-inside: avoid;
      break-after: always;
    }

    td {
      break-before: always;
      break-inside: avoid;
      break-after: always;
    }

    thead {
      display: table-header-group;

    }

    .pad-10 {
      padding: 10px;
    }

    .display-table {
      display: table;
    }

    .display-table-cell {
      display: table-cell;
    }

    .width-100 {
      width: 100%;
    }

    .rupeesSymbol {
      font-family: Arial, Helvetica, sans-serif;
    }

    .dateStyle {
      font-family: sans-serif;
    }

    .bg-blue {
      background-color: #272861;
    }

    .bg-red {
      background-color: #e26639;
    }

    .bg-white {
      background-color: #ffffff;
    }

    .bg-silver {
      background-color: #f0f1f1;
    }

    .grey-outline {
      outline: 1px solid rgba(128, 128, 128, 0.2);
    }

    .width-20 {
      width: 20%;
    }

    .width-30 {
      width: 30%;
    }

    .width-40 {
      width: 40%;
    }

    .width-50 {
      width: 50%;
    }

    .width-60 {
      width: 60%;
    }

    .width-70 {
      width: 70%;
    }

    .pad-5 {
      padding: 5px;
    }

    .pad-20 {
      padding: 20px;
    }

    .text-center {
      text-align: center;
    }

    .text-right {
      text-align: right;
    }

    .text-left {
      text-align: left;
    }

    .font-25 {
      font-size: 25px;
    }

    .font-16 {
      font-size: 12px;
    }

    .font-14 {
      font-size: 14px;
    }

    .font-12 {
      font-size: 12px;
    }

    .font-18 {
      font-size: 18px;
    }

    .font-20 {
      font-size: 20px;
    }

    .font-bold {
      font-weight: 600;
    }

    .m-t-10 {
      margin-top: 10px;
    }

    .m-l-20 {
      margin-left: 20px;
    }

    .p-b-0 {
      padding-bottom: 0px;
    }

    .p-t-0 {
      padding-top: 0px;
    }

    .p-l-0 {
      padding-left: 0px;
    }

    .p-r-0 {
      padding-right: 0px;
    }

    .p-b-5 {
      padding-bottom: 5px;
    }

    .p-t-10 {
      padding-top: 10px;
    }

    .p-b-10 {
      padding-bottom: 10px;
    }

    .p-l-10 {
      padding-left: 10px;
    }

    .p-l-20 {
      padding-left: 20px;
    }

    .m-b-2 {
      margin-bottom: 8px;
    }

    .m-b-4 {
      margin-bottom:16px;
    }
    
    .m-r-1{
      margin-right:4px;
    }

    .showinprint {
      display: none;
    }

    @media print {

      .cancelled_invoice_text {
        font-size: 800% !important;
      }
    }
  </style>

</head>

<body>
  <div>

    <div id="template">

      <table width="100%" style="border-bottom:1px solid white;background-color: #e9e7ce;">
        <tr>
          <td class="pad-10 grey-outline" width="100%" align="center">
            <div style="width:70%">
              <h3 style="vertical-align: middle;width:100%;">Purchase Order</h3>
            </div>
          </td>
        </tr>
      </table>

      <table width="100%">
        <tr>
          <td class="text-center grey-outline pad-10" width="33%">
            <div class="pad-10">
              <div class="font-14">
                <span class="color-grey">PO Number :
                  <span class="color-black font-bold">
                    ${data._id}
                  </span>
                </span>
              </div>
            </div>
          </td>
          <td class="text-center grey-outline pad-10" width="33%">
            <div class="pad-10">
              <div class="font-14 p-l-0 p-r-0" style="justify-content: center;">
                <span class="color-grey">PO Date :</span>
                <span style="display: inline-block;" class="color-black font-bold">
                  &nbsp;&nbsp;&nbsp;&nbsp; ${poDate}
                </span>

              </div>
            </div>
          </td>    
          
        </tr>
      </table>
      

    <table width="100%">
      <tr>
          <td width="50%" class="grey-outline font-14" style="vertical-align:top";  >
              <div class="font-bold">Billing To</div>
              <div class="m-b-4" >${data.billTo.name}<div>
              <div class="font-bold m-b-2">Billing Address</div>
              <div class="m-b-4" >${billToAddress}<div>
              <div> <span class="m-r-1 font-bold"> GST No: </span> <span class="m-r-1 font-bold">${billToGst}</span>  </div>
          </td>
          <td width="50%" class="font-14 grey-outline" style="vertical-align:top";>
              <div class="font-bold">Ship To</div>
              <div class="m-b-4" >${data.shipTo.name}<div>
              <div class="font-bold m-b-2">Shipping Address</div>
              <div class="m-b-4" >${shipToAddress}<div>
              <div> <span class="m-r-1 font-bold"> GST No: </span> <span class="m-r-1 font-bold">${shipToGst}</span>  </div>
          </td>
      </tr>
      <tr>
          <td width="50%" class="grey-outline font-14" style="vertical-align:top";  >
              <div class="font-bold">Vendor Name:</div>
              <div class="m-b-4" >${data.vendorDetails?.name}<div>
              <div> <span class="m-r-1 font-bold"> GST No: </span> <span class="m-r-1 font-bold">${
                data.vendorDetails.gstNo || "N/A"
              }</span>  </div>
          </td>
          <td width="50%" class="font-14 grey-outline" style="vertical-align:top";>
              <div class="font-bold">Vendor Address</div>
              <div class="m-b-4" >${data.vendorDetails?.address?.line1} ${
    data.vendorDetails?.address?.line2
  }
  <div>Mobile : ${data?.vendorDetails?.mobile || "N/A"}</div>
  <div>
          </td>
      </tr>
    </table>


      
      <table width="100%" style="margin-top:10px;">
        <thead>
          <tr style="background-color: #bdb766; height: 40px; line-height: 1.2;">
            <th class="font-16 text-center grey-outline">HSN Number</th>
            <th class="font-16 p-l-10 text-center grey-outline">Deal Id</th>
            <th class="font-16 p-l-10 text-center grey-outline">Deal Name</th>
            <th class="font-16 text-center grey-outline">MRP <span class="rupeesSymbol font-12">(Rs.)</span></th>
            <th class="font-16 text-center grey-outline">Price <span class="rupeesSymbol font-12">(Rs.)</span></th>
            <th class="font-16 text-center grey-outline">Margin</th>    
            <th class="font-16 text-center grey-outline">PO Qty</th>
            <th class="font-16 text-center grey-outline">Tax <span class="rupeesSymbol font-12">(%)</span></th>
            <th class="font-16 text-center grey-outline">Total <span class="rupeesSymbol font-12">(Rs.)</span></th>
          </tr>
        </thead>
        <tbody>
          ${items}
        </tbody>
        <tr class="grey-outline" style="border-bottom:1px solid white; page-break-inside: avoid;">
          ${
            productList.length
              ? `<td class="pad-5 p-t-0 p-b-0" style="line-height: 1.8;" colspan="6">
            <div style="width: 100%;">
              <span class="font-16 color-grey font-bold">Total Products: ${productList.length}</span>
            </div>
          </td>

          <td class="pad-5 p-t-0 p-b-0" style="line-height: 1.8;" colspan="2">
            
          </td>

          <td class="pad-5 p-t-0 p-b-0" style="line-height: 1.8;text-align:center;" colspan="1">
            <span class="font-16 color-grey p-t-0 p-b-0">
              <span class="font-bold">
                Rs.${totalVal}
              </span>
            </span>
          </td>`
              : `<td class="text-center font-16 color-grey font-bold text-center pad-10" colspan="9" > No Products found </td>`
          }
        </tr>
      </table>

      <table width="100%" style="page-break-inside: avoid;">
        <tr>
          <td class="pad-10 grey-outline" style="border-top:1px solid white;border-bottom:1px solid white;"
            width="100%">
            <small class="text-muted"><b> *Please Note: Tax already included in price.*</b></small>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <script>
    window.addEventListener('load', function(){          
      const t = setTimeout(function(){window.print(); clearTimeout(t);},  800)
    })
  </script>
</body>    
</html>
`;

  const w = window.open("", "SK-POS");
  w.document.write(template);
  w.document.close();
  w.focus();
  const timer = setTimeout(() => {
    clearTimeout(timer);
    // w.window.close();
  }, 2000);
};

export default { doPrint };
