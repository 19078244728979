import {
  Amount,
  DateFormatter,
  HighlightText,
  KeyVal,
  InfoPopover,
} from "@sk/uis";
import { useMemo } from "react";

const PosOrderBasic = ({ order, callback }) => {
  // Function to generate the breakdown content
  const breakdownTemplate = useMemo(() => {
    return [
      {
        label: "Order Value",
        value: order.orderValue || 0,
        isAmount: true,
        type: "value",
      },
      {
        label: "Shipping Charges",
        value: order.shippingCharges,
        isAmount: true,
        type: "charge",
      },
      {
        label: "Coupon Code",
        value: order.couponCode || "",
        isAmount: false,
        type: "coupon",
      },
      {
        label: "Coupon Discount",
        value:
          order.couponValue && order.couponType === "Amount"
            ? order.couponValue
            : 0,
        isAmount: true,
        type: "discount",
      },
      {
        label: "SmartCoins Redeemed",
        value: order.smartCoinsRedeemed,
        isAmount: true,
        type: "redemption",
      },
      {
        label: "Final Amount",
        value: order._payableAmt || 0,
        isAmount: true,
        type: "total",
      },
    ].filter((item) => item.value);
  }, [order]);

  return (
    <>
      {!order.customerInfo.isGuestCustomer ? (
        <>
          <KeyVal label="Customer Name" labelCol="col-5" contentCol="col-7">
            :{" "}
            <button
              className="btn btn-link p-0 fs-val-md text-dark"
              onClick={() =>
                callback({ action: "viewCustomer", id: order.customerInfo?.id })
              }
            >
              {order.customerInfo?.name}
            </button>
          </KeyVal>
          <KeyVal label="Customer ID" labelCol="col-5" contentCol="col-7">
            : {order.customerInfo?.id}
          </KeyVal>
        </>
      ) : null}
      <KeyVal label="Status" labelCol="col-5" contentCol="col-7">
        :{" "}
        <HighlightText type={order.statusType} template={2}>
          {order.displayStatus}
        </HighlightText>
      </KeyVal>
      <KeyVal label="Order ID" labelCol="col-5" contentCol="col-7">
        : {order._id}
      </KeyVal>
      <KeyVal label="Total Amount" labelCol="col-5" contentCol="col-7">
        :{" "}
        <span className="text-primary fw-semibold">
          <Amount value={order._payableAmt} />
          {breakdownTemplate.length > 1 && (
            <InfoPopover
              content={
                <div className="d-flex flex-column gap-2">
                  {breakdownTemplate.map((item) => (
                    <KeyVal
                      key={item.label}
                      label={item.label}
                      template="col"
                      fwSize="sm"
                    >
                      {item.isAmount ? (
                        <Amount value={item.value} />
                      ) : (
                        item.value
                      )}
                    </KeyVal>
                  ))}
                </div>
              }
              placement="right"
              className="ms-1"
              size="sm"
            >
              <span className="text-dark text-muted text-decoration-underline fs-val-xs">
                See Details
              </span>
            </InfoPopover>
          )}
        </span>
      </KeyVal>
      <KeyVal label="Ordered On" labelCol="col-5" contentCol="col-7">
        : <DateFormatter date={order.createdAt} />
      </KeyVal>
      <KeyVal label="Payment Method" labelCol="col-5" contentCol="col-7">
        : {order.amountReceivedVia || "N/A"}
      </KeyVal>
    </>
  );
};

export default PosOrderBasic;
