import { Amount, CheckboxInput, KeyVal, TableHeader } from "@sk/uis";
import { memo } from "react";

const headers = [
  { label: "Sl No", width: "auto", isCentered: true },
  { label: "Event", isCentered: true },
  { label: "Template", isCentered: true, width: "25%" },
  { label: "Notification Details", isCentered: true, width: "40%" },
  // { label: "Conversions", isCentered: true },
  { label: "Actions", isCentered: true },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  editCb,
  viewCb,
}) => {
  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader data={headers} />
        <tbody className="fs-val-md">
          {/* Table Loader */}
          {/* {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )} */}

          {/* No Data */}
          {/* {!loading && !data?.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Data Found</NoDataFound>
              </td>
            </tr>
          ) : null} */}

          {/* List  */}
          {[1, 2, 3].map((x, index) => (
            <tr key={index}>
              {/* SL No */}
              <td className="text-center">{1 + index}</td>

              {/* Event*/}
              <td>
                <div>Customer Visit first time </div>
              </td>

              {/* Template */}
              <td>
                <div className="mb-2 text-center text-primary">
                  Revisit Invitation (TEMP1234)
                </div>

                <div className="row">
                  <div className="col-4 text-center">
                    <div className="text-primary">
                      <i className="bi bi-check-square-fill text-primary align-middle me-2 fs-val-md"></i>
                    </div>
                    SMS
                  </div>
                  <div className="col-4 text-center">
                    <div>
                      <i className="bi bi-check-square-fill text-primary align-middle me-2 fs-val-md"></i>
                    </div>
                    WhatsApp
                  </div>
                  <div className="col-4 text-center">
                    <div>
                      <i className="bi bi-app  align-middle me-2 fs-val-md opacity-25"></i>
                    </div>
                    Email
                  </div>
                </div>
              </td>

              <td>
                <div className="row">
                  <div className="col-3 text-center pt-3">SMS </div>
                  <div className="col-2 mb-4">
                    <div className="fs-val-sm text-muted mt-2">Sent</div>
                    <div>100</div>
                  </div>
                  <div className="col-2 mb-4">
                    <div className="fs-val-sm text-muted mt-2">Rejected</div>
                    <div>30</div>
                  </div>
                  <div className="col-2 mb-4">
                    <div className="fs-val-sm text-muted mt-2">Delivered</div>
                    <div>70</div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-3 text-center pt-3">Email </div>
                  <div className="col-2 mb-4">
                    <div className="fs-val-sm text-muted mt-2">Sent</div>
                    <div>100</div>
                  </div>
                  <div className="col-2 mb-4">
                    <div className="fs-val-sm text-muted mt-2">Rejected</div>
                    <div>30</div>
                  </div>
                  <div className="col-2 mb-4">
                    <div className="fs-val-sm text-muted mt-2">Delivered</div>
                    <div>70</div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-3 text-center pt-3">WhatsApp </div>
                  <div className="col-2 mb-4">
                    <div className="fs-val-sm text-muted mt-2">Sent</div>
                    <div>100</div>
                  </div>
                  <div className="col-2 mb-4">
                    <div className="fs-val-sm text-muted mt-2">Rejected</div>
                    <div>30</div>
                  </div>
                  <div className="col-2 mb-4">
                    <div className="fs-val-sm text-muted mt-2">Delivered</div>
                    <div>70</div>
                  </div>
                  <div className="col-2 mb-4">
                    <div className="fs-val-sm text-muted mt-2">Seen</div>
                    <div>50</div>
                  </div>
                </div>
              </td>

              {/* Notification Details*/}
              {/* <td className="text-center">
                <div>SMS, Email, WhatsApp</div>
              </td> */}

              {/* Created/Modified By Details */}
              {/* <td>
                <div className="fs-val-sm text-muted">Created Details</div>
                <div className="mb-0">13 FEB 2024 01:45 AM</div>
                <div className="text-muted">
                  <span>by </span>
                  <span>Swapna</span>
                </div>
              </td> */}

              {/* Status */}
              {/* <td className="text-center">
                <HighlightText status="Active" />
              </td> */}

              {/* Action block */}
              <td className="text-center">
                <button className="btn btn-sm btn-outline-primary me-1 fs-val-xs mb-3 mx-1">
                  View
                </button>
                <button className="btn btn-sm btn-outline-warning me-1 fs-val-xs mb-3 mx-1">
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default memo(Table);
