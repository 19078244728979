import { NotificationService } from "@sk/services";
import { set as dtSet } from "date-fns";
import { lazy, useCallback, useEffect, useState } from "react";
const ChartDynamic = lazy(() => import("react-apexcharts"));

const getColorBystatus = (status) => {
  if (status == "delivered") {
    return "#170bbe";
  } else if (status == "failed") {
    return "#d20505";
  } else if (status == "read") {
    return "#0faa29";
  } else {
    return "#ffba49";
  }
};

const getLabelsBystatus = (status) => {
  if (status == "delivered") {
    return "Delivered";
  } else if (status == "failed") {
    return "Failed";
  } else if (status == "read") {
    return "Read";
  } else {
    return status;
  }
};

const options = {
  chart: {
    type: "pie",
  },
  labels: ["Failed", "Read", "Delivered"],
  values: [0, 0, 0],
  colors: ["#FF5733", "#33FF57", "#3357FF"],
};

const MarketDahboardChart = ({ type, filterParams }) => {
  const [display, setDisplay] = useState("loading");
  const [data, setData] = useState({ ...options });

  useEffect(() => {
    if (type == "Whatsapp" && filterParams) {
      loadChartDetails(filterParams);
    }
  }, [type, filterParams]);

  const prepareParams = (params) => {
    let p = {
      filter: {
        createdAt: {},
        mode: type,
      },
      outputType: "list",
    };

    if (params?.createdAt?.length > 0) {
      p.filter.createdAt = {
        $gte: dtSet(params.createdAt[0], {
          hours: 0,
          minutes: 0,
          seconds: 0,
        }),
        $lte: dtSet(params.createdAt[1], {
          hours: 23,
          minutes: 59,
          seconds: 59,
        }),
      };
    }
    return p;
  };

  const loadChartDetails = useCallback(async (params) => {
    let p = prepareParams(params);
    let m = await NotificationService.getNotificationDashboardDeliveryDetails(
      p
    );

    if (Array.isArray(m.resp)) {
      let total = m.resp.reduce((e, c) => {
        return c.totalCount + e;
      }, 0);

      let read = m.resp.filter((e) => {
        return e._id == "read";
      });
      let readVal = read?.length > 0 ? read[0]?.totalCount : 0;
      let readPerc = total > 0 ? (readVal / total) * 100 : 0;

      let d = {
        chartData: {
          ...options,
          labels: m.resp.map((e) => {
            return getLabelsBystatus(e._id);
          }),
          colors: m.resp.map((e) => {
            return getColorBystatus(e._id);
          }),
          values: m.resp.map((e) => {
            return e.totalCount;
          }),
        },
        _readPerc: Math.ceil(readPerc),
        _total: total,
      };

      setData(d);
      setDisplay("data");
    } else {
      setDisplay("NoData");
    }
  }, []);

  return (
    <>
      <div className="row p-0 m-0 justify-content-md-center">
        <div className="col p-2 mt-3 text-center pb-4">
          <h1 className="fw-bold">{data._total || 0}</h1>
          <div className="fs-val-md mb-2 mt-3">
            {data?._readPerc > 0 ? (
              <>
                <span className="text-success fw-bold pe-1">
                  {data._readPerc || 0} %
                </span>
                People Read
              </>
            ) : (
              <>{type} Notifications are not configured for customers</>
            )}
          </div>
          <div className="fs-val-sm text-muted mb-3">
            {data?._readPerc} From Last 22 days
          </div>
        </div>

        {data?._readPerc ? (
          <div className="col-8 p-0">
            <ChartDynamic
              height={"100%"}
              width={"100%"}
              options={data?.chartData}
              series={data?.chartData?.values}
              type="pie"
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default MarketDahboardChart;
