import { AppCard } from "@sk/uis";
import { Modal } from "react-bootstrap";

const GrnPoModal = ({ show, callback, poids, grnId }) => {
  const handleClose = () => {
    callback({ show: false });
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">PO - ID: {grnId}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-bg">
        <AppCard title={`Total PO: ${poids.length}`}>
          <div className="row">
            {poids.map((po, index) => (
              <div className="col-4" key={index}>
                <div className="fs-val-md fw-semibold mb-1">{po}</div>
              </div>
            ))}
          </div>
        </AppCard>
      </Modal.Body>
    </Modal>
  );
};

export default GrnPoModal;
