import { DatePickerInput, SelectInput, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";

const defaultFromData = {
  createdAt: [],
  expectedAt: [],
  status: "",
  poId: "",
};

const createdOnDateConfig = { mode: "range" };

const statusOptions = [
  { label: "All", value: "" },
  { label: "Open", value: "Open" },
  { label: "Partially Inwarded", value: "Partially Inwarded" },
];
const VendorOpenPoFilter = ({ callback }) => {
  const { register, control, getValues, reset } = useForm({
    defaultValues: defaultFromData,
  });

  const onInputChange = useCallback(() => {
    callback({ data: getValues(), action: "applied" });
  }, [callback, getValues]);

  const onSearch = useCallback(
    debounce(() => {
      callback({ data: getValues(), action: "applied" });
    }, 700),
    [getValues(), callback]
  );

  const resetFilter = () => {
    reset(defaultFromData);
    callback({ action: "reset" });
  };

  const dtChange = useCallback(
    (chngFn) => {
      return (e) => {
        chngFn(e);
        onInputChange();
      };
    },
    [onInputChange]
  );

  return (
    <div className="row">
      <div className="col-3">
        <TextInput
          name="poId"
          register={register}
          label="PO ID"
          callback={onSearch}
          placeholder="Search By Po Id"
        />
      </div>
      <div className="col-2">
        <SelectInput
          name="status"
          register={register}
          label="Status"
          callback={onInputChange}
          options={statusOptions}
        />
      </div>

      <div className="col-2">
        <label className=" mb-1 fs-val-md"> Created On </label>
        <Controller
          name="createdAt"
          control={control}
          render={({ field: { value, onChange } }) => (
            <DatePickerInput
              placeholder="Choose"
              value={value}
              inpChange={dtChange(onChange)}
              config={createdOnDateConfig}
            />
          )}
        />
      </div>

      <div className="col-2">
        <label className="mb-1 fs-val-md"> Expected On </label>
        <Controller
          name="expectedAt"
          control={control}
          render={({ field: { value, onChange } }) => (
            <DatePickerInput
              placeholder="Choose"
              value={value}
              inpChange={dtChange(onChange)}
              config={createdOnDateConfig}
            />
          )}
        />
      </div>

      {/* <div className="col-3 text-end mt-4">
        <button
          className="btn  btn-warning fs-val-md me-2"
          onClick={resetFilter}
        >
          Reset
        </button>
        <button className="btn  btn-primary fs-val-md" onClick={onApply}>
          Apply
        </button>
      </div> */}
    </div>
  );
};

export default memo(VendorOpenPoFilter);
